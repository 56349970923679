import React, { useEffect, useState } from "react";
import styles from "./projects.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from "@mdi/react";
import {
  mdiBriefcase,
  mdiClipboardList,
  mdiContentCopy,
  mdiPaletteSwatch,
  mdiPencil,
  mdiToolbox,
  mdiTrashCanOutline,
} from "@mdi/js";
import {
  IconButton,
  Tooltip,
  Box,
  LinearProgress,
  Typography,
  LinearProgressProps,
  styled,
  Grid,
  Button,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DeleteDialog } from "../../components";
import { projectService, sharedService, userService } from "../../api";
import { useUserContext } from "../../utils/UserContext";
import {
  ILookupData,
  IDisplayProject,
  IProjectParam,
  IDeleteProjectParam,
} from "../../interfaces";
import { Link, useNavigate } from "react-router-dom";
import { LookupTypeId } from "../../enums";
import { decryptData } from "../../utils/CryptoJS";
import { useTranslation } from "react-i18next";

const Projects: React.FC = () => {
  const [apiError, setApiError] = useState<string | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(
    null
  );
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  const email = localStorage.getItem("email");
  const [lookupData, setLookupData] = useState<ILookupData[]>([]);
  const [displayProjects, setDisplayProjects] = useState<IDisplayProject[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortByParam, setSortBy] = useState<any[]>();
  const [sortOrder, setSortOrder] = useState<any>();
  const [projectTypeIdparam, setProjectTypeId] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [lookupValues, setLookupValues] = useState<any>({});
  const [lookupTypes, setLookupTypes] = useState<any[]>([]);
  const [statusTypes, setStatusTypes] = useState<any[]>([]);
  const [projectTypes, setProjectTypes] = useState<any[]>([]);
  const [units, setUnits] = useState<any>([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<
    number | string
  >("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sortParams, setSortParams] = useState({
    sortBy: "modifieddate",
    sortDescending: false,
  });
  const [oneTimeOnly, setOneTimeOnly] = useState<number>(1);
  const [checked, setChecked] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [deleteRequest, setDeleteRequest] =
    useState<IDeleteProjectParam | null>(null);
  const [showActionColumns, setShowActionColumns] = useState(false);

  const CustomProgressBar = styled(LinearProgress)({
    height: "26px",
    borderRadius: "4px",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "var(--primary)",
    },
  });

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number | string }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", alignSelf: "center" }}>
        <Box className={styles.progressBarContainer}>
          <CustomProgressBar variant="determinate" {...props} />
          <Typography
            variant="body2"
            className={styles.progressValue}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "projectName",
      headerName: "Project name",
      width: 200,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
      sortable: false,
      renderHeader: () => {
        return (
          <div
            onClick={() => handleSort("projectname")}
            className="cursor_pointer"
          >
            {t("projects.project_name")}
          </div>
        );
      },
      renderCell: (params) => {
        const project = displayProjects.find(
          (p) => p.projectName === params.value
        );
        const description = project
          ? project.description
          : "No description available";
        return (
          <Tooltip placement="right" title={description}>
            <Link to={"/project-detail"} className={styles.projectLink}>
              <span>{params.value}</span>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "projectAddress",
      headerName: "Address",
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
      sortable: false,
      renderHeader: () => {
        return (
          <div>
          {t("projects.project_address")}
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div className={styles.address_text}>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    // {
    //   field: 'projectType',
    //   headerName: 'Project Type',
    //   description: 'This column has a value getter and is not sortable.',
    //   editable: false,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   width: 130,
    //   flex: 1,
    //   headerClassName: 'filterMenu',
    //   renderHeader: () => (
    //     <>
    //       Project Type
    //       <IconButton
    //         aria-controls="filter-menu"
    //         aria-haspopup="true"
    //         onClick={handleHeaderClick}
    //         className={styles.filterIcon}
    //     >
    //       <Tooltip title="Filter"><Icon path={mdiFilter} aria-label="filter" size={1} /></Tooltip>
    //     </IconButton>
    //       <Menu
    //         id="filter-menu"
    //         anchorEl={anchorEl}
    //         keepMounted
    //         open={Boolean(anchorEl)}
    //         onClose={handleHeaderClose}
    //       >
    //         {projectTypes.map((lookup) => (
    //           <MenuItem key={lookup.lookupValueID}>
    //             <FormControlLabel
    //               control={
    //                 <Checkbox
    //                   onChange={e => handleChange(e, lookup.lookupValueID)}
    //                   color="primary"
    //                   defaultChecked={checked}
    //                 />
    //               }
    //               label={lookup.displayName}
    //             />
    //           </MenuItem>
    //         ))}
    //         <Button onClick={handleApply}>Apply</Button>
    //       </Menu>
    //     </>
    //   )
    // },
    // {
    //   field: 'projectStatus',
    //   headerName: 'Project Status',
    //   width: 130,
    //   editable: false,
    //   disableColumnMenu: true,
    //   flex: 1,
    //   sortable: false,
    // },
    {
      field: "lastActivity",
      headerName: "Last Activity",
      width: 130,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return (
          <div>
          {t("projects.last_activity")}
          </div>
        );
      },
      renderCell: (params) => {
        const { lastActivityType } = params.row;

        const text = params.value || ""; // Get the value of lastActivity
        const maxLength = 20;

        return (
          <span className={styles.verticalAligned}>
            <Icon
              color="var(--primary)"
              path={
                lastActivityType === "Task"
                  ? mdiClipboardList
                  : lastActivityType === "Equipment"
                    ? mdiToolbox
                    : lastActivityType === "Material"
                      ? mdiPaletteSwatch
                      : mdiBriefcase
              }
              size={0.8}
            />
            <span title={text}>
              {text.length > maxLength
                ? `${text.substring(0, maxLength - 3)}...`
                : text}
            </span>
          </span>
        );
      },
    },
    {
      field: "lastActivityDate",
      headerName: "Last Activity Date",
      width: 130,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return (
          <div>
          {t("projects.last_activity_date")}
          </div>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 130,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <div
          onClick={() => handleSort("projectstartdate")}
          className="cursor_pointer"
        >
          {t("projects.start_date")}
        </div>
      ),
    },
    {
      field: "progress",
      headerName: "Progress",
      width: 130,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      sortable: false,
      renderHeader: () => {
        return (
          <div>
          {t("projects.progress")}
          </div>
        );
      },
      align: "center",
      renderCell: (params) => (
        <LinearProgressWithLabel value={params.value as number} />
      ),
    },
  ];
  if (showActionColumns) {
    columns.push(
      {
        field: "editProject",
        headerName: "",
        width: 30,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        type: "actions",
        flex: 0.3,
        renderCell: (params) => (
          <IconButton
            aria-label="edit"
            onClick={() => handleEdit(params.row.projectID)}
          >
            <Tooltip title="Edit">
              <Icon path={mdiPencil} aria-label="edit" size={1} />
            </Tooltip>
          </IconButton>
        ),
      },
      {
        field: "deleteProject",
        headerName: "",
        width: 30,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        type: "actions",
        flex: 0.3,
        renderCell: (params) => (
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.row.projectID)}
          >
            <Tooltip title="Delete">
              <Icon path={mdiTrashCanOutline} aria-label="delete" size={1} />
            </Tooltip>
          </IconButton>
        ),
      },
      {
        field: "copyProject",
        headerName: "",
        width: 30,
        editable: false,
        sortable: false,
        disableColumnMenu: true,
        type: "actions",
        flex: 0.3,
        renderCell: (params) => (
          <IconButton
            aria-label="copy"
            onClick={() => handleCopy(params.row.projectID)}
          >
            <Tooltip title="Copy">
              <Icon path={mdiContentCopy} aria-label="copy" size={1} />
            </Tooltip>
          </IconButton>
        ),
      }
    );
  }

  const mapLookup = (id: number) => {
    const lookup = lookupData.find((item) => item.lookupValueID === id);
    return lookup ? lookup.displayName : "Unknown";
  };
  const handleHeaderClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHeaderClose = () => {
    setAnchorEl(null);
  };
  const [userRoles, setUserRoles] = useState<any>(null);
  useEffect(() => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(0));
    fetchProjects();
  }, [
    page,
    rowsPerPage,
    sortByParam,
    sortOrder,
    projectTypeIdparam,
    selectedProjectStatus,
  ]);

  // Handles the sort action for the table
  const handleSort = (field: string) => {
    setSortParams((sortParams) => {
      const isSameField = sortParams.sortBy === field;
      const isDescending = isSameField ? !sortParams.sortDescending : true;
      const updatedSortParams = { sortBy: field, sortDescending: isDescending };
      fetchProjects(updatedSortParams);
      return updatedSortParams;
    });
  };

  // Fetches the project list based on current filters and sorting
  const fetchProjects = async (updatedSortParams = sortParams) => {
    try {
      if (
        (localStorage.getItem("userId") as string) == "undefined" &&
        (localStorage.getItem("userId") as string) == null
      ) {
        if (localStorage.getItem("email")) {
          const userDetails = await userService.getUserDetails(
            localStorage.getItem("email") as string
          );
          if (userDetails) {
            const data = userDetails.userId;
            localStorage.setItem("userId", data);
          }
        }
      }
      if (localStorage.getItem("userId") as string) {
        setUserRoles(await sharedService.getUserRolesPermissions());
        const params: IProjectParam = {
          sortBy: updatedSortParams.sortBy,
          sortDescending: updatedSortParams.sortDescending,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          projectTypeId: projectTypeIdparam,
          searchKey: "",
          isGantt: false,
        };
        const response = await projectService.getProjectByUserId(
          localStorage.getItem("userId") as string,
          params
        );
        if (response.isSuccess) {
          setDisplayProjects(response.projects);
          setCount(response.totalCount);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };

  // Handles the edit project action
  const handleEdit = (projectID: number) => {
    navigate(`/edit-project?id=${projectID}`);
  };

  // Handles the delete project action
  const handleDelete = (projectID: number) => {
    setSelectedProjectId(projectID);
    setDeleteDialogOpen(true);
    setDeleteRequest({
      userId: localStorage.getItem("userId") as string,
      projectId: projectID,
    });
  };

  const handleDeleteConfirm = async () => {
    if (selectedProjectId !== null && deleteRequest !== null) {
      try {
        setDeleteDialogOpen(false);
        const response = await projectService.deleteProject(deleteRequest);
        if (response.isSuccess) {
          setDisplayProjects((prevProjects) =>
            prevProjects.filter(
              (project) => project.projectId !== selectedProjectId
            )
          );
          fetchProjects();

          setDeleteDialogOpen(false);
        }
      } catch (error: any) {
        setApiError(error.response.data.errors.join(", "));
      }
    }
  };

  // Handles the page change event
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    fetchProjects();
  };

  // Handles the cancel action for the delete dialog
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleCopy = (projectID: number) => { };
  //handle the row click event
  const HandleRowClick = (params: any) => {

    //localStorage.setItem("userRoleId", params.row.userTypeId.toString());
    sessionStorage.setItem("customerId", params.row.customerId.toString());
    sessionStorage.setItem("currentProject", JSON.stringify(params.row));
    navigate("/project-detail", { state: { projectId: params.row.projectID } });
  };

  const rows = displayProjects.map((project) => ({
    id: project.projectId,
    projectID: project.projectId,
    projectName: project.projectName,
    projectAddress: project.address,
    projectType: mapLookup(project.projectTypeId),
    projectStatus: mapLookup(project.statusId),
    startDate: project.projectStartDate,
    endDate: project.projectEndDate,
    progress: project.progressPercentage,
    userTypeId: project.userTypeId,
    userTypeName: project.userTypeName,
    customerId: project.customerId,
    lastActivity: project.lastActivity,
    lastActivityDate: new Date(project.lastActivityDate).toLocaleDateString(
      "en-CA"
    ),
    lastActivityType: project.lastActivityType,
  }));

  const overlayButtonText = t("projects.no_projects_to_show_text");

  return (
    <div className={styles.Gridcontainer}>
      <Grid container>
        <Grid item xs={12} className={styles.ProjectTitle_container}>
          <h3 id="project_title"> {t("projects.project_title")} </h3>
          {count !== 0 && userRoles?.create_project && (
            <>
              <div className={styles.title_sperator}></div>
              <Button id="new_project_btn"
                variant="contained"
                href={userRoles?.create_project ? "/add-project" : ""}
                className="primary-button rounded"
              >
                {t("projects.new_project_btn")}
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Datatable
        rows={rows}
        columns={columns}
        overlayButtonLink={userRoles?.create_project ? "/add-project" : ""}
        overlayButtonText={overlayButtonText}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={count}
        loading={loading}
        error={error}
        onRowClick={HandleRowClick}
        getRowHeight="auto"
        className="ProjectPage"
      />
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={deleteDialogTitle}
          cancelbuttonText={dialogCancelButtonText}
          submitButtonText={dialogDeleteButtonText}
          cancelbuttonLink={handleDeleteCancel}
          submitButtonLink={handleDeleteConfirm}
        />
      )}
    </div>
  );
};

export default Projects;
