import { UserTypeId } from "../../enums";
import IDocumentParam from "../../interfaces/IDocumentParam";
import UserAxios from "../UserAxios";

const getFileListByFilter = async (projectId: any, entityTypeId: any, userId: any,userTypeId:any, pagination: IDocumentParam) => {
    const response = await UserAxios.post('/Document/GetFileListByFilter', 
        pagination,
        {
            params: {
                projectId,
                entityTypeId,
                userId,
                userTypeId
            }
        }
    );
    return response.data;
};

const getNoteByDocumentId = async (entityTypeId: any, entityId: any) => {
    const response = await UserAxios.get('/Document/GetNoteByDocumentId', {
        params: {
            entityId,
            entityTypeId
        }
    });
    return response.data;
}

const getEntityTypeList = async () => {
    const response = await UserAxios.get('/Document/GetEntityTypes');
    return response.data;
}

const saveDocumentNotes = async (payload: any) => {
    const response = await UserAxios.post("/Document/PostNoteForDocument", payload);
    return response.data;
};


const documentServices = { getFileListByFilter, getNoteByDocumentId, getEntityTypeList, saveDocumentNotes }

export default documentServices;