import { IMaterialInformation } from "../interfaces/IMaterialInformation";
import { IMaterialPaymentDetails } from "../interfaces/IPayment";


interface ValidationErrors {
  [key: string]: string;
}

export const validateMaterial = (material: IMaterialInformation): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!material.costCodeId.trim()) {
      errors.costCodeId = "Category is required.";
    }
    if (!material.materialName.trim()) {
      errors.materialName = "Material name is required.";
    }
  if (!material.purchaseDate ) {
    errors.purchaseDate = "Date is required.";
  }
  return errors;
};
