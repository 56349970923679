import React, { useEffect, useState } from "react";
import styles from "./equipments.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from "@mdi/react";
import {
  mdiCash,
  mdiCheckbook,
  mdiClose,
  mdiCreditCardOutline,
  mdiFilter,
  mdiMagnify,
  mdiProgressClock,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import TaskServices from "../../api/services/TaskServices";
import { useLocation, useParams } from "react-router-dom";
import { IDisplayTask } from "../../interfaces/IDisplayTask";
import {
  IProjectParam,
  IVendorandVendorTypes,
  IVendorWithTypeIds,
} from "../../interfaces";
import { PaymentDrawer } from "../../components";
import { AddEquipments } from "../../containers";
import ICategoryWithCategoryIds from "../../interfaces/ICategoryWithCategoryIds";
import IAddContacts from "../../interfaces/IAddContacts";
import equipmentService from "../../api/services/EquipmentService";
import IDisplayEquipment from "../../interfaces/IDisplayEquipment";
import { Category, Description } from "@mui/icons-material";
import { IEquipmentPaymentDetails } from "../../interfaces/IPayment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { sharedService } from "../../api";

const Equipments: React.FC = () => {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [equipmentlist, setTasklist] = useState<IDisplayEquipment[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<any>();
  const [searchKey, setSearchKey] = useState<string>("");
  const pageCount = Math.ceil(count / rowsPerPage);
  const {t}=useTranslation();
  const columns: GridColDef[] = [
    {
      field: "equipmentName",
      headerName: t("Equipments.equipmentName"),
      width: 120,
      minWidth: 150,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div  className="projectLink cursor_pointer">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "rentDate",
      headerName: t("Equipments.rentDate"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "rentDuration",
      headerName: t("Equipments.rentDuration"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "right",
      headerAlign: "right",
    },
    {
      field: "rentAmount",
      headerName: t("Equipments.rentAmount"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => <span>$ {params.value} </span>,
    },
    {
      field: "description",
      headerName: t("Equipments.description"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    }
  ];
  const [userRoles, setUserRoles] = useState<any>(null);
  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setSearchKey(value);
  };
  // Fetch tasks based on projectId
  useEffect(() => {
    fetchEquipment(projectId);

  }, [projectId, page, rowsPerPage, searchKey]);

  const fetchEquipment = async (projectId: any) => {
    try {
      setUserRoles(await sharedService.getUserRolesPermissions());
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: "modifieddate",
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: searchKey,
          isGantt: false,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const response = await equipmentService.getEquipment(
          projectId,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );

        setTasklist(response.equipments);
        setCount(response.totalCount);
        setLoading(false);
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };
  const rows = equipmentlist.map((equipment) => ({
    id: equipment.equipmentId,
    category: equipment.costCode?.costCodeName || "Unknown",
    equipmentName: equipment.equipmentName,
    description: equipment.description,
    rentDate: equipment.rentDate
      ? dayjs(equipment.rentDate).format("YYYY-MM-DD")
      : null,
    rentDuration: equipment.rentDuration,
    rentAmount: equipment.rentAmount,
  }));
  const [refreshTask, setRefreshTask] = useState(false);
  useEffect(() => {
    if (refreshTask) {
      fetchEquipment(projectId);
    }
  }, [refreshTask]);
  // handle for drawer
  const [addEquipmentDrawerOpen, setAddEquipmentDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleAddEquipmentDrawerOpen = () => {
    setAddEquipmentDrawerOpen(true);
  };

  // handle for closing drawer
  const handleAddEquipmentDrawerClose = () => {
    setSelectedEquipment(null);
    setAddEquipmentDrawerOpen(false);
  };

  // handle for drawer
  const [equipmentPaymentDrawerOpen, setEquipmentPaymentDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleEquipmentPaymentDrawerOpen = () => {
    setEquipmentPaymentDrawerOpen(true);
  };

  // handle for closing drawer
  const handleEquipmentPaymentDrawerClose = () => {
    setEquipmentPaymentDrawerOpen(false);
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };
  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };
  // Handle row click
  const handleRowClick = (params: any) => {
    const { row } = params;
    const Equi = equipmentlist.filter((e) => e.equipmentId == params.id).pop();
    setSelectedEquipment(Equi);
    setAddEquipmentDrawerOpen(true);
  };
  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.search_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <TextField
            placeholder={t("Equipments.add_equipment_btn")}
            variant="outlined"
            className="width30"
            value={searchKey}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKey ? (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchKey("")} // Clear the search key
                    >
                      <Icon path={mdiClose} size={0.8} />
                    </IconButton>
                  ) : (
                    <Icon path={mdiMagnify} size={1} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => setAddEquipmentDrawerOpen(userRoles?.add_edit_task ? true : false)}
            className="primary-button rounded"
          >
            {t("Equipments.add_equipment_btn")}
          </Button>
        </Grid>
      </Grid>

      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={count}
        loading={loading}
        error={error}
        onRowClick={handleRowClick}
      />
      <AddEquipments
        open={addEquipmentDrawerOpen}
        onClose={handleAddEquipmentDrawerClose}
        equipment={selectedEquipment}
        refreshTask={handleDataFromChild}
      />
      <PaymentDrawer
        open={equipmentPaymentDrawerOpen}
        onClose={handleEquipmentPaymentDrawerClose}
      />
    </div>
  );
};

export default Equipments;
