import { Dialog, styled } from "@mui/material";

export const BillingInformationDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    maxWidth: "600px",
    borderRadius: "14px",
    padding: "25px 30px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  "& .MuiDialogTitle-root": {
    padding: "0px",
    alignItems: "start",
    justifyContent: "start",
  },
  "& .MuiDialogContent-root": {
    padding: "20px 0px",
  },
  "& .MuiToggleButton-root": {
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "4px",
    border: "1px solid #EEE !important",
    background: "#FFF",
  },
  "& .MuiToggleButton-root:hover, & .Mui-selected": {
    background: "var(--primary) !important",
    color: "#FFF !important",
    borderRadius: "4px !important",
    border: "1px solid var(--primary) !important",
  },
  "& .inputGroup .MuiInputBase-root": {
    border: "0px",
  },
  "& .MuiRadioGroup-root label": {
    width: "100%",
    h3: {
      fontSize: "14px",
      fontWeight: "700",
      marginBottom: "0px",
      lineHeight: "1",
    },
  },
}));
export default BillingInformationDialog;
