import { IProjectParam } from "../../interfaces";
import { IMaterialInformation } from "../../interfaces/IMaterialInformation";
import UserAxios from "../UserAxios";



const getMaterialById = async (materialId: number) => {
    const response = await UserAxios.get(`Material/${materialId}`)
    return response.data;
}

const getMaterialByProject = async (projectId: number, userTypeId: number, userId: string,param: IProjectParam) => {
    const response = await UserAxios.post(`Material/getMaterialByProject?ProjectId=${projectId}&userTypeId=${userTypeId}&userId=${userId}`,param);
    return response.data;
}

const createMaterial = async (material: any) => {
    const response = await UserAxios.post(`Material/createMaterial`, material)
    return response.data;
}

const updateMaterial = async (material: any) => {
    const response = await UserAxios.put(`Material/${material.materialId}`, material, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return response.data;
}


const uploadAttachment = async (material: any,files:Array<File>,entityTypeId:string) => {
    const formData=new FormData();
    formData.append('customerId',material.customerId);
    formData.append('entityTypeId',entityTypeId);//6=material
    formData.append('entityId',material.materialId.toString());
    formData.append('createdBy',material.createdBy);
    formData.append('projectId',material.projectId);
    for(let i=0;i<files.length;i++){
        formData.append(`files`,files[i])
    }
    const response = await UserAxios.post(`attachment`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return response.data;
}

const deleteMaterial = async (material: IMaterialInformation) => {
    const response = await UserAxios.delete(`Material/${material.materialId}`,
        {
            data: {
                materialId: material.materialId,
                modifiedBy: material.modifiedBy
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
    return response.data;
}


const materialService = {
    getMaterialById,
    getMaterialByProject,
    createMaterial,
    updateMaterial,
    deleteMaterial,
    uploadAttachment
}

export default materialService;