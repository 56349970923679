import React, { useState } from "react";
import styles from "./paymentDrawer.module.scss";
import { Box, Button, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IAddTaskDrawerProps, IPayment } from "../../interfaces";
import { SMSSwitch, StyledDrawer } from "../../components";
import { billingService, projectService, userService } from "../../api";
import dayjs from "dayjs";
import { IPaymentDrawerProps } from "../../interfaces/IAddTaskDrawerProps";
import { decryptData } from "../../utils/CryptoJS";

const PaymentDrawer: React.FC<IPaymentDrawerProps> = ({ open, onClose }) => {
  const isTask = false;
  const isMaterial = false;
  const isEquipment = true;
  // handle for drawer
  const [taskPaymentDrawerOpen, setTaskPaymentDrawerOpen] =
    React.useState(false);
  // toggle event for drawer
  const handleDrawerToggle = () => {
    setTaskPaymentDrawerOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const [TaskPaymentDetils, setTaskPaymentDetils] = useState()
  const [MaterialPaymentDetils, setMaterialPaymentDetils] = useState()
  const [EquipmentPaymentDetils, setEquipmentPaymentDetils] = useState()
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  const [paymentDetails, setPaymentDetails] = useState({
    customerId: userDetailsObj.customerId,
    projectId: 1,
    paidToCompanyId: 1,
    category: "",
    amountPaid: 0,
    paymentMethodId: 1,
    paymentMemo: "",
    description: "",
    taskId: 0,
    materialId: 0,
    equipmentId: 0,
    workOrderId: 0,
    paymentDate: new Date().toISOString(),
    createdBy: userDetailsObj.userId,
    createdDate: new Date().toISOString(),
  });

  // Generic handle change function
  const handlePaymentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number | string>) => {
    const { name, value } = e.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: value,
    });
  };

  // Handle date changes
  const handleDateChange = (date: string | null, field: keyof IPayment) => {
    setPaymentDetails({
      ...paymentDetails,
      [field]: date || "",
    });
  };

  // Handle form submission
  const handleFormSubmit = async () => {
    try {
      const response = await billingService.Payment(paymentDetails);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Error saving payment", error);
    }

  };
  return (
    <StyledDrawer
      className={styles.rightDrawer}
      open={open}
      anchor="right"
    //onClose={onClose}
    >
      < Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            {isTask ? <h3>Payment for Task</h3> : null}
            {isMaterial ? <h3>Payment for Material</h3> : null}
            {isEquipment ? <h3>Payment for Equipment</h3> : null}
          </Grid>
          {isTask ? <Grid container item xs={12}>
            <Grid item container className="fieldWrapper">
              <label htmlFor="category">Category:</label>
              <TextField
                name="category"
                placeholder="Category"
                className="inlineSelect selectBox width70"
                // value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="taskName">Task Name:</label>
              <TextField
                name="taskName"
                placeholder="Task Name"
                variant="outlined"
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="vendor">Vendor:</label>
              <TextField
                name="vendor"
                placeholder="vendor"
                className="inlineSelect selectBox width70"
                //value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Address:</label>
              <TextField
                name="note"
                placeholder="Notes"
                multiline
                rows={4}
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="contactPerson">Contact Person:</label>
              <TextField

                name="contactPerson"
                placeholder="ContactPerson"
                className="inlineSelect selectBox width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="Schedule on">Schedule on:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="scheduleOn"
                  readOnly
                  sx={{ width: "36% !important" }} />
              </LocalizationProvider>
            </Grid>
            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240" > 
                  Task Marked Completed By:
                </label>
                <TextField
                  name="taskMarkedCompletedBy"
                  value="John Doe" // default value
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskMarkedCompletedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskMarkedCompletedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240">
                  Task Approved By:
                </label>
                <TextField
                  name="taskApprovedBy"
                  value="Jane Smith" // default value
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskApprovedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskApprovedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="approvedAmount">Approved Amount:</label>
              <TextField
                name="approvedAmount"
                placeholder="Approved Amount"
                variant="outlined"
                sx={{ width: "36% !important" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>
            : null}
          {isMaterial ? <Grid container item xs={12}>
            <Grid item container className="fieldWrapper">
              <label htmlFor="category">Category:</label>
              <TextField
                name="category"
                placeholder="Category"
                className="inlineSelect selectBox width70"
                // value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="materialName">Material Name:</label>
              <TextField
                name="materialName"
                placeholder="Task Name"
                variant="outlined"
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
                value="MaterialName"
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="vendor">Vendor:</label>
              <TextField
                name="vendor"
                placeholder="vendor"
                className="inlineSelect selectBox width70"
                //value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Address:</label>
              <TextField
                name="note"
                placeholder="Notes"
                multiline
                rows={4}
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="contactPerson">Contact Person:</label>
              <TextField

                name="contactPerson"
                placeholder="ContactPerson"
                className="inlineSelect selectBox width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240">
                  Purchased By:
                </label>
                <TextField
                  name="purchasedBy"
                  value="John Doe"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskMarkedCompletedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskMarkedCompletedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240">
                  Task Approved By:
                </label>
                <TextField
                  name="taskApprovedBy"
                  value="Jane Smith" // default value
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskApprovedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskApprovedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="approvedAmount">Approved Amount:</label>
              <TextField
                name="approvedAmount"
                placeholder="Approved Amount"
                variant="outlined"
                sx={{ width: "36% !important" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>
            : null}
          {isEquipment ? <Grid container item xs={12}>
            <Grid item container className="fieldWrapper">
              <label htmlFor="category">Category:</label>
              <TextField
                name="category"
                placeholder="Category"
                className="inlineSelect selectBox width70"
                // value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="materialName">Equipment Name:</label>
              <TextField
                name="materialName"
                placeholder="Task Name"
                variant="outlined"
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
                value="MaterialName"
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="vendor">Vendor:</label>
              <TextField
                name="vendor"
                placeholder="vendor"
                className="inlineSelect selectBox width70"
                //value={formData.c}
                InputProps={{
                  readOnly: true,
                }}
              >
              </TextField>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Address:</label>
              <TextField
                name="note"
                placeholder="Notes"
                multiline
                rows={4}
                className="width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="contactPerson">Contact Person:</label>
              <TextField

                name="contactPerson"
                placeholder="ContactPerson"
                className="inlineSelect selectBox width70"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240">
                  Purchased By:
                </label>
                <TextField
                  name="purchasedBy"
                  value="John Doe"
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskMarkedCompletedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskMarkedCompletedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240">
                  Task Approved By:
                </label>
                <TextField
                  name="taskApprovedBy"
                  value="Jane Smith" // default value
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="taskApprovedOn">
                  on:
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="taskApprovedOn"
                    value={dayjs('2024-08-26')} // default value
                    sx={{ width: "100%", ml: "10px", mr: "8%" }}
                    readOnly
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="approvedAmount">Approved Amount:</label>
              <TextField
                name="approvedAmount"
                placeholder="Approved Amount"
                variant="outlined"
                sx={{ width: "36% !important" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>
            : null}
          <Grid item container className="fieldWrapper">
            <label htmlFor="checkPaidTo">Paid to:</label>
            <TextField
              name="checkPaidTo"
              placeholder="Paid to"
              variant="outlined"
              className="width70"
              //value={formData.checkPaidTo || ""}
              onChange={handlePaymentChange}
            />
          </Grid>
          <Grid item container className="fieldWrapper">
            <label htmlFor="amountPaid">Amount to be paid:</label>
            <TextField
              name="amountPaid"
              placeholder="Amount to be paid"
              variant="outlined"
              sx={{ width: "36% !important" }}
              value={paymentDetails.amountPaid || ""}
              onChange={handlePaymentChange}
            />
          </Grid>
          <Grid item container className="fieldWrapper">
            <Grid
              item
              xs={7}
              className="multiColumns"
              alignItems="center !important"
              alignContent="center !important"
            >
              <label className="FirstLabel w-240">
                Memo:
              </label>
              <TextField
                name="paymentMemo"
                placeholder="Memo"
                variant="outlined"
                value={paymentDetails.paymentMemo || ""}
                onChange={handlePaymentChange}
              />
            </Grid>
            <Grid item xs={5} className="multiColumns">
              <label htmlFor="checkNumber">Check #:</label>
              <TextField
                name="checkNumber"
                placeholder="Check #"
                variant="outlined"
                // value={formData.checkNumber || ""}
                onChange={handlePaymentChange}
                sx={{ width: "80% !important", ml: "10px", mr: "8%" }}
              />
            </Grid>
          </Grid>
          <Grid item container className="fieldWrapper">
            <label htmlFor="comment">Comment:</label>
            <TextField
              name="description"
              id="comment"
              placeholder="Payment Method"
              multiline
              rows={4}
              className="width70"
              value={paymentDetails.description || ""}
              onChange={handlePaymentChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className={styles.switchContanier + " fieldWrapper"}
          >
            <label htmlFor="name"> Print Envelope?:</label>
            <SMSSwitch
              inputProps={{ "aria-label": "recieve sms", readOnly: true }}
            />
          </Grid>
          <Grid
            item
            className={styles.switchContanier + " fieldWrapper"}
            xs={5}
          >
            <label htmlFor="name"> Print Check?:</label>
            <SMSSwitch
              inputProps={{ "aria-label": "recieve sms", readOnly: true }}
            />
          </Grid>
          <Grid
            item
            className={styles.switchContanier + " fieldWrapper"}
            xs={7}
          >
            <label htmlFor="name w-240">
              {" "}
              Include Digital Signature?:
            </label>
            <SMSSwitch
              inputProps={{ "aria-label": "recieve sms", readOnly: true }}
            />
          </Grid>




        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.submitButtons + " submitButtons"}
        >

          <Button
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            // type="submit"
            fullWidth
            variant="contained"
            className="primary-button widthauto"
            onClick={handleFormSubmit}
          >
            Save
          </Button>
        </Grid>
      </Box>
    </StyledDrawer>
  );
};

export default PaymentDrawer;
