import { IAddTasks } from "../interfaces/IAddTasks";

interface ValidationErrors {
  [key: string]: string;
}

export const validateTask = (task: IAddTasks): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!task.costCodeId.trim()) {
    errors.costCodeId = "Category is required.";
  }
  if (!task.taskName.trim()) {
    errors.taskName = "Task name is required.";
  }
  if (!task.scheduleStartDate ) {
    errors.scheduleStartDate = "Date is required.";
  }
  return errors;
};

export const validatePaymentTask = (payment : any): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!payment.budgetAmount) {
    errors.tentativeCost = "Tentative Cost is required.";
  }
  if (!payment.checkPaidTo) {
    errors.checkPaidTo = "Paid To is required.";
  }
  if (!payment.amountPaid) {
    errors.amountPaid = "Amount Paid is required.";
  }
  if (!payment.paymentMemo) {
    errors.paymentMemo = "Payment Memo is required.";
  }
  if (!payment.checkNumber) {
    errors.checkNumber = "Check Number is required.";
  }
  if (!payment.description) {
    errors.description = "Description is required.";
  }
  return errors;
};