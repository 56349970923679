import dayjs from "dayjs";
import { IProject } from "../interfaces";

interface ValidationErrors {
  [key: string]: string;
}

export const validateProject = (project: IProject): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!project.projectName.trim()) {
    errors.projectName = "Project name is required";
  }
  if (isNaN(project.projectTypeId) || project.projectTypeId <= 0) {
    errors.projectTypeId = "Project type is required";
  }
  if (!project.projectStartDate) {
    errors.projectStartDate = "Start date is required";
  }

  if (project.projectStartDate && project.projectEndDate) {
    const startDate = dayjs(project.projectStartDate);
    const endDate = dayjs(project.projectEndDate);
    if (startDate.isAfter(endDate)) {
      errors.projectStartDate = "Start date cannot be after end date";
      errors.projectEndDate = "End date cannot be before start date";
    }
  }

  if (!project.address1.trim()) {
    errors.address1 = "Address is required";
  }
  if (!project.zipCode.trim()) {
    errors.zipCode = "Zip code is required";
  }
  if (!project.stateId) {
    errors.stateId = "State is required.";
  }
  if (isNaN(project.templateId) || project.templateId <= 0) {
    errors.templateId = "Template is required.";
  }
  if (!project.city.trim()) {
    errors.city = "City is required.";
  }
  if (!project.description.trim()) {
    errors.description = "Description is required.";
  }
  return errors;
};
