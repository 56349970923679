import React, { useEffect, useState } from "react";
import styles from "./companySetting.module.scss";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Tab,
  Tabs,
  styled,
  Snackbar,
  FormHelperText,
} from "@mui/material";
import { decryptData } from "../../utils/CryptoJS";
import scheduleSettingService from "../../api/services/ScheduleSetting";
import { useNavigate } from "react-router";
import { BillingInformation, Users } from "../../containers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface IScheduleSetting {
  scheduleDay: string;
  customerId: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      className={styles.tabpanel_container}
    >
      {value === index && <Box component="div">{children}</Box>}
    </div>
  );
}

const CompanySetting: React.FC = () => {
  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "5px",
      marginBottom: "-1px",
      borderRadius: "10px 10px 0px 0px",
      //backgroundColor: "#1814F3",
    },
    ".MuiButtonBase-root": {
      borderRadius: "10px 10px 0px 0px",
      padding: "0px 20px",
      minHeight: "35px",
    },
    "& .Mui-selected": {
      backgroundColor: "var(--bgColor) !important",
      color: "var(--Text-color) !important",
    },
  });

  const location = useLocation();
  const tabFromState = location.state?.tab ?? 0; // Get the tab index from location.state, default to 0
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [scheduleDayError, setScheduleDayError] = useState<string | null>(null);
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    scheduleDay: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [mode, setMode] = useState("");
  // State to handle form data and validation
  const [formDetails, setFormDetails] = useState<IScheduleSetting>({
    scheduleDay: "",
    customerId: "",
  });

  // Handle input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      customerId: userDetailsObj.customerId,
    }));

    if (name === "scheduleDay" && (value === "" || Number(value) <= 0)) {
      setScheduleDayError("Please enter a valid day greater than 0");
    } else {
      setScheduleDayError(null);
    }
  };

  useEffect(() => {
    if (formDetails && formDetails.customerId) {
      setMode("add");
    } else {
      setMode("update");
    }
  }, [formDetails.customerId]);

  useEffect(() => {
    if (mode === "update" || (mode === "add" && formTouched)) {
      const isValid = validateForm();
      setIsFormValid(isValid);
    }
  }, [formDetails]);

  const handleBlur = () => {
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  const validateForm = () => {

    const newErrors = {
      scheduleDay: formDetails.scheduleDay === "",
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  // Handle tab change
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Handle form submission
  const handleSave = async () => {
    if (validateForm()) {
      if (
        formDetails.scheduleDay === "" ||
        Number(formDetails.scheduleDay) <= 0
      ) {
        setScheduleDayError("Please enter a valid day greater than 0");
        return;
      }
      try {
        var result = await scheduleSettingService.sendEmailReminder(formDetails);
        if (result.isSuccess) {
          setSnackbarMessage("Reminder sent for Tasks.");
          setOpenSnackbar(true);
          setFormDetails({
            scheduleDay: "",
            customerId: "",
          });
          setScheduleDayError(null);
        } else {
          setSnackbarMessage(result.errors[0]);
          setOpenSnackbar(true);
          setFormDetails({
            scheduleDay: "",
            customerId: "",
          });
          setScheduleDayError(null);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    }
  };

  // Handle cancel event
  const handleCancel = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (location.state?.tab !== undefined) {
      setTabValue(location.state.tab); // Update the selected tab if location.state changes
    }
  }, [location.state]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <div className={styles.ProjectDetails}>
      <Grid container item className={styles.project_details_container}>
        <Grid item xs={12}>
          <CustomTabs
            value={tabValue}
            className={styles.tab_title}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab id="accoint_management_label" label={t("CompanySetting.accoint_management_label")} />
            <Tab id="payment_methods_label" label={t("CompanySetting.payment_methods_label")} />
            <Tab id="billing_information_label" label={t("CompanySetting.billing_information_label")} />
            <Tab id="schedule_settings_label" label={t("CompanySetting.schedule_settings_label")} />
          </CustomTabs>
          <TabPanel value={tabValue} index={0}>
            <Grid item container spacing={2}>
              <Grid item sm={12}>
                {/* todo: Add Account Management component */}
                <Users></Users>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* <Grid
              container
              className={styles.heading_container}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid
                className={styles.chip_container}
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <h3>Payment Methods</h3>
              </Grid>
            </Grid> */}
            <Grid item container spacing={2}>
              <Grid item sm={12}>
                {/* todo: Add Account Management component */}

              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {/* <Grid
              container
              className={styles.heading_container}
              alignItems="start"
              justifyContent="flex-start"
            >
              <Grid
                className={styles.chip_container}
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <h3> Schedule Setting </h3>
              </Grid>
            </Grid> */}
            <Grid
              container
              className={styles.wrapper_container}
              alignItems="start"
              justifyContent="flex-start"
            >
              <Grid
                container
                className={styles.wrapper_container_inner}
                alignItems="flex-start"
                sx={{ height: "60vh" }}
              >
                <Grid container item sm={5.5} spacing={2}>
                  <Grid item sm={12} mt={4} className={styles.additional_info_title}>
                    <h4 id="notify_text">
                      {t("CompanySetting.notify_text")}
                    </h4>
                  </Grid>
                  <Grid item sm={12} container spacing={2}>
                    <Grid item sm={12} container className="fieldWrapper">
                      <TextField
                        name="scheduleDay"
                        value={formDetails.scheduleDay}
                        onChange={handleInputChange}
                        label={t("CompanySetting.schedule_label")}
                        placeholder={t("CompanySetting.schedule_placeholder")}
                        variant="outlined"
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">{t("CompanySetting.days_postfix")}</InputAdornment>
                          ),
                        }}
                        inputProps={{
                          min: 0,
                        }}
                        onBlur={handleBlur}
                        error={formTouched && errors.scheduleDay}
                        helperText={formTouched && errors.scheduleDay ? "ScheduleDay is required" : ""}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                className={styles.submmitButton_container}
                spacing={3}
                alignItems="flex-start"
              >
                <Grid container item xs={12} className="submitButtons">
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    className="outline-button widthauto"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    className="primary-button widthauto"
                    disabled={!isFormValid}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {/* <Grid
              container 
              className={styles.heading_container}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid
                className={styles.chip_container}
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <h3> Billing Information </h3>
              </Grid>
            </Grid> */}
            <Grid item container spacing={2}>
              <Grid item sm={12}>
                <BillingInformation></BillingInformation>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default CompanySetting;
