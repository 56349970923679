import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./addUsers.module.scss";
import { DeleteDialog, SMSSwitch, StyledDrawer } from "../../../components";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Snackbar, TextField } from "@mui/material";
import { projectService, userService } from "../../../api";
import { IUser } from "../../../interfaces";
import { ValidateUser } from "../../../utils/ValidateUser";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { IProjectList } from "../../../interfaces/IUser";
import IAddUserDrawerProps from "../../../interfaces/IAddUserDrawerProps";

import { Language } from "../../../enums/Language";

import { useNavigate } from "react-router-dom";
import { IDeleteUserParams } from "../../../interfaces/IDeleteUserParams";
const AddUsers: React.FC<IAddUserDrawerProps> = ({
  open,
  onClose,
  onUserAdded,
  user,
}) => {

  const [isSearching, setIsSearching] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const customerId = parseInt(localStorage.getItem("customerId") as string, 10);
  const userId = localStorage.getItem("userId") as string;
  const [userTypes, setUserTypes] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [projectName, setProjectName] = React.useState<number[]>([]);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    userRole: false,
    primaryphone: false,
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string; }>({});
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const language: Language = localStorage.getItem('language') === 'en' ? Language.en : Language.es;
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  const [deleteRequest, setDeleteRequest] = useState<IDeleteUserParams | null>(
    null
  );
  var resetFormDetails: IUser = {
    firstName: "",
    lastName: "",
    email: "",
    projectId: [],
    userTypeId: 0,
    customerId: customerId,
    userId: userId,
    alternatePhoneNumber: "",
    canReceiveSms: false,
    contactNo: "",
    sendSmsToPhone: "",
    projectList: [],
    id: "",
    isLanguage: 0
  };

  const handleDrawerToggle = () => {
    if (onClose) {
      setFormDetails(resetFormDetails);
      setError(null);
      setErrors({
        firstName: false,
        lastName: false,
        email: false,
        userRole: false,
        primaryphone: false,
      });
      setTouchedFields({});
      setValidationErrors({});
      setFormTouched(false);
      onClose();
    }
  };
  const [formDetails, setFormDetails] = useState<IUser>({
    firstName: "",
    lastName: "",
    email: "",
    projectId: [],
    userTypeId: 0,
    customerId: customerId,
    userId: userId,
    alternatePhoneNumber: "",
    canReceiveSms: false,
    contactNo: "",
    sendSmsToPhone: "",
    projectList: [],
    id: "",
    isLanguage: language
  });

  useEffect(() => {
    if (loadData || user) {
      (async () => {
        var userTypesResponse = await userService.getUserTypes();
        if (userTypesResponse.isSuccess) { setUserTypes(userTypesResponse.userTypes); }
        var projectListResponse = await projectService.GetProjectByCustomerId(customerId);
        if (projectListResponse.isSuccess) { setProjectList(projectListResponse.projectList); }
      })()
      setLoadData(false);

      if (user) {
        var edituser: IUser = {
          id: user.id,
          alternatePhoneNumber: user.alternatePhoneNumber,
          canReceiveSms: user.canReceiveSms,
          contactNo: user.contactNo,
          customerId: customerId,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          projectId: user.projectList.map((project: IProjectList) => project.projectId),
          sendSmsToPhone: user.sendSMSToPhone,
          userTypeId: user.userTypeId,
          userId: user.id,
          projectList: user.projectList,
          isLanguage: language
        };
        setFormDetails(edituser);
        setProjectName(user.projectList.map((project: IProjectList) => project.projectId));
      }
    }
  }, [loadData, user]);

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formDetails]);

  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true);
    }
  };
  // Handle change of project name dropdown
  const handleProjectNameChange = (event: SelectChangeEvent<typeof projectName>) => {
    const {
      target: { value },
    } = event;
    const updatedValue = typeof value === "string" ? value.split(",").map(Number) : value;
    var selectedProject = projectList.filter((projectObj: any) => updatedValue.includes(projectObj.projectId));
    formDetails.projectList = selectedProject;
    setProjectName(updatedValue as number[]);
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      projectId: updatedValue as number[],
    }));
  };

  //handling the user role change
  const handleUserRoleChange = (event: SelectChangeEvent<number>) => {
    const selectedTypeId = event.target.value as number;
    if (typeof selectedTypeId === "number") {
      setFormDetails((prevDetails) => ({
        ...prevDetails,
        userTypeId: selectedTypeId,
      }));
    }
  };
  const validateForm = () => {
    const newErrors = {
      firstName: formDetails.firstName === "",
      lastName: formDetails.lastName === "",
      email: formDetails.email === "",
      primaryphone: formDetails.contactNo === "",
      userRole: formDetails.userTypeId === 0,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSaveAddUserData = async () => {
    if (validateForm()) {
      setSavingData(true);
      const errors = ValidateUser(formDetails);
      setValidationErrors(errors);
      if (Object.keys(errors).length === 0) {
        try {
          let response;
          if (!user) {
            // Creating a new user
            response = await userService.createUser(formDetails);
          } else {
            // Updating an existing user
            response = await userService.updateUser(formDetails);
          }

          if (response.isSuccess) {
            if (onUserAdded) {
              onUserAdded();
              setFormDetails(resetFormDetails);
              handleDrawerToggle();
            }
            // if (!user) {
            //   setSnackbarMessage("User added successfully");
            // } else {
            //   setSnackbarMessage("User updated successfully");
            // }
            // setOpenSnackbar(true);  // Show the Snackbar
          } else {
            // Handle specific API errors, like duplicate email error
            const emailErrorMessage = response.errors.find((error: any) =>
              error.includes("Username")
            );
            if (emailErrorMessage) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                email: emailErrorMessage, // Store specific email error message
              }));
            }
            console.error("Error:", response.message);

          }
        } catch (error) {
          // Log specific error details and set appropriate flags
          console.error("Network or server error:", error);
        } finally {
          setSavingData(false);
        }
      } else {
        setSavingData(false);
      }
    }
  };
  //Handle switch changes
  const handleSwitchChange = (e: any) => {
    setFormDetails({
      ...formDetails,
      canReceiveSms: e.target.checked,
    });
    setValidationErrors({});
  };

  const handlePhoneNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);

    setFormDetails({
      ...formDetails,
      [fieldName]: formattedPhoneNumber,
    });
  };
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleDeleteUser = async () => {
    if (user) {
      try {
        const response = await userService.deleteUser(formDetails.userId, formDetails.customerId);
        if (response.isSuccess) {
          if (onUserAdded) {
            onUserAdded();
            setFormDetails(resetFormDetails);
          }
          handleDrawerToggle();
          setSnackbarMessage("User deleted successfully");
          setOpenSnackbar(true);
        } else {
          console.error("Error:", response.message);
        }
      } catch (error) {
        console.error("Network or server error:", error);
      }
    };
  };
  const handleDelete = (userId: string) => {
    setDeleteDialogOpen(true);
    setDeleteRequest({
      userId: userId,
      customerId: customerId,
    });
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteConfirm = async () => {
    handleDeleteUser();
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  return (
    <StyledDrawer className={styles.rightDrawer} open={open} anchor="right">
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            <h3>{user ? "Edit User" : "Add User"}</h3>
            <h5 className="error_color">{error}</h5>
          </Grid>
          <Grid item sm={6} container className="fieldWrapper">
            <TextField
              name="firstName"
              placeholder="First Name"
              label="First Name"
              variant="outlined"
              value={formDetails.firstName}
              onChange={handleChange}
              onBlur={() => handleBlur('firstName')}
              error={touchedFields.firstName && errors.firstName}
              helperText={touchedFields.firstName && errors.firstName ? "First Name is required" : ""}
            />
          </Grid>
          <Grid item sm={6} container className="fieldWrapper">
            <TextField
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              variant="outlined"
              value={formDetails.lastName}
              onChange={handleChange}
              onBlur={() => handleBlur('lastName')}
              error={touchedFields.lastName && errors.lastName}
              helperText={touchedFields.lastName && errors.lastName ? "Last Name is required" : ""}
            />
          </Grid>
          <Grid item sm={12} container className="fieldWrapper">
            <TextField
              name="email"
              placeholder="Email Address"
              label="Email Address"
              variant="outlined"
              value={formDetails.email}
              onChange={handleChange}
              onBlur={() => handleBlur('email')}
              error={touchedFields.email && errors.email}
              helperText={touchedFields.email && errors.email ? "Email is required" : ""}
            />
          </Grid>
          <Grid item xs={12} className="fieldWrapper">
            <TextField
              fullWidth
              value={formDetails.contactNo || ""}
              type="PrimarContactNumber"
              placeholder="Primary Contact Number"
              label="Primary Phone"
              name="PrimarContactNumber"
              onChange={(e: any) => handlePhoneNumberChange(e, "contactNo")}
              onBlur={() => handleBlur('primaryphone')}
              error={touchedFields.primaryphone && errors.primaryphone}
              helperText={touchedFields.primaryphone && errors.primaryphone ? "Primary Phone is required" : ""}
            />
          </Grid>
          <Grid item xs={12} className="fieldWrapper">
            <TextField
              fullWidth
              value={formDetails.alternatePhoneNumber || ""}
              type="AlternatecontactNumber"
              placeholder="Alternate contact number"
              label="Alternate Phone"
              name="AlternatecontactNumber"
              onChange={(e: any) =>
                handlePhoneNumberChange(e, "alternatePhoneNumber")
              }
              onBlur={() => handleBlur('alternatePhone')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className=" fieldWrapper"
            gap={3}
            alignItems="center"
          >
            <FormControl component="fieldset"
            >
              <label htmlFor="name"> Can receive SMS:</label>
              <SMSSwitch
                checked={formDetails.canReceiveSms}
                onChange={(e) => {
                  const canReceiveSms = e.target.checked;
                  handleSwitchChange(e); // This should toggle `canReceiveSms`
                  setFormDetails({
                    ...formDetails,
                    canReceiveSms,
                    sendSmsToPhone: canReceiveSms ? formDetails.sendSmsToPhone : "", // Clear `sendSmsToPhone` if toggle is off
                  });
                }}
                value={formDetails.canReceiveSms || ""}
                //defaultChecked
                inputProps={{ "aria-label": "recieve sms" }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} className="fieldWrapper">
            <FormControl className="inlineSelect"
            >
              <InputLabel id="sms_placeholder">Send SMS to Phone</InputLabel>
              <Select
                labelId="sms_placeholder"
                id="sms"
                placeholder="SMS"
                label="Send SMS to Phone"
                className="selectBox"
                onChange={(e) => {
                  setFormDetails({
                    ...formDetails,
                    sendSmsToPhone: e.target.value,
                  });
                  setValidationErrors({});
                }}
                value={formDetails.sendSmsToPhone || ""}
                input={<OutlinedInput notched label="Send SMS to Phone" />}
                onBlur={() => handleBlur('sendsmstophone')}
              >
                {formDetails.contactNo && (
                  <MenuItem value={formDetails.contactNo}>
                    Primary: {formDetails.contactNo}
                  </MenuItem>
                )}
                {formDetails.alternatePhoneNumber && (
                  <MenuItem value={formDetails.alternatePhoneNumber}>
                    Alternate: {formDetails.alternatePhoneNumber}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} container className="fieldWrapper">
            <FormControl
              className="inlineSelect"
              error={touchedFields.userRole && errors.userRole}
            >
              <InputLabel id="userRole_placeholder">User Role</InputLabel>
              <Select
                name="userRole"
                labelId="userRole_placeholder"
                id="userRole"
                placeholder="User Role"
                label="User Role"
                className="selectBox"
                onChange={handleUserRoleChange}
                value={formDetails.userTypeId}
                onBlur={() => handleBlur('userRole')}
              >
                {userTypes.map((userTypes: any) => (
                  <MenuItem
                    key={userTypes.userTypeId}
                    value={userTypes.userTypeId}
                  >
                    {userTypes.userType}
                  </MenuItem>
                ))}
              </Select>
              {touchedFields.userRole && errors.userRole && (
                <FormHelperText>User Role is required</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} className="fieldWrapper">
            <FormControl
              className="inlineSelect"
            >
              <InputLabel id="type_placeholder" shrink={true}>
                Access To Projects
              </InputLabel>
              <Select
                labelId="type_placeholder"
                id="projectId"
                placeholder="Type"
                className="selectBox"
                label="Access To Projects"
                multiple
                name="projectId"
                value={formDetails.projectId}
                onChange={handleProjectNameChange}
                input={<OutlinedInput notched label="Access To Projects" />}
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const project: any = projectList.find(
                        (projectObj: any) => projectObj.projectId === value
                      );
                      return project ? project.projectName : value;
                    })
                    .join(", ")
                }
                onBlur={() => handleBlur('projectAccess')}
              >
                {projectList?.map((project: any) => (
                  <MenuItem
                    key={project.projectId}
                    value={project.projectId}
                  >
                    <Checkbox
                      checked={
                        formDetails.projectId.indexOf(
                          project.projectId
                        ) > -1
                      }
                    />
                    <ListItemText primary={project.projectName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={12}
          sx={{ justifyContent: "space-between", gap: 0 }}
          className={styles.submitButtons + " submitButtons"}
        >
          <Grid item sm={4}>
            {user ? (<>
              <Button
                fullWidth
                variant="outlined"
                className="primary-button red-button widthauto"
                onClick={() => handleDelete(userId)}
              >
                Delete
              </Button>
            </>) : null}
          </Grid>
          <Grid
            item
            sm={8}
            className="submitButtons"
            sx={{ mt: "0 !important" }}
          >
            <Button
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
              onClick={handleDrawerToggle}
            >
              Cancel
            </Button>

            {isSearching ? null : (
              <Button
                fullWidth
                variant="contained"
                className="primary-button widthauto"
                onClick={handleSaveAddUserData}
                disabled={!isFormValid}
                startIcon={
                  savingData ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
              >
                {savingData ? "Saving..." : "Save"}
              </Button>
            )}
          </Grid>
        </Grid>
        {deleteDialogOpen && (
          <DeleteDialog
            open={deleteDialogOpen}
            title={deleteDialogTitle}
            cancelbuttonText={dialogCancelButtonText}
            submitButtonText={dialogDeleteButtonText}
            cancelbuttonLink={handleDeleteCancel}
            submitButtonLink={handleDeleteConfirm}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
        />
      </Box>
    </StyledDrawer>
  );
};

export default AddUsers;
