import React, { useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import Icon from "@mdi/react";
import { Charts, CustomLoader, RecentActivity, UpcomingPayments } from "../../components";
import {
  mdiAlert,
  mdiBriefcase,
  mdiCalendarMonth,
  mdiCheckBold,
  mdiClipboardList,
  mdiPaletteSwatch,
  mdiProgressClock,
  mdiToolbox,
  mdiTrendingDown,
  mdiTrendingUp,
} from "@mdi/js";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../utils/UserContext";
import { sharedService, userService } from "../../api";
import { mdiCurrencyUsd } from "@mdi/js";
import { IProjectDetails } from "../../interfaces/IDashboardData";
import { useTranslation } from "react-i18next";

const Dashboard: React.FC=  ({}) => {
  const { email } = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [dashboardData, setDashboardData] = useState<IProjectDetails[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    GetUserDetails();
  }, []);
  const [userRoles, setUserRoles] = useState<any>(null);
  const [inProgress, setinProgress] = useState<any>(null);
  const GetUserDetails = async () => {
    try {
      if (email) {
        const userDetails = await userService.getUserDetails(email);
        if (userDetails) {
          debugger
          const data = userDetails.userId;
          var DashboardDetails = await userService.getDashboardData(
            userDetails.userId
          );
          setDashboardData(DashboardDetails.projectDeatilsViewModel);
          setinProgress(DashboardDetails.projectDeatilsViewModel[0].totalInProgressCount)
          localStorage.setItem("userRoleId", userDetails.userAccessDto[0].userTypeId.toString());
          setUserRoles(await sharedService.getUserRolesPermissions());
        }
      }
    } catch (error: any) {
      console.error("Error fetching user details:", error);
    }finally{
      setLoading(false);
    }
  };

  const handleEditProject = (ProjectId: any) => {
    var projectData = dashboardData.find((x) => x.projectId == ProjectId);
    if (projectData) {
      var customerId = localStorage.getItem("customerId");
      if (customerId) {
        sessionStorage.setItem("customerId", customerId);
      }
      localStorage.setItem("userRoleId", projectData.userTypeId.toString());
      sessionStorage.setItem("ActiveTabIndex", JSON.stringify(0));
    }
    navigate("/project-detail", { state: { projectId: ProjectId } });
  };
 
  const CustomNoProjectOverlay: React.FC<any> = () => {
    return (
      <Grid item container className={styles.noProjectOverlay_container}>
        <Grid item sm={12} className={styles.noProjectOverlay}>
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/assets/no_records.svg"}
            className="empty_img"
          />
          <Typography className={styles.empty_msg}>
            There are no projects to show here. Click the button to create a new
            project.
          </Typography>
          <Button
            variant="contained"
            className="primary-button"
            href={userRoles?.create_project ? "/add-project" : ""}
            sx={{ padding: "8px 30px", color: "#FFF" }}
          >
            Create New
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.dashboardDetails}>
      <Grid container item className={styles.details_container}>
        <Grid container item className={styles.dashboardDetails_inner}>        
        {loading ? (
            <CustomLoader></CustomLoader>
          ) : dashboardData.length === 0 ? (
            <CustomNoProjectOverlay></CustomNoProjectOverlay>
          ) : (
            dashboardData.map((project, index) => (
              <Grid
                container
                key={index}
                item
                lg={12}
                md={12}
                className={styles.greyBox}
              >
                <Grid container item spacing={1.5}>
                  <Grid item xl={3} lg={3} sm={6} xs={12}>
                    <div className={styles.whiteBox}>
                      <div className={styles.projectTitle}>
                        <h3
                          onClick={() => handleEditProject(project.projectId)}
                        >
                          {project.projectName}
                        </h3>
                        <span>{project.address}</span>
                        {/* <IconButton
                          aria-label="edit"
                          
                        >
                          <Icon path={mdiPencil} aria-label="edit" size={1} />
                        </IconButton> */}
                      </div>
                      <div className={styles.activityDetails}>
                        <h3 id="last_activity">{t('dashboard.last_activity')}:</h3>
                        {project.lastActivities?.map((activity, index) => (
                          <p key={index} className={styles.verticalAligned}>
                            <Icon
                              color="var(--primary)"
                              path={
                                activity.type === "Task"
                                  ? mdiClipboardList
                                  : activity.type === "Equipment"
                                    ? mdiToolbox
                                    : activity.type === "Material"
                                      ? mdiPaletteSwatch
                                      : mdiBriefcase
                              }
                              size={0.8}
                            />
                            {activity.name} on {activity.lastActivityDate}
                          </p>
                        ))}
                      </div>
                      <div className={styles.taskDetails}>
                        <h3 id="upcoming_tasks">{t('dashboard.upcoming_tasks')}:</h3>
                        <div className={styles.task_item}>
                          <h5>{project.upCommingTaskData?.taskName} </h5>
                          <p className={styles.orgName}>
                            {project.upCommingTaskData?.description}{" "}
                          </p>
                          <p className={styles.date}>
                            {" "}
                            {project.upCommingTaskData?.scheduleStartDate}{" "}
                          </p>
                        </div>
                      </div>
                      <div className={styles.statusDetails}>
                        <h3 id="status">{t('dashboard.status')}:</h3>
                        <p>Overall progress:{inProgress}%</p>
                        <p> On Track: Yes </p>
                        <p> Issues : No </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xl={7} lg={6} sm={8}>
                    <Grid container item spacing={1.5}>
                      <Grid item sm={6} xs={12}>
                        <div className={styles.iconBox}>
                          <div className={styles.iconBoxTitleContainer}>
                            <div className={styles.iconBoxTitle}>
                              <h4 id="task_overdue"> {t('dashboard.task_overdue')} </h4>
                              <span className={styles.totalCount}>
                                {project.taskOverdueCount}
                              </span>
                            </div>
                            <div
                              className={
                                styles.iconBoxIcon + " " + styles.pinkIcon
                              }
                            >
                              {/* <Icon path={mdiAlert} size={1.25} /> */}
                              <img
                                alt=""
                                src={process.env.PUBLIC_URL + "/assets/dashboard-icon/task-overdue.svg"}
                                className="empty_img"
                              />
                            </div>
                          </div>
                          <div className={styles.totalCountSummary}>
                            <p id="task_overdue_info">
                              <span className={styles.green}>
                                {" "}
                                <Icon path={mdiTrendingUp} size={1} />
                              </span>{" "}
                              {t('dashboard.task_overdue_info')}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={styles.iconBox}>
                          <div className={styles.iconBoxTitleContainer}>
                            <div className={styles.iconBoxTitle}>
                              <h4 id="upcoming_task"> {t('dashboard.upcoming_task')}</h4>
                              <span className={styles.totalCount}>
                                {project.upCommingTaskCount}
                              </span>
                            </div>
                            <div
                              className={
                                styles.iconBoxIcon + " " + styles.blueIcon
                              }
                            >
                              {/* <Icon path={mdiCalendarMonth} size={1.25} /> */}
                              <img
                                alt=""
                                src={process.env.PUBLIC_URL + "/assets/dashboard-icon/upcoming-task.svg"}
                                className="empty_img"
                              />
                            </div>
                          </div>
                          <div className={styles.totalCountSummary}>
                            <p id="upcoming_task_info">
                              <span className={styles.green}>
                                {" "}
                                <Icon path={mdiTrendingUp} size={1} />
                              </span>{" "}
                              {t("dashboard.upcoming_task_info")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <div className={styles.iconBox}>
                          <div className={styles.iconBoxTitleContainer}>
                            <div className={styles.iconBoxTitle}>
                              <h4 id="task_completed"> {t('dashboard.task_completed')} </h4>
                              <span className={styles.totalCount}>
                                {project.taskComplatedCount}
                              </span>
                            </div>
                            <div
                              className={
                                styles.iconBoxIcon + " " + styles.greenIcon
                              }
                            >
                              {/* <Icon path={mdiCheckBold} size={1.25} /> */}
                              <img
                                alt=""
                                src={process.env.PUBLIC_URL + "/assets/dashboard-icon/task-completed.svg"}
                                className="empty_img"
                              />
                            </div>
                          </div>
                          <div className={styles.totalCountSummary}>
                            <p id="task_completed_info">
                              <span className={styles.red}>
                                {" "}
                                <Icon path={mdiTrendingDown} size={1} />
                              </span>{" "}
                              {t("dashboard.task_completed_info")}
                            </p>
                          </div>
                        </div>
                      </Grid>
                      {userRoles?.see_financial_data ? (<Grid item sm={6} xs={12}>
                        <div className={styles.iconBox}>
                          <UpcomingPayments />
                        </div>
                      </Grid>) : null}

                      {userRoles?.see_financial_data ? (
                        <Grid item sm={12} xs={12}>
                          <div
                            className={`${styles.iconBox} ${styles.rowDisplay}`}
                          >
                            <Grid item sm={6}>
                              <div className={styles.iconBoxTitleContainer}>
                                <div className={styles.iconBoxTitle}>
                                  <h4 id="total_amount_spent"> {t('dashboard.total_amount_spent')} </h4>
                                  <span className={styles.totalCount}>
                                    $ {project.totalAmountSpent}
                                  </span>
                                </div>
                                <div
                                  className={
                                    styles.iconBoxIcon + " " + styles.greenIcon
                                  }
                                >
                                  {/* <Icon path={mdiCurrencyUsd} size={1.25} /> */}
                                  <img
                                    alt=""
                                    src={process.env.PUBLIC_URL + "/assets/dashboard-icon/total-amount.svg"}
                                    className="empty_img"
                                  />
                                </div>
                              </div>
                              <div className={styles.totalCountSummary}>
                                <p id="total_amount_spent_info">
                                  <span className={styles.green}>
                                    {" "}
                                    <Icon path={mdiTrendingUp} size={1} />
                                  </span>{" "}
                                  {t("dashboard.total_amount_spent_info")}
                                </p>
                              </div>
                            </Grid>
                            <Grid item sm={6} className={styles.smallChart}>
                              <Charts
                                type="Doughnut"
                                paymentPaid={project.paymentPaid}
                              ></Charts>
                            </Grid>
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  {/* <Grid container item xl={2} lg={2} sm={6} xs={12} spacing={1.5}>
                    <Grid item sm={12} xs={12}>
                      <div className={styles.graphBox}>
                      <Charts type="Bar"></Charts>
                      </div>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <div className={styles.graphBox}>
                      <Charts type="Doughnut"></Charts>
                      </div>
                    </Grid>
                  </Grid> */}
                  <Grid container item xl={2} lg={3} sm={4} xs={12}>
                    <div className={styles.whiteBox}>
                      <RecentActivity
                        attachmentDetail={project.attachmentDetails}
                      ></RecentActivity>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
