import React, { useEffect } from "react";
import styles from "./addCostCategoryDialog.module.scss"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Select, SelectChangeEvent, TextField, styled } from "@mui/material";
import { decryptData } from "../../../utils/CryptoJS";
import { useState } from "react";
import { ICostCategoryProps, IAddCostCategoryProps, ICostCodeProps } from '../../../interfaces';
import { settingService } from '../../../api'
import  AddCostCategoryDialogStyles from "./AddCostCategoryDialogStyles";
import { ValidateCostCategoryDetails } from "../../../utils/CostCodeValidation";



const AddCostCategory: React.FC<IAddCostCategoryProps> = ({
  open,
  onClose,
}) => {  
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [costCategorieswithCostCodes, setCostCategoriesWithCostCodes] = useState([]);
  const [addCostCategoryError, setAddCostCategoryError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const [errors,setErrors]=  useState<{ [key: string]: string }>({});
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  // const subAccessNumber = 'SAN0000049';

  const costCategoryObj: ICostCategoryProps = {
    categoryName: '',
    categoryNumber: '',
    createdBy: userDetailsObj.userId,
    // subAccessNumber: ''
  };
  
  const [categoryDetails, setCategoryDetails] = useState({
    ...costCategoryObj,
  })
  //fetching the cost category data
  const fetchData = async () => {
    // if(userDetailsObj.SubAccessNumber){
    try {
      const costcoderesponse = await settingService.getCostCodesWithCategoryList();
      setCostCategoriesWithCostCodes(costcoderesponse.costCodesWithCategories);
    } catch (error) {
      console.error(error);
    }
  // }
  };

  useEffect(() => {
    fetchData();
  }, []);

 //Handling cost category input change
  const handleCostCategoryInputChange = (fieldName: keyof ICostCategoryProps, value: string) => {
    setCategoryDetails({ ...categoryDetails, [fieldName]: value });
  };
  //Handling saving cost category
  const handleSaveCostCategory = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    // categoryDetails.createdBy = userDetailsObj.userId;
    // categoryDetails.subAccessNumber = userDetailsObj.subAccessNumber;
    const validationErrors= ValidateCostCategoryDetails(categoryDetails);
    setErrors(validationErrors);
    
    if (Object.keys(categoryDetails).length===0) {
      try {
        var response = await settingService.addCostCategory(categoryDetails);

        if (response.isSuccess) {
          fetchData();
          handleCloseAddCategoryDialog();
        }

      } catch (error: any) {
        setApiError(error.response.data.errors.join(", "));
      }

    }

  }
  //Handling when the field is inactive
  const handleBlur=(fieldName: keyof ICostCategoryProps)=>{
    const newErrors = ValidateCostCategoryDetails({
      ...categoryDetails,
      [fieldName]: categoryDetails[fieldName],
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  }
  //handling the close of add category dialog
  const handleCloseAddCategoryDialog = () => {
    setOpenAddCategory(false);
    categoryDetails.categoryName = ''
    categoryDetails.categoryNumber = ''
    setAddCostCategoryError(null);
    setErrors({});
    if (onClose) {
      onClose();
    }
  }

  return (
  <AddCostCategoryDialogStyles
    open={open}
    onClose={onClose}
    className={styles.estimate_dialog}
    PaperProps={{
      component: 'form',
      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
      },
    }}
  >
      <DialogTitle>New Cost Category</DialogTitle>
      <DialogContent>
        {addCostCategoryError && <p className={styles.error}>{addCostCategoryError}</p>}
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid container spacing={2} item xs={12}>
              <Grid item container xs={4} className="fieldWrapper">
                <label htmlFor="name"> Category Number:</label>
                <TextField
                  required
                  placeholder="Enter Category Number"
                  variant="outlined"
                  className="width100"
                  type="text"
                  inputProps={{ maxLength: 6 }}
                  value={categoryDetails.categoryNumber}
                  onChange={(e) =>
                    handleCostCategoryInputChange("categoryNumber", e.target.value)
                  }
                  error={!!errors.categoryNumber}
                  onBlur={()=>handleBlur("categoryNumber")}
                  helperText={errors.categoryNumber}
                />
              </Grid>
              <Grid item container xs={8} className="fieldWrapper">
                <label htmlFor="name"> Category Name:</label>
                <TextField
                  required
                  placeholder="Enter Category Name"
                  variant="outlined"
                  className="width100"
                  inputProps={{ maxLength: 2000 }}
                  value={categoryDetails.categoryName}
                  onChange={(e) =>
                    handleCostCategoryInputChange("categoryName", e.target.value)
                  }
                  error={!!errors.categoryName}
                  onBlur={()=>handleBlur("categoryName")}
                  helperText={errors.categoryName}
                  
                />
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          fullWidth
          variant="outlined"
          className="outline-button widthauto"
          onClick={handleCloseAddCategoryDialog}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          fullWidth
          onClick={handleSaveCostCategory}
          variant="contained"
          className="primary-button widthauto"
        >
          Save
        </Button>
      </DialogActions>
  </AddCostCategoryDialogStyles>
  )
};
export default AddCostCategory;

