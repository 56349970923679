import React from "react";
import styles from "./pricingpage.module.scss";
import { Box, Button, Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import Icon from '@mdi/react';
import { mdiCheckDecagram } from "@mdi/js";
import { Link } from "react-router-dom";
import {IPricingPlansProps} from "../../interfaces";

const PricingPage: React.FC = () => {
  // plans details
  const plans: IPricingPlansProps[] = [
    {
      title: 'Project',
      buttonTitle: 'per Project Plan',
      price: '$10/mo',
      features: {
        'Number of Users': '20 Pages',
        'Users Per Page': '5 Pages',
        'Includes essential features to get started': true,
        'More advanced features for increased productivity': true,
        'Designing & Development': false,
        'Customizable options to meet your specific needs': false,
        'Secure data storage': false,
        'Email Support': false,
        '24/7 customer support': false,
      },
    },
    {
      title: 'Month',
      buttonTitle: 'Monthly Plan',
      price: '$20/mo',
      features: {
        'Number of Users': '600 Pages <i> Pages Add-ons on Demand </i>',
        'Users Per Page': '50 Pages',
        'Includes essential features to get started': true,
        'More advanced features for increased productivity': true,
        'Designing & Development': true,
        'Customizable options to meet your specific needs': true,
        'Secure data storage': false,
        'Email Support': false,
        '24/7 customer support': false,
      },
    },
    {
      title: 'Year',
      buttonTitle: 'Yearly Plan',
      price: '$30/mo',
      features: {
        'Number of Users': 'Unlimited <i> Pages Add-ons on Demand </i>',
        'Users Per Page': 'Unlimited <i> Pages Add-ons on Demand </i>',
        'Includes essential features to get started': true,
        'More advanced features for increased productivity': true,
        'Designing & Development': true,
        'Customizable options to meet your specific needs': true,
        'Secure data storage': true,
        'Email Support': true,
        '24/7 customer support': true,
      },
    },
  ];

  // feature list 
  const featureList = [
    'Number of Users',
    'Users Per Page',
    'Includes essential features to get started',
    'More advanced features for increased productivity',
    'Designing & Development',
    'Customizable options to meet your specific needs',
    'Secure data storage',
    'Email Support',
    '24/7 customer support'
  ];

  return (
    <div className={styles.landingContainer}>
      <div className={styles.landingpage_header}>
        <Link to="/"><img alt="logo" src={process.env.PUBLIC_URL + '/assets/logo_white.svg'} width='153px' /></Link>
        <Link className={styles.white_button} to="/login"> Sign In </Link>
      </div>
      <div className={styles.pricingpage_content}>
      <Box className={styles.desktop_table}>
      <Grid container spacing={0}>
        <Grid item sm={12}>
          <Grid container spacing={0} className={styles.card_header}>
            <Grid item sm={3} className={styles.card_header_inner}>
              <Card className={styles.card_style}>
                <CardContent className={styles.card_content}>
                  <div className={styles.plan_title}>
                  <Typography variant="h4">Compare plans</Typography>
                  <Chip label="40% Off" variant="outlined" />
                  </div>
                  <p> Choose your workspace plan according to your organisational plan </p>
                </CardContent>
              </Card>
            </Grid>
            {plans.map((plan, index) => (
              <Grid item sm={3} key={index}>
                <Card className={styles.card_style}>
                  <CardContent className={styles.card_content}>
                    <Typography variant="h6">Pay <span>/ {plan.title}</span></Typography>
                    <Button type="submit" variant="contained" color="primary" fullWidth className={styles.plan_button + ' primary-button'}>
                      Choose {plan.buttonTitle}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {featureList.map((feature, index) => (
          <Grid container spacing={0} key={index}>
            <Grid item sm={3}>
              <Card className={styles.card_style}>
                <CardContent className={styles.card_content}>
                  <Typography variant="body1" className={styles.card_features}>{feature}</Typography>
                </CardContent>
              </Card>
            </Grid>
            {plans.map((plan, planIndex) => (
              <Grid item sm={3} key={planIndex}>
                <Card className={styles.card_style}>
                  <CardContent className={styles.card_content}>
                  {typeof plan.features[feature] === 'string' ? (
                      <Typography variant="body1" className={styles.card_feature_details}>
                        {plan.features[feature] as string}
                      </Typography>
                    ) : (
                      plan.features[feature] && <Icon path={mdiCheckDecagram} size={1} /> 
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      </Box>

      <Box sx={{ p: 4 }} className={styles.mobile_table}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0} className={styles.card_header}>
              <Grid item xs={12} className={styles.card_header_inner}>
                <Card className={styles.card_style}>
                  <CardContent className={styles.card_content}>
                    <div className={styles.plan_title}>
                    <Typography variant="h4">Compare plans</Typography>
                    <Chip label="40% Off" variant="outlined" />
                    </div>
                    <p> Choose your workspace plan according to your organisational plan </p>
                  </CardContent>
                </Card>
              </Grid>
                {plans.map((plan, index) => (
                  <Grid item xs={12} key={index} className={styles.card_header_inner}>
                    <Card className={styles.card_style}>
                      <CardContent className={styles.card_content}>
                        <Typography variant="h6">Pay <span>/ {plan.title}</span></Typography>
                        {featureList.map((feature, index) => (
                        <Grid item container xs={12} className={styles.card_content_xs}>
                          <Grid item xs={6}>
                            <Typography variant="body1" className={styles.card_features}>{feature}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                          {typeof plan.features[feature] === 'string' ? (
                                <Typography variant="body1" className={styles.card_feature_details}>
                                  {plan.features[feature] as string}
                                </Typography>
                              ) : (
                                plan.features[feature] && <Icon path={mdiCheckDecagram} size={1} /> 
                              )}
                          </Grid>
                        </Grid>
                         ))}
                        <Button type="submit" variant="contained" color="primary" fullWidth className={styles.plan_button + ' primary-button'}>
                          Choose {plan.buttonTitle}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
            ))}
          </Grid>
          </Grid>
        </Grid>
      </Box>
      </div>
    </div>
  );
};

export default PricingPage;
