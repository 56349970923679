import React, { useEffect, useState } from "react";
import styles from "./deleteDialog.module.scss";
import styled from "@emotion/styled";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import { IDeleteDialogProps } from "../../interfaces";

const DeleteDialog: React.FC<IDeleteDialogProps> = ({
  title,
  content,
  cancelbuttonLink,
  cancelbuttonText,
  submitButtonLink,
  submitButtonText,
}) => {
  const DeleteDialogContainer = styled(Dialog)({
    "& .MuiPaper-root": {
      maxWidth: "420px",
      width: "420px",
      borderRadius: "14px",
      padding: "25px 30px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      alignContent: "center",
      alignItem: "center",
      textAlign: "center",
    },
    "& .MuiDialogTitle-root": {
      padding: "0px",
    },
    "& .MuiDialogContent-root": {
      padding: "0px",
      paddingBottom: "25px",
      textAlign: "left",
    },
    "& .MuiDialogActions-root": {
      gap: "15px",
      "& button": {
        width: "auto",
      },
    },
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const onClickDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  useEffect(() => {
    setDeleteDialogOpen(true); // Set open state to true on component mount
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const onClickDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <DeleteDialogContainer
      open={deleteDialogOpen}
      className={styles.notification_dialog}
      PaperProps={{
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onClickDeleteDialogClose();
        },
      }}
    >
      <DialogContent>
        <h4>{title}</h4>
        <p>{content}</p>
      </DialogContent>
      <DialogActions className={styles.deteleDialogButton}>
        <Button
          fullWidth
          variant="outlined"
          className="outline-button"
          onClick={cancelbuttonLink}
        >
          {cancelbuttonText}
        </Button>
        <Button
          fullWidth
          variant="contained"
          className="primary-button red-button"
          onClick={submitButtonLink}
        >
          {submitButtonText}
        </Button>
      </DialogActions>
    </DeleteDialogContainer>
  );
};

export default DeleteDialog;
