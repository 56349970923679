import { Modal, Box, Typography, Button } from "@mui/material";
import { useModal } from "../../utils/ModalContext";

const CustomModal = () => {
  const { isOpen, message, closeModal }: any = useModal();

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          borderRadius: 4,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="custom-modal-title"
          sx={{ display: "flex", justifyContent: "center" }}
          variant="h6"
          component="h2"
        >
          <img src="./assets/new_logo_teal.svg" alt="Logo" />
        </Typography>
        <Typography
          id="custom-modal-description"
          sx={{ mt: 3, mb: 3, lineHeight: "1.2", textAlign: "center" }}
        >
          {message}
        </Typography>
        <Button
          sx={{
            backgroundColor: "var(--primary)",
            width: "100%",
            color: "#fff",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "#1C3F3A",
              opacity: 1,
            },
          }}
          onClick={closeModal}
        >
          Sign In
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;
