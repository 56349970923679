import React from "react";
import styles from "./breadcrumbs.module.scss";
import { Button } from "@mui/material";
import { IRouteConfig, IBreadcrumbsProps } from "../../interfaces";
import { useLocation } from "react-router-dom";

const BreadcrumbsComponent: React.FC<IBreadcrumbsProps> = ({ routes }) => {
  const location = useLocation();

  const route: IRouteConfig = React.useMemo(() => {
    return routes.filter((x) => x.path === location.pathname)[0];
  }, [location.pathname, routes]);

  return (
    <div className={styles.breadcrumbs}>
      {route.title ? <h3> {route.title} </h3> : null}
      {route.buttonName && route.buttonLink ? (
        <>
          <ul className={styles.breadcrumbsNav}>
            <li></li>
          </ul>
          <Button
            variant="contained"
            href={route.buttonLink}
            className="primary-button rounded"
          >
            {route.buttonName}
          </Button>
        </>
      ) : null}
    </div>
  );
};

export default BreadcrumbsComponent;
