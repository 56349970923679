import { OutlinedInput } from "@mui/material";
import "./App.css";
import "./materialGlobal.scss";
import { Routes } from "./routes";
import { DrawerProvider } from "./utils/DrawerContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import './i18n'; // Import your i18n configuration here
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 769, // Set 'sm' to 768px
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#0D223F", // Set the primary color
    },
    secondary: {
      main: "#1E1E1E", // Set the secondary color
    },
  },
  // typography: {
  //   fontFamily: "'Calisto MT', serif",
  // },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
        InputLabelProps: {
          shrink: true, // Always shrink the label
        },
      },
      styleOverrides: {
        root: {
          "& .MuiInputBase-root.Mui-disabled": {
            color: "#545454", // Change text color for disabled state
            "-webkit-text-fill-color": "#545454", // Ensure text color is applied
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: "#545454", // Change text color for disabled state
            "-webkit-text-fill-color": "#545454", // Ensure text color is applied
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            color: "#545454", // Change label color for disabled state
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        input: <OutlinedInput notched label="Select Label Lab" />,
      },
      styleOverrides: {
        outlined: {
          "&.Mui-disabled": {
            color: "#545454", // Change text color for disabled state
            "-webkit-text-fill-color": "#545454", // Ensure text color is applied
          },
        },
        icon: {
          "&.Mui-disabled": {
            color: "#545454", // Change icon color for disabled state
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true, // Always shrink the label for Select (via FormControl)
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          fontSize: 14,
        },
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <DrawerProvider>
        <Routes />
      </DrawerProvider>
    </ThemeProvider>
  );
};

export default App;
