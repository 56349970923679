import React, { useEffect, useState, useTransition } from "react";
import styles from "./tasks.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from "@mdi/react";
import {
  mdiAlert,
  mdiArrowDown,
  mdiArrowUp,
  mdiCalendarMonth,
  mdiCancel,
  mdiCheckBold,
  mdiCheckCircle,
  mdiClose,
  mdiContentCopy,
  mdiCurrencyUsd,
  mdiFilter,
  mdiMagnify,
  mdiPencil,
  mdiProgressClock,
  mdiThumbUp,
  mdiTrashCanOutline,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { AddProjectTask } from "../../containers";
import TaskServices from "../../api/services/TaskServices";
import { useLocation, useParams } from "react-router-dom";
import { IDisplayTask } from "../../interfaces/IDisplayTask";
import { IProjectParam } from "../../interfaces";
import { PaymentDrawer } from "../../components";
import { sharedService } from "../../api";
import { useTranslation } from "react-i18next";

const statusOptions = [
  { id: 0, label: "In Progress" },
  { id: 2, label: "Scheduled" },
  { id: 3, label: "Completed" },
  { id: 4, label: "OverDue" },
  { id: 5, label: "Approved" },
  { id: 6, label: "Paid" },
];
const Tasks: React.FC = (props: any) => {
  const location = useLocation();
  const { t } = useTranslation();
  const projectId = location.state?.projectId;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [Tasklist, setTasklist] = useState<IDisplayTask[]>([]);
  const [selectedTask, setSelectedTask] = useState<IDisplayTask | null>(null);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState<string>("default");
  const [sortParams, setSortParams] = useState({
    sortBy: "modifieddate",
    sortDescending: false,
  });
  const [sortDirection, setSortDirection] = useState<boolean>(false); // false for ascending, true for descending
  const displayValue = searchKey.length < 3 ? "" : searchKey;
  // handle for drawer
  const [addTaskDrawerOpen, setAddTaskDrawerOpen] = React.useState(false);
  // New state for filter menu
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);
  // Calculate the total number of pages
  const pageCount = Math.ceil(count / rowsPerPage);
  const columns: GridColDef[] = [
    {
      field: "taskCategory",
      headerName: t("Tasks.taskCategory"),
      width: 200,
      minWidth: 200,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="projectLink cursor_pointer">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "taskName",
      headerName: t("Tasks.taskName"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "taskAssignee",
      headerName: t("Tasks.taskAssignee"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("Tasks.status"),
      width: 150,
      minWidth: 100,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.7,
      renderHeader: () => (
        <>
          <div
            onClick={() => handleSortChange("status")}
            className="sort_container"
          >
            {t("Tasks.status")}
            {sortField === "status" &&
              (sortDirection ? (
                <Icon path={mdiArrowDown} size={0.8} />
              ) : (
                <Icon path={mdiArrowUp} size={0.8} />
              ))}
          </div>
          <IconButton
            aria-controls="filter-menu"
            aria-haspopup="true"
            onClick={handleFilterMenuOpen}
            className={styles.filterIcon}
          >
            <Tooltip title="Filter">
              <Icon path={mdiFilter} aria-label="filter" size={1} />
            </Tooltip>
          </IconButton>
          <Menu
            id="filter-menu"
            keepMounted
            anchorEl={filterMenuAnchorEl}
            open={Boolean(filterMenuAnchorEl)}
            onClose={handleFilterMenuClose}
          >
            {statusOptions.map((status) => (
              <MenuItem key={status.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedStatuses.includes(status.id)}
                      onChange={() => handleStatusChange(status.id)}
                    />
                  }
                  label={status.label}
                />
              </MenuItem>
            ))}
            <MenuItem>
             <Button onClick={applyFilters}>Apply Filters</Button>{" "}
              <Button onClick={clearFilters}>Clear Filters</Button>{" "}
              {/* Clear Filters button */}
            </MenuItem>
          </Menu>
        </>
      ),
      renderCell: (params) => {
        let icon;
        let iconClass;
        switch (params.value) {
          case "Completed":
            icon = mdiCheckBold;
            iconClass = "#29a065";
            break;
          case "In Progress":
            icon = mdiProgressClock;
            iconClass = "var(--primary)";
            break;
          case "Cancelled":
            icon = mdiAlert;
            iconClass = "#ef4135";
            break;
          case "OverDue":
            icon = mdiAlert;
            iconClass = "#ef4135";
            break;
          case "Scheduled":
            icon = mdiCalendarMonth;
            iconClass = "var(--primary)";
            break;
          case "Paid":
            icon = mdiCurrencyUsd;
            iconClass = "#29a065";
            break;
          default:
            icon = mdiProgressClock;
            iconClass = "var(--primary)";
        }
        const scheduleEndDate = new Date(
          params.row.scheduleEndDate
        ).toLocaleDateString();
        return (
          <div className={styles.statusCell}>
            <Icon
              path={icon}
              size={0.8}
              color={iconClass}
              className={styles.iconColor}
            />
            <span>{scheduleEndDate}</span>
          </div>
        );
      },
    },
    {
      field: "taskComments",
      headerName: t("Tasks.taskComments"),
      width: 200,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 2,
    },
  ];


  // Handle opening and closing of the filter menu
  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setFilterMenuAnchorEl(null);
  };
  // Handle checkbox changes for filtering
  const handleStatusChange = (statusId: number) => {
    setSelectedStatuses((prev) =>
      prev.includes(statusId)
        ? prev.filter((id) => id !== statusId)
        : [...prev, statusId]
    );
  };

  // Handle row click
  const handleRowClick = (params: any) => {
    const { row } = params;

    setSelectedTask(row);
    setAddTaskDrawerOpen(true);
  };

  const [refreshTask, setRefreshTask] = useState(false);
  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };
  // Fetch tasks based on projectId
  useEffect(() => {
    fetchTasks(projectId);
  }, [projectId, page, rowsPerPage, searchKey, sortField, sortDirection]);

  useEffect(() => {
    if (refreshTask) {
      fetchTasks(projectId);
    }
  }, [refreshTask]);

  const fetchTasks = async (id: any, updatedSortParams = sortParams) => {
    try {
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: updatedSortParams.sortBy,
          projectTypeId: undefined,
          sortDescending: updatedSortParams.sortDescending,
          searchKey: searchKey,
          StatusId: selectedStatuses,
          isGantt: false,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const response = await TaskServices.getTasksByProjectId(
          id,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );
        setTasklist(response.tasks);
        setCount(response.totalCount);
        setLoading(false);
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };
  
  // Apply filters and fetch tasks
  const applyFilters = () => {
    fetchTasks(projectId); 
    handleFilterMenuClose();
  };
  const clearFilters = async () => {
    setSelectedStatuses([]);
    handleFilterMenuClose(); // Close the filter menu
  };
  const [userRoles, setUserRoles] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTasks(projectId);
      setUserRoles(await sharedService.getUserRolesPermissions());
    };

    if (selectedStatuses.length === 0) {
      fetchData(); // Fetch tasks when statuses are cleared
    }
  }, [selectedStatuses, projectId]);
  const rows = Tasklist.map((task) => ({
    id: task.taskId,
    taskName: task.taskName,
    taskComments: task.comments,
    status: task.status,
    taskCategory: task.costCodeCategoryName,
    taskAssignee: task.assignedUserName,
    assignedToVendorId: task.assignedToVendorId,
    scheduleEndDate: task.scheduleEndDate,
  }));

  // handle for opening drawer
  const handleAddTaskDrawerOpen = () => {
    setAddTaskDrawerOpen(true);
  };

  // handle for closing drawer
  const handleAddTaskDrawerClose = () => {
    setSelectedTask(null);
    setAddTaskDrawerOpen(false);
  };
  // handle for drawer
  const [taskPaymentDrawerOpen, setTaskPaymentDrawerOpen] =
    React.useState(false);

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };
  const handleSortChange = (field: string) => {
    setSortParams((sortParams) => {
      const isSameField = sortParams.sortBy === field;
      const isDescending = isSameField ? !sortParams.sortDescending : true;
      const updatedSortParams = { sortBy: field, sortDescending: isDescending };
      fetchTasks(projectId, updatedSortParams);
      return updatedSortParams;
    });
    if (field === "status") {
      setSortField("scheduleEndDate");
      setSortDirection(!sortDirection); // Toggle direction
    }
  };

  // Handle input changes
  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchKey(value);
  };
  return (
    <div className={styles.tab_grid_container}>
      <Grid
        container
        className={styles.search_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <TextField
            id="search"
            name="search"
            placeholder={t("Tasks.search")}
            variant="outlined"
            className="width30"
            value={searchKey} // Use searchKey directly here
            onChange={handleChange} // Updated handleChange function
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKey ? (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchKey("")} // Clear the search key
                    >
                      <Icon path={mdiClose} size={0.8} />
                    </IconButton>
                  ) : (
                    <Icon path={mdiMagnify} size={1} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => setAddTaskDrawerOpen(userRoles?.add_edit_task ? true : false)}
            className="primary-button rounded"
          >
            {t("Tasks.add_task_btn")}
          </Button>
        </Grid>
      </Grid>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={count}
        loading={loading}
        error={error}
        onRowClick={handleRowClick}
      />

      <AddProjectTask
        open={addTaskDrawerOpen}
        onClose={handleAddTaskDrawerClose}
        task={selectedTask}
        refreshTask={handleDataFromChild}
      />
    </div>
  );
};

export default Tasks;
