import React, { useEffect } from "react";
import styles from "./addCostCodeDialog.module.scss"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, MenuItem, Select, SelectChangeEvent, TextField, styled } from "@mui/material";
import { decryptData } from "../../../utils/CryptoJS";
import { useState } from "react";
import { ICostCodeProps, IAddCostCodeProps } from '../../../interfaces';
import { settingService } from '../../../api'
import  AddCostCodeDialogStyles from "./AddCostCodeDialogStyles";
import { validateCostCodeDetails } from "../../../utils/CostCodeValidation";


const AddCostCode: React.FC<IAddCostCodeProps> = ({
  open,
  onClose,
}) => {  
  const [openAddcode, setOpenAddcode] = React.useState(false);
  const [addCostCodeError, setAddCostCodeError] = useState<string | null>(null);
  const [costCategories, setCostCategories] = useState([]);
  const [apiError, setApiError] = useState<string | null>(null);
  const [errors,setErrors]=  useState<{ [key: string]: string }>({});
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);

  const addCostCodeObj: ICostCodeProps = {
    CostCategoryId: 0,
    CostCodeNumber: '',
    CostCodeName: '',
    CostCodeDescription: '',
    CreatedBy: userDetailsObj.userId,
    // SubAccessNumber: ''
  };

  const [costCodeDetails, setCostCodeDetails] = useState({
    ...addCostCodeObj
  });
//Handling cost category change
  const handleCostCategorySelectChange = (event: SelectChangeEvent<any>) => {
    costCodeDetails.CostCategoryId = event.target.value.costCategoryId;
  };
  //Handling the close of add cost code dialog
  const handleCloseAddCodeDialog = () => {  
    setOpenAddcode(false);
    costCodeDetails.CostCategoryId = 0;
    costCodeDetails.CostCodeName = ''
    costCodeDetails.CostCodeNumber = ''
    costCodeDetails.CostCodeDescription = ''
    setAddCostCodeError(null);
    setErrors({});
    if (onClose) {
      onClose();
    }
  };
  //Fetching the cost category data
  const fetchCostCategoriesData = async () => {
    // if(userDetailsObj.subAccessNumber){
    try {
      var costCategoriesList = await settingService.getCostCategories();
      costCategoriesList = costCategoriesList.costCategories;
      setCostCategories(costCategoriesList);
    } catch (error: any) {
      console.error(error);
      setApiError(error.response.data.errors.join(", "));
    }
  // }
  };

  useEffect(() => {
    fetchCostCategoriesData();
  }, []);

  //Handling input change for cost codes
  const handleCostCodeInputChange = (fieldName: keyof ICostCodeProps, value: string) => {
    setCostCodeDetails({ ...costCodeDetails, [fieldName]: value });
  };
  //handling the saving of cost code
  const handleSaveCostCode = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    
    // For testing purposes, added SAN0000049 sub access number. Remove this in production.
    // costCodeDetails.SubAccessNumber = userDetailsObj.subAccessNumber; 
  
    const validationErrors = validateCostCodeDetails(costCodeDetails);
    setErrors(validationErrors);
  
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await settingService.addCostCode(costCodeDetails);
        if (response.IsSuccess) {
          handleCloseAddCodeDialog();
        }
        fetchCostCategoriesData();
      } catch (error) {
        console.log(error);
      }
    }
  }
  //handling when the field becomes inactive
  const handleBlur=(fieldName: keyof ICostCodeProps)=>{
    const newErrors = validateCostCodeDetails({
      ...costCodeDetails,
      [fieldName]: costCodeDetails[fieldName],
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  }
  
  return (
  <AddCostCodeDialogStyles
    open={open}
    onClose={onClose}
    className={styles.estimate_dialog}
    PaperProps={{
      component: 'form',
      onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
      },
    }}
  >
    <DialogTitle>New Cost Code</DialogTitle>
    <DialogContent>
      {addCostCodeError && <p className={styles.error}>{addCostCodeError}</p>}
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid container spacing={2} item xs={12}>
            <Grid item container xs={4} className="fieldWrapper">
              <label htmlFor="name"> Cost Code Number:</label>
              <TextField
                required
                placeholder="Enter Cost Code Number"
                variant="outlined"
                className="width100"
                type="text"
                inputProps={{ maxLength: 6 }}
                error={!!errors.CostCodeNumber}
                value={costCodeDetails.CostCodeNumber}
                onChange={(e) => {
                  handleCostCodeInputChange('CostCodeNumber', e.target.value)
                }}
                onBlur={()=>handleBlur("CostCodeNumber")}
                helperText={errors.CostCodeNumber}
              />
            </Grid>
            <Grid item container xs={8} className="fieldWrapper">
              <label htmlFor="name"> Cost Code Name:</label>
              <TextField
                required
                placeholder="Enter Cost Code Name"
                variant="outlined"
                className="width100"
                inputProps={{ maxLength: 2000 }}
                value={costCodeDetails.CostCodeName}
                onChange={(e) => {
                  handleCostCodeInputChange('CostCodeName', e.target.value)
                }}
                onBlur={()=>handleBlur("CostCodeName")}
                error={!!errors.CostCodeName}
                helperText={errors.CostCodeName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} item xs={12}>
            <Grid item container xs={12} className="fieldWrapper">
              <label htmlFor="name">Cost Category</label>
              <Select
                labelId="category_placeholder"
                id="categoryNumber"
                placeholder="category number"
                className={styles.inlineSelect + ' selectBox'}
                onChange={handleCostCategorySelectChange}
                error={!!errors.CostCategoryId}
                onBlur={()=>handleBlur("CostCategoryId")}
              >
                {
                  costCategories.map((category: any) => (
                    <MenuItem aria-required key={category.costCategoryId} value={category}>
                      {category.categoryNumber} - {category.categoryName}
                    </MenuItem>
                  ))
                }

              </Select>
              {errors.CostCategoryId&&<FormHelperText error>{errors.CostCategoryId}</FormHelperText>}
            </Grid>

          </Grid>
          <Grid item container xs={12} className="fieldWrapper">
            <label htmlFor="name"> Description:</label>
            <TextField
              required
              placeholder="Enter Description"
              variant="outlined"
              className="width100"
              inputProps={{ maxLength: 2000 }}
              value={costCodeDetails.CostCodeDescription}
              onChange={(e) => {
                handleCostCodeInputChange('CostCodeDescription', e.target.value)
              }}
              onBlur={()=>handleBlur("CostCodeDescription")}
              error={!!errors.CostCodeDescription}
              helperText={errors.CostCodeDescription}
            />
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button
        type="submit"
        fullWidth
        variant="outlined"
        className="outline-button widthauto"
        onClick={handleCloseAddCodeDialog}
      >
        Cancel
      </Button>
              <Button
        type="submit"
        fullWidth
        variant="contained"
        className="primary-button widthauto"
        onClick={handleSaveCostCode}
      >
        Save
      </Button>
    </DialogActions>
  </AddCostCodeDialogStyles>
  )
};
export default AddCostCode;
