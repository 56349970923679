import React, { useEffect, useState } from "react";
import styles from "./scheduleList.module.scss";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiAccountMultiple,
  mdiChevronDown,
  mdiChevronUp,
  mdiMagnify,
} from "@mdi/js";
import { scheduleService } from "../../../api";
import { useLocation } from "react-router-dom";
import { IProjectParam } from "../../../interfaces";
import { CustomPagination } from "../../../components";
import AddProjectTask from "../../Tasks/AddProjectTask/AddProjectTask";
import { useBannerContext } from "../../../utils/BannerContext";
import { useTranslation } from "react-i18next";

const ScheduleList = (props: any) => {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [scheduleTaskList, setScheduleTaskList] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [contact, setContact] = useState<any>(null);
  const [scheduleDate, setScheduleDate] = useState<any>(null);
  const { showBanner } = useBannerContext();
  const {t}= useTranslation();
  // Calculate the total number of pages
  const pageCount = Math.ceil(count / rowsPerPage);
  //This is used to check if the text is truncated
  const isTruncated = (text: string, maxLength: number): boolean => {
    return text.length > maxLength;
  };
  //This is used to truncate the text
  const truncateText = (text: string, maxLength: number): string => {
    if (isTruncated(text, maxLength)) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  //Handling the fetching of data
  const fetchData = async (id: any) => {
    try {
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: undefined,
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: props.searchKey||props.selectKey,
          isGantt: false,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const scheduleTaskListResponse = await scheduleService.getSchedules(
          id,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );
        setScheduleTaskList(scheduleTaskListResponse.schedules || []);
        setCount(scheduleTaskListResponse.totalCount);
        setContact(scheduleTaskListResponse.contact);
        setScheduleDate(scheduleTaskListResponse.scheduleDate);
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };
  useEffect(() => {
    fetchData(projectId);
  }, [projectId, page, rowsPerPage, props.searchKey,props.selectKey]);

  useEffect(() => {
    if (contact) props.onSendData(contact);
    if (scheduleDate) props.onSendScheduleDate(scheduleDate);
  }, [contact, props.onSendData, scheduleDate]);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  // handle for drawer
  const [addTaskDrawerOpen, setAddTaskDrawerOpen] = React.useState(false);
  // handle for closing drawer
  const handleAddTaskDrawerClose = () => {
    setSelectedTask(null);
    setAddTaskDrawerOpen(false);
  };
  const [refreshTask, setRefreshTask] = useState(false);
  useEffect(() => {
    if (refreshTask) {
      fetchData(projectId);
    }
  }, [refreshTask]);

  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };

  // Handle row click
  const handleRowClick = (params: any) => {
    const { row } = params;
    const rows = {
      id: params.scheduleId,
      assignedToVendorId: params.companyId,
    };
    setSelectedTask(rows);
    setAddTaskDrawerOpen(true);
  };

  const ScheduleTaskList: React.FC<{ row: any }> = ({ row }) => {
    const [openRow, setOpenRow] = React.useState(false);

    const truncatedText = truncateText(row.scheduleName, 50);
    const showTooltip = isTruncated(row.scheduleName, 50);

    return (
      <React.Fragment>
        <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          onClick={() => handleRowClick(row)}
        >
          <TableCell
            component="td"
            width="40%"
            className={styles.expand_button_row}
          >
            {/* <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRow(!openRow)}
              className={styles.expand_button}
            > */}
            {/* {openRow ? <Icon path={mdiChevronUp} size={1} /> : <Icon path={mdiChevronDown} size={1} />} */}
            {showTooltip ? (
              <Tooltip title={row.scheduleName}>
                <div className="projectLink cursor_pointer">
                  <span>{truncatedText}</span>
                </div>
              </Tooltip>
            ) : (
              <div className="projectLink cursor_pointer">
                <span>{row.scheduleName}</span>
              </div>
            )}
            {/* </IconButton> */}
          </TableCell>
          <TableCell width="20%">{row.percentageCompleted}%</TableCell>
          <TableCell width="20%">{row.scheduleDate}</TableCell>
          <TableCell width="20%">{row.scheduleEndDate}</TableCell>
        </TableRow>
        {/* <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          onClick={() => handleRowClick(row)}
        >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={openRow} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key="0"
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        width="41%"
                        className={styles.expand_button_row}
                        sx={{
                          "& td, th": { border: 0 },
                        }}
                      >
                        {row.scheduleName}
                      </TableCell>
                      <TableCell width="15%">
                        {row.percentageCompleted}
                      </TableCell>
                      <TableCell width="30%">{row.scheduleDate}</TableCell>
                      <TableCell width="30%">{row.scheduleEndDate}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
      </React.Fragment>
    );
  };

  return (
    <Grid container item xs={12}>
      {/* <Grid container className={styles.search_container} alignItems="center" justifyContent="flex-end">
              <Grid container item xs={6} className={styles.chip_container} alignItems="center" justifyContent="flex-start">
                <TextField
                    placeholder="Search"
                    variant="outlined"
                    className="width30"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon path={mdiMagnify} size={1} />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    select
                    placeholder="Assignee"
                    variant="outlined"
                    className="width30"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon path={mdiAccountMultiple} size={1} />
                        </InputAdornment>
                      ),
                    }}
                  />
              </Grid>
              <Grid className={styles.chip_container} container item xs={6} alignItems="center" justifyContent="flex-end">
                    <span className="today_filter">Today</span>
                    <Select
                      displayEmpty
                      value='Day'
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Day</em>;
                        }
                      }}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem disabled value="">
                        <em>Day</em>
                      </MenuItem>
                    </Select>
              </Grid>
            </Grid> */}
      <Grid container alignItems="flex-start">
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            className={styles.tableContainer}
            sx={{
              height: `${
                showBanner ? "calc(100vh - 345px)" : "calc(100vh - 310px)"
              }`,
            }}
          >
            <Table aria-label="collapsible table" size="medium">
              <TableHead className={styles.tableHeader}>
                <TableRow>
                  <TableCell width="40%">{t("ScheduleList.task_name")}</TableCell>
                  <TableCell width="20%">{t("ScheduleList.completion")}</TableCell>
                  <TableCell width="20%">{t("ScheduleList.start_date")}</TableCell>
                  <TableCell width="20%">{t("ScheduleList.end_date")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(scheduleTaskList) &&
                  scheduleTaskList.map((row, index) => (
                    <ScheduleTaskList key={index} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomPagination
            page={page}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            handleFirstPage={() => handlePageChange(0)}
            handleLastPage={() => handlePageChange(pageCount - 1)}
            handleNextPage={() => handlePageChange(page + 1)}
            handlePreviousPage={() => handlePageChange(page - 1)}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Grid>
      </Grid>
      <AddProjectTask
        open={addTaskDrawerOpen}
        onClose={handleAddTaskDrawerClose}
        task={selectedTask}
        refreshTask={handleDataFromChild}
      />
    </Grid>
  );
};
export default ScheduleList;
