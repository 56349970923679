import React, { useEffect, useState } from "react";
import styles from "./UpdateProject.module.scss";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  TextFieldProps,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Icon from "@mdi/react";
import { mdiCloudUpload } from "@mdi/js";
import {
  ICountry,
  ILookupData,
  IProject,
  IState,
  IAttachment,
} from "../../../interfaces";
import { billingService, projectService } from "../../../api";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate, useSearchParams } from "react-router-dom";
import { validateProject } from "../../../utils/ProjectValidation";
import { LookupTypeId } from "../../../enums";
dayjs.extend(utc);
const UpdateProject: React.FC = () => {
  const [lookupValues, setLookupValues] = useState<any>({});
  const [lookupTypes, setLookupTypes] = useState<any[]>([]);
  const [statusTypes, setStatusTypes] = useState<any[]>([]);
  const [templateTypes, setTemplateTypes] = useState<any[]>([]);
  const [projectTypes, setProjectTypes] = useState<ILookupData[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<{ [key in keyof IProject]?: string }>(
    {}
  );
  const [searchParams] = useSearchParams();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const projectId = searchParams.get("id");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [attachmentData, setAttachmentData] = useState<IAttachment[]>([]);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [projectData, setProjectData] = useState<IProject>({
    projectName: "",
    statusId: 0,
    projectTypeId: 0,
    address1: "",
    address2: "",
    stateId: "",
    zipCode: "",
    city: "",
    projectStartDate: null,
    projectEndDate: null,
    notes: "",
    templateId: 0,
    userId: "",
    files: [],
    projectParams: [],
    description: "",
    customerId: 0,
  });

  // To handle and push the file in filesArray
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = [
      ".pdf",
      ".jpg",
      ".dwf",
      ".dwg",
      ".tif",
      ".jpeg",
      ".png",
    ];
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files);
      const invalidFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      const existingFileNames = new Set(
        attachmentData.map((attachment) => attachment.fileName)
      );

      const newAttachments: Partial<IAttachment>[] = invalidFiles
        .filter((file) => !existingFileNames.has(file.name))
        .map((file, i) => ({
          fileName: file.name,
          isDeleted: false,
          file: file,
        }));

      setAttachmentData((prevAttachments) => [
        ...prevAttachments,
        ...(newAttachments as IAttachment[]),
      ]);
    }
  };
  // To remove Files from the uploded file list
  const removeFile = (index: number) => {
    setAttachmentData((prevAttachments) =>
      prevAttachments
        .map((attachment, i) => {
          if (i === index) {
            if (attachment.attachmentId && attachment.attachmentId !== 0) {
              return { ...attachment, isDeleted: true };
            }
            return undefined;
          }
          return attachment;
        })
        .filter((attachment): attachment is IAttachment => attachment !== null)
    );
  };

  //To get the Countries and States data
  const getCountryStateData = async () => {
    try {
      const data = await billingService.fetchCountryStateData();
      if (data) {
        setCountries(data.country);
        setStates(data.state);
      }
    } catch (error: any) {
      setSnackbarMessage(error.response.data.errors.join(", "));
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    getCountryStateData();
  }, []);
  useEffect(() => {
    // Fetch template data for project templates
    const getTemplateData = async () => {
      try {
        const data = await projectService.getProjectTemplatesAsync();
        if (data.isSuccess) {
          setTemplateTypes(data.projectTemplate);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    //to get LookupData for status projectype and Unit
    const getLookupData = async () => {
      try {
        const data = await projectService.getLookupDataAsync();
        if (data.isSuccess) {
          setLookupTypes(data.lookupType);
          setLookupValues(data.lookupValues);

          const statusTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.StatusType
          );
          const projectTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.ProjectType
          );
          const unitTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.UnitType
          );
          setStatusTypes(statusTypes);
          setProjectTypes(projectTypes);
          setUnits(unitTypes);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    //to fetch the projectdata
    const fetchProjectData = async () => {
      try {
        const data = await projectService.getProjectByProjectId(projectId);
        setProjectData(data.readProjectDetails);
        setAttachmentData(data.readProjectDetails.attachmentList);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    getTemplateData();
    getLookupData();
    fetchProjectData();
  }, [projectId]);

  //To handle the change event
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setProjectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle the state change in the dropdown
  const handleStateChange = (event: SelectChangeEvent<number>) => {
    const selectedStateId = Number(event.target.value);
    setProjectData({
      ...projectData,
      stateId: selectedStateId.toString(),
    });
  };
  // Handle changes for select dropdowns
  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target;
    setProjectData((prevState) => ({
      ...prevState,
      [name!]: value,
    }));
  };
  // Handle date changes for date pickers
  const handleDateChange = (name: keyof IProject, date: Dayjs | null) => {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0];
      setProjectData({
        ...projectData,
        [name]: formattedDate,
      });
    } else {
      setProjectData({
        ...projectData,
        [name]: "",
      });
    }
  };
  //handle when cancel button is clicked.
  const handleCancel = () => {
    navigate("/projects", { replace: true });
  };
  //For the form Submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const projectDataWithProjectId = { projectData };
    const errors = validateProject(projectDataWithProjectId.projectData);
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await projectService.editProject(
          {
            ...projectDataWithProjectId.projectData,
            projectEndDate:
              projectDataWithProjectId.projectData.projectEndDate || null,
          },
          "0"
        );
        navigate("/projects", { replace: true });
      } catch (error: any) {
        if (error.response?.data.errors[0] == "Project Already Exists") {
          setValidationErrors({
            ...validationErrors,
            projectName: "Project Name already exists",
          });
        }
        setErrors(error.response.data.errors);
      }
    }
  };
  //handle when the field becomes inactive
  const handleBlur = (fieldName: keyof IProject) => {
    const newErrors = validateProject({
      ...projectData,
      [fieldName]: projectData[fieldName],
    });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div className={styles.updateProjectcontainer}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Update Project</h3>
        </Grid>
      </Grid>
      <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid container item xs={6}>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Project Name:</label>
              <TextField
                name="projectName"
                value={projectData.projectName}
                onChange={handleChange}
                placeholder="Project Name"
                variant="outlined"
                className="width70"
                onBlur={() => handleBlur("projectName")}
                error={!!validationErrors.projectName}
                helperText={validationErrors.projectName}
              />
            </Grid>

            <Grid item container className={styles.fieldWrapper}>
              <Grid item xs={6.5} className={styles.multiColumns}>
                <label
                  id="status_placeholder"
                  htmlFor="name"
                  className={styles.FirstLabel}
                >
                  Status:
                </label>
                <Select
                  name="statusId"
                  value={projectData.statusId}
                  onChange={handleSelectChange}
                  labelId="status_placeholder"
                  id="statusId"
                  placeholder="Status"
                  className={styles.inlineSelect + " selectBox"}
                  onBlur={() => handleBlur("statusId")}
                  error={!!validationErrors.statusId}
                >
                  {statusTypes.map((lookup) => (
                    <MenuItem
                      key={lookup.lookupValueID}
                      value={lookup.lookupValueID}
                    >
                      {lookup.displayName}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.statusId && (
                  <FormHelperText error>
                    {validationErrors.statusId}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={5.5} className={styles.multiColumns}>
                <label id="type" htmlFor="name">
                  Project Type:
                </label>
                <Select
                  name="projectTypeId"
                  value={projectData.projectTypeId}
                  onChange={handleSelectChange}
                  labelId="type_placeholder"
                  id="projectTypeId"
                  placeholder="Type"
                  onBlur={() => handleBlur("projectTypeId")}
                  className={
                    styles.inlineSelect + " " + styles.width70 + " selectBox"
                  }
                  error={!!validationErrors.projectTypeId}
                >
                  {projectTypes.map((lookup) => (
                    <MenuItem
                      key={lookup.lookupValueID}
                      value={lookup.lookupValueID}
                    >
                      {lookup.displayName}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.projectTypeId && (
                  <FormHelperText error>
                    {validationErrors.projectTypeId}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Address 1:</label>
              <TextField
                name="address1"
                value={projectData.address1}
                onChange={handleChange}
                placeholder="Address"
                variant="outlined"
                className="width70"
                onBlur={() => handleBlur("address1")}
                error={!!validationErrors.address1}
                helperText={validationErrors.address1}
              />
            </Grid>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Address 2:</label>
              <TextField
                name="address2"
                value={projectData.address2}
                onChange={handleChange}
                placeholder="Address"
                variant="outlined"
                className="width70"
                onBlur={() => handleBlur("address2")}
              />
            </Grid>

            <Grid item container className={styles.fieldWrapper}>
              <Grid item xs={5} className={styles.multiColumns}>
                <label id="type" htmlFor="name">
                  State:
                </label>
                {/* <Select
                  name="stateId"
                  value={projectData.stateId}
                  onChange={handleStateChange}
                  labelId="type_placeholder"
                  id="stateId"
                  placeholder="Type"
                  className={
                    styles.inlineSelect + " " + styles.width70 + " selectBox"
                  }
                  error={!!validationErrors.stateId}
                  onBlur={() => handleBlur("stateId")}
                >
                  {states
                    .filter((state) => state.countryId === 233)
                    .map((state) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateName}
                      </MenuItem>
                    ))}
                </Select> */}
                {validationErrors.stateId && (
                  <FormHelperText error>
                    {validationErrors.stateId}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Zip Code:</label>
              <TextField
                name="zipCode"
                value={projectData.zipCode}
                onChange={handleChange}
                placeholder="Zip Code"
                variant="outlined"
                className="width30"
                error={!!validationErrors.zipCode}
                onBlur={() => handleBlur("zipCode")}
                helperText={validationErrors.zipCode}
              />
            </Grid>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Notes:</label>
              <TextField
                name="notes"
                value={projectData.notes}
                onChange={handleChange}
                onBlur={() => handleBlur("notes")}
                id="note"
                placeholder="Notes"
                multiline
                rows={4}
                className="width70"
                error={!!validationErrors.notes}
                helperText={validationErrors.notes}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Start Date:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="width70"
                  value={
                    projectData.projectStartDate
                      ? dayjs.utc(projectData.projectStartDate)
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange("projectStartDate", date)
                  }
                />
              </LocalizationProvider>
              {validationErrors.projectStartDate && (
                <FormHelperText error>
                  {validationErrors.projectStartDate}
                </FormHelperText>
              )}
            </Grid>
            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="name">Estimated End Date:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="width70"
                  value={
                    projectData.projectEndDate
                      ? dayjs.utc(projectData.projectEndDate)
                      : null
                  }
                  onChange={(date) => handleDateChange("projectEndDate", date)}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item container className={styles.fieldWrapper}>
              <label htmlFor="template">Templates:</label>
              <Select
                name="templateId"
                value={projectData.templateId}
                onChange={handleSelectChange}
                labelId="template_placeholder"
                id="templateId"
                placeholder="Template"
                className="selectBox width70"
                error={!!validationErrors.templateId}
                onBlur={() => handleBlur("templateId")}
              >
                {templateTypes.map((template) => (
                  <MenuItem
                    key={template.templateId}
                    value={template.templateId}
                  >
                    {template.displayName}
                  </MenuItem>
                ))}
              </Select>
              {validationErrors.templateId && (
                <FormHelperText error>
                  {validationErrors.templateId}
                </FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              className={styles.fieldWrapper}
              alignItems={"flex-start"}
            >
              <label>Upload Files:</label>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                aria-placeholder="Click here to upload"
                endIcon={
                  <Icon
                    className={styles.uploadButtonIcon}
                    path={mdiCloudUpload}
                    size={1}
                  />
                }
                className={styles.uploadButton}
              >
                Click here to upload
                <input
                  type="file"
                  multiple
                  accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                  className="d_none"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid className={styles.fileListContainer}>
              {attachmentData
                .filter((file) => !file.isDeleted)
                .map((file, index) => (
                  <div key={index} className={styles.fileItem}>
                    <span>{file.fileName}</span>
                    <svg
                      className={styles.cancelIcon}
                      onClick={() => removeFile(index)}
                    >
                      <HighlightOffIcon />
                    </svg>
                  </div>
                ))}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={styles.submitButtons}>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UpdateProject;
