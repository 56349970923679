// to logout user

export const logout = (): void => {
    localStorage.removeItem('userDetails');
    localStorage.removeItem('token');
    localStorage.removeItem('customerId');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRoleId');
    localStorage.removeItem('lastActivity');
    window.location.href = "/";
  };