import React, { useEffect, useState } from "react";
import styles from "./scheduleCalendar.module.scss";
import { Box, Grid, Tooltip } from "@mui/material";
import TimeLine from "react-gantt-timeline";
import { useLocation } from "react-router-dom";
import { scheduleService } from "../../../api"; // Import the new service function
import { IProjectParam } from "../../../interfaces";
import { UserTypeId } from "../../../enums";
import { AddProjectTask } from "../../../containers";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

// Define a custom event interface for your calendar
interface ICalendarEvent {
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  color?: string; // Custom color field
}

const ScheduleCalendar = (props: any) => {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [count, setCount] = React.useState(0);
  const [contact, setContact] = useState<any>(null);
  const [scheduleDate, setScheduleDate] = useState<any>(null);
  // Fetch schedules for the Gantt chart
  const [scheduleTaskList, setScheduleTaskList] = useState<ICalendarEvent[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  // handle for drawer
  const [addTaskDrawerOpen, setAddTaskDrawerOpen] = React.useState(false);
  const [refreshTask, setRefreshTask] = useState(false);
  useEffect(() => {
    if (refreshTask) {
      fetchData(projectId);
    }
  }, [refreshTask]);
  // handle for closing drawer
  const handleAddTaskDrawerClose = () => {
    setSelectedTask(null);
    setAddTaskDrawerOpen(false);
  };

  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };

  const handleTaskClick = (Schedules: any) => {
    if (Schedules && Schedules.id) {
      const selectedTaskData = {
        id: Schedules.id,
        name: Schedules.name,
        assignedToVendorId: Schedules.assignedToVendorId || null,
        assignedToUserId: Schedules.assignedToUserId || null,
      };
      setSelectedTask(selectedTaskData);
      setAddTaskDrawerOpen(true);
    } else {
      console.error("Clicked task has no ID or is invalid.");
    }
  };
  const fetchData = async (projectId: number) => {
    try {
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: 1,
          pageSize: 0,
          sortBy: undefined,
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: props.searchKey,
          isGantt: true,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const data = await scheduleService.getSchedules(
          projectId,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );
        setScheduleTaskList(data.schedules || []);
        setCount(data.totalCount);
        setContact(data.contact);
        setScheduleDate(data.scheduleDate);
        if (data) {
          // Define an array of color options
          const colorOptions: string[] = [
            "#3F4BDB",
            "#00BAE5",
            "#7C71FF",
            "#00B69B",
            "#4AD991",
            "#46E8AF",
          ];

          const schedules = data.schedules.map((task: any) => ({
            id: task.scheduleId,
            title: task.scheduleName,
            start: new Date(task.scheduleDate),
            end: new Date(task.scheduleEndDate),
            allDay: true,
            color:
              colorOptions[Math.floor(Math.random() * colorOptions.length)], // Randomly select a color
          }));
          setScheduleTaskList(schedules);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setError(
        error.response?.data?.errors?.join(", ") || "Failed to load data"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (projectId) {
    fetchData(projectId);
    // }
  }, [projectId, props.searchKey]);

  // eventPropGetter function to style events based on their color property
  const eventPropGetter = (
    event: ICalendarEvent,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    const backgroundColor = event.color || "#3174ad"; // Default color if no color is provided
    return {
      style: {
        backgroundColor,
        color: "white", // Set text color to contrast with the background
      },
    };
  };

  return (
    <Grid item container>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 330px)",
          maxWidth: "100%",
          mx: "auto",
          mt: 2,
          overflow: "hidden",
        }}
      >
        <Calendar
          localizer={localizer}
          events={scheduleTaskList}
          startAccessor="start"
          endAccessor="end"
          titleAccessor="title"
          eventPropGetter={eventPropGetter} // Attach eventPropGetter for custom styles
          views={[Views.MONTH, Views.WEEK, Views.DAY]}
          defaultView={Views.MONTH}
          className="h_100_p"
          onSelectEvent={(task: any) => handleTaskClick(task)}
        />
      </Box>
      <AddProjectTask
        open={addTaskDrawerOpen}
        onClose={handleAddTaskDrawerClose}
        task={selectedTask}
        refreshTask={handleDataFromChild}
      />
    </Grid>
  );
};

export default ScheduleCalendar;
