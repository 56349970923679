import React, { useState, useEffect } from "react";
import styles from "./participant-search-list.module.scss";
import Datatable from "../../../components/datatable/datatable";
import { GridColDef } from "@mui/x-data-grid";
import { IParticipantListProps } from "../../../interfaces/IParticipantListProps";

const ParticipantSearchList: React.FC<IParticipantListProps> = ({ contactData, onRowClick }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const totalContacts = contactData.length;
  const columns: GridColDef[] = [
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      width: 50,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      width: 60,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'companyType',
      headerName: 'Company Type',
      width: 60,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to the first page
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  // Slice contacts for pagination
  const paginatedContacts = contactData.slice(page * pageSize, page * pageSize + pageSize);

  const rows = paginatedContacts.map((contact,index) => ({
    id: index, 
    contactId:contact.contactId,
    companyName: contact.companyName,
    companyType: contact.companyType,
    contactPerson: contact.contactPerson,
  }));

  useEffect(() => {
    setLoading(false); // Assuming loading is complete once data is processed
  }, [contactData]);

  const handleRowClick = (params: any) => {
    const clickedContact = contactData.find(contact => contact.contactId === params.row.contactId);
    if (clickedContact) {
      onRowClick(clickedContact);
    }
  };

  return (
    <div className={styles.Gridcontainer}>
      <Datatable
        rows={rows}
        columns={columns}
        overlayButtonLink={"/add-proposal"}
        page={page}
        rowsPerPage={pageSize}
        rowCount={totalContacts}
        pagination
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onRowClick={handleRowClick}
        loading={loading}
        error={error}
      />
    </div>
  );
};

export default ParticipantSearchList;
