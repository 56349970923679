import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./signup.module.scss";
import {
  Button,
  FormControl,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
  Snackbar,
  FormHelperText,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import userService from "../../api/services/UserService";
import { validateSignupForm } from "../../utils/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import Icon, { Stack } from "@mdi/react";
import { decryptVerifyEmail, encryptData } from "../../utils/CryptoJS";
import { start } from "repl";
import { IState, IUserDetails } from "../../interfaces";
import { billingService } from "../../api";
import { SMSSwitch } from "../../components";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { useTranslation } from "react-i18next";

const Signup: React.FC = () => {
  const [emailError, setEmailError] = useState<string | null>(null);
  const [signupSuccess, setSignupSuccess] = useState<string | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { t } = useTranslation();
  const UserObj: IUserDetails = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    address1: "",
    address2: "",
    city: "",
    stateId: "",
    zipCode: "",
    isLanguage: 1,
  };
  const [states, setStates] = useState<IState[]>([]);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encryptedEmail = params.get("email") || "";
  let decryptedEmail = "";
  if (encryptedEmail) {
    decryptedEmail = decryptVerifyEmail(encryptedEmail);
  }
  const [formDetails, setFormDetails] = useState({
    ...UserObj,
    email: decryptedEmail,
    isLanguage: localStorage.language === 'en' ? 1 : (localStorage.language === 'es' ? 2 : 1),
  });
  const [errors, setErrors] = useState<Partial<IUserDetails>>({});
  const fromFreeTrial = params.get("fromFreeTrial") === "true";
  const [showPassword, setShowPassword] = React.useState(false);
  const [savingData, setSavingData] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate();
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const companyNameRef = useRef<HTMLInputElement>(null);
  const address1Ref = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLSelectElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const zipCodeRef = useRef<HTMLSelectElement>(null);
  const contectNoRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  // To handle field error focus
  const handleFocus = (errors: any) => {
    if (errors.firstName) {
      firstNameRef.current?.focus();
    } else if (errors.lastName) {
      lastNameRef.current?.focus();
    } else if (errors.companyName) {
      companyNameRef.current?.focus();
    } else if (errors.address1) {
      address1Ref.current?.focus();
    } else if (errors.city) {
      cityRef.current?.focus();
    } else if (errors.stateId) {
      stateRef.current?.focus();
    } else if (errors.zipCode) {
      zipCodeRef.current?.focus();
    } else if (errors.contactNo) {
      contectNoRef.current?.focus();
    } else if (errors.email) {
      emailRef.current?.focus();
    } else if (errors.password) {
      passwordRef.current?.focus();
    } else if (errors.confirmPassword) {
      confirmPasswordRef.current?.focus();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePhoneNumberChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const formattedPhoneNumber = formatPhoneNumber(event.target.value);

    setFormDetails({
      ...formDetails,
      [fieldName]: formattedPhoneNumber,
    });
  };

  const handleInputChange = (fieldName: keyof IUserDetails, value: string) => {
    setFormDetails({ ...formDetails, [fieldName]: value });
  };
  const handleBlur = (fieldName: keyof IUserDetails) => {
    const newErrors = validateSignupForm({
      ...formDetails,
      [fieldName]: formDetails[fieldName],
    }, t);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  };

  const handleSave = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSavingData(true);

    const validationErrors = validateSignupForm(formDetails, t);
    setErrors(validationErrors);
    handleFocus(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // setDisable(true); // Disable button to prevent multiple submissions
      try {
        const result = await userService.signUp(encryptData(formDetails));
        if (!result.isSuccess) {
          setSignupSuccess("");
          setEmailError(result.errors.join(", "));
          setSavingData(false);
        } else {
          setEmailError("");
          setSnackbarMessage(
            result.message
          );
          setOpenSnackbar(true);
          setTimeout(() => {
            setOpenSnackbar(false);
            navigate("/login");
          }, 10000);
          setSavingData(false);
        }
      } catch (error: any) {
        setSignupSuccess("");
        if (error.response.data) {
          setEmailError(error.response.data.errors.join(", "));
        }
        console.error("Error:", error);
        setSavingData(false);
      }
    }
    setSavingData(false);
  };

  useEffect(() => {
    //To get the Countries and States data
    if (states.length === 0) {
      (async () => {
        try {
          const data = await billingService.fetchCountryStateData();
          if (data) {
            setStates(data.state);
          }
        } catch (error: any) {
          setErrors(error.response.data.errors.join(", "));
        }
      })();
    }
  });

  return (
    <div className={styles.loginContainer}>
      <div className={styles.login_right}>
        <div className={styles.login_right_content}>
          {signupSuccess && (
            <p className={styles.signupSuccess}>{signupSuccess}</p>
          )}
          <h3 id="welcome_text">{fromFreeTrial ? t("Signup.welcome_text") : t("Signup.sign_up")}</h3>
          {fromFreeTrial && (
            <p id="welcome_sub_text" className={styles.trialMessage}>
              {t("Signup.welcome_sub_text")}
            </p>
          )}

          {!fromFreeTrial && (
            <p className={styles.signupLink}>
              Already a member? <Link href="/login">Log in</Link> now
            </p>
          )}
          {emailError && <p className={styles.errorText}>{emailError}</p>}
          <form onSubmit={handleSave}>
            <Grid
              container
              className={styles.signupFormContainer}
              spacing={2}
              justifyContent="space-around"
            >
              <Grid item className=" fieldWrapper" xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  placeholder={t("Signup.firstName")}
                  label={t("Signup.firstName")}
                  name="firstName"
                  inputRef={firstNameRef}
                  className={styles.textField}
                  value={formDetails.firstName}
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                  onBlur={() => handleBlur("firstName")}
                  error={!!errors.firstName}
                  helperText={
                    errors.firstName && (
                      <span className={styles.errorText}>
                        {errors.firstName}
                      </span>
                    )
                  }
                  autoFocus
                />
              </Grid>
              <Grid item className=" fieldWrapper" xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  placeholder={t("Signup.lastName")}
                  label={t("Signup.lastName")}
                  name="lastName"
                  inputRef={lastNameRef}
                  className={styles.textField}
                  value={formDetails.lastName}
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                  onBlur={() => handleBlur("lastName")}
                  error={!!errors.lastName}
                  helperText={
                    errors.lastName && (
                      <span className={styles.errorText}>
                        {errors.lastName}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  required
                  type="companyName"
                  id="companyName"
                  placeholder={t("Signup.companyName")}
                  label={t("Signup.companyName")}
                  name="companyName"
                  inputRef={companyNameRef}
                  value={formDetails.companyName}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                  onBlur={() => handleBlur("companyName")}
                  error={!!errors.companyName}
                  helperText={
                    errors.companyName && (
                      <span className={styles.errorText}>
                        {errors.companyName}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  required
                  type="address1"
                  id="address1"
                  placeholder={t("Signup.address1")}
                  label={t("Signup.address1")}
                  name="address1"
                  inputRef={address1Ref}
                  value={formDetails.address1}
                  onChange={(e) =>
                    handleInputChange("address1", e.target.value)
                  }
                  onBlur={() => handleBlur("address1")}
                  error={!!errors.address1}
                  helperText={errors.address1}
                />
              </Grid>
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  type="address2"
                  id="address2"
                  placeholder={t("Signup.address2")}
                  label={t("Signup.address2")}
                  name="address2"
                  value={formDetails.address2}
                  onChange={(e) =>
                    handleInputChange("address2", e.target.value)
                  }
                  onBlur={() => handleBlur("address2")}
                />
              </Grid>
              <Grid
                item
                container
                spacing={1}
                xs={12}
                className=" fieldWrapper"
              >
                <Grid item xs={4}>
                  <TextField
                    name="city"
                    id="city"
                    required
                    value={formDetails.city}
                    onChange={(e) => handleInputChange("city", e.target.value)}
                    placeholder={t("Signup.city")}
                    label={t("Signup.city")}
                    inputRef={cityRef}
                    variant="outlined"
                    className="inlineSelect"
                    error={!!errors.city}
                    helperText={errors.city}
                    onBlur={() => handleBlur("city")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    className="inlineSelect"
                    error={!!errors.stateId}
                  >
                    <Autocomplete
                      size="small"
                      id="state-autocomplete"
                      options={states.filter(
                        (state) => state.countryId === 233
                      )} // Filter states by countryId
                      value={
                        states.find(
                          (state) => state.stateId === formDetails.stateId
                        ) || null
                      } // Set value based on stateId
                      onChange={(event, newValue) => {
                        setFormDetails((prevDetails) => ({
                          ...prevDetails,
                          stateId: newValue ? newValue.stateId : "", // Update stateId based on selected state
                        }));
                      }}
                      getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Signup.state-autocomplete")}
                          variant="outlined"
                          inputRef={stateRef}
                          error={!!errors.stateId} // Show error if stateId has an error
                          helperText={errors.stateId} // Display error message if stateId has an error
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="zipCode"
                    id="zipCode"
                    required
                    inputRef={zipCodeRef}
                    value={formDetails.zipCode}
                    onChange={(e) =>
                      handleInputChange("zipCode", e.target.value)
                    }
                    placeholder={t("Signup.zipCode")}
                    label={t("Signup.zipCode")}
                    variant="outlined"
                    error={!!errors.zipCode}
                    helperText={errors.zipCode}
                    onBlur={() => handleBlur("zipCode")}
                  />
                </Grid>
              </Grid>
              {fromFreeTrial && (
                <Grid item container xs={12} className=" fieldWrapper">
                  <TextField
                    fullWidth
                    required
                    type="contactNumber"
                    id="contactNumber"
                    placeholder={t("Signup.contactNumber")}
                    label={t("Signup.contactNumber")}
                    name="contactNumber"
                    inputRef={contectNoRef}
                    value={formDetails.contactNo}
                    onChange={(e: any) =>
                      handlePhoneNumberChange(e, "contactNo")
                    }
                    onBlur={() => handleBlur("contactNo")}
                    error={!!errors.contactNo}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+1-</InputAdornment>
                      ),
                    }}
                    helperText={
                      errors.contactNo && (
                        <span className={styles.errorText}>
                          {errors.contactNo}
                        </span>
                      )
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  required
                  type="email"
                  id="email"
                  placeholder={t("Signup.email")}
                  label={t("Signup.email")}
                  name="email"
                  inputRef={emailRef}
                  value={formDetails.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  onBlur={() => handleBlur("email")}
                  error={!!errors.email}
                  helperText={
                    errors.email && (
                      <span className={styles.errorText}>{errors.email}</span>
                    )
                  }
                  InputProps={{
                    readOnly: !!decryptedEmail,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {!fromFreeTrial && (
                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  className=" fieldWrapper"
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      placeholder="Address Line 1"
                      label="Address Line 1"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} className=" fieldWrapper">
                    <TextField
                      fullWidth
                      placeholder="Address Line 2"
                      label="Address Line 2"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className=" fieldWrapper"
                  >
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        placeholder="City"
                        label="City"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl className="inlineSelect">
                        <Autocomplete
                          size="small"
                          id="state-autocomplete"
                          options={states.filter(
                            (state) => state.countryId === 233
                          )} // Filter states by countryId
                          value={
                            states.find(
                              (state) => state.stateId === formDetails.stateId
                            ) || null
                          } // Set value based on stateId
                          onChange={(event, newValue) => {
                            setFormDetails((prevDetails) => ({
                              ...prevDetails,
                              stateId: newValue ? newValue.stateId : "", // Update stateId based on selected state
                            }));
                          }}
                          getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              variant="outlined"
                              error={!!errors.stateId} // Show error if stateId has an error
                              helperText={errors.stateId} // Display error message if stateId has an error
                              InputLabelProps={{
                                shrink: true,
                              }}
                              autoComplete="no"
                            />
                          )}
                        />
                        {errors.stateId && (
                          <p className={styles.errorText}>{errors.stateId}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        required
                        placeholder="Zip Code"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    className=" fieldWrapper"
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        type="PrimarContactNumber"
                        placeholder="Primary Contact Number"
                        label="Primary Contact Number"
                        name="PrimarContactNumber"
                        value={formDetails.contactNo || "+1-"}
                        onChange={(e) =>
                          handleInputChange("contactNo", e.target.value)
                        }
                        onBlur={() => handleBlur("contactNo")}
                        error={!!errors.contactNo}
                        helperText={
                          errors.contactNo && (
                            <span className={styles.errorText}>
                              {errors.contactNo}
                            </span>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        type="AlternatecontactNumber"
                        placeholder="Alternate contact number"
                        label="Alternate contact number"
                        name="AlternatecontactNumber"
                        value={formDetails.contactNo || "+1-"}
                        onChange={(e) =>
                          handleInputChange("contactNo", e.target.value)
                        }
                        onBlur={() => handleBlur("contactNo")}
                        error={!!errors.contactNo}
                        helperText={
                          errors.contactNo && (
                            <span className={styles.errorText}>
                              {errors.contactNo}
                            </span>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item className={styles.switchContanier} xs={12}>
                    <Typography>Can receive SMS</Typography>
                    <SMSSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "recieve sms" }}
                    />
                  </Grid>
                  <Grid item xs={12} className=" fieldWrapper">
                    <FormControl className="inlineSelect">
                      <InputLabel id="sms_placeholder">
                        Send SMS to Phone
                      </InputLabel>

                      <Select
                        labelId="sms_placeholder"
                        id="sms"
                        placeholder="Send SMS to Phone"
                        label="Send SMS to Phone"
                        className="selectBox"
                        value={formDetails.stateId}
                        onChange={(e) =>
                          handleInputChange("stateId", e.target.value)
                        }
                        onBlur={() => handleBlur("stateId")}
                        error={!!errors.stateId}
                        input={
                          <OutlinedInput notched label="Send SMS to Phone" />
                        }
                      >
                        {/* <MenuItem
                          disabled
                          className="selectPlaceHolder"
                          value=""
                        >
                          Send SMS to Phone
                        </MenuItem> */}
                        <MenuItem value={"Architect"}>Architect</MenuItem>
                        <MenuItem value={"GC"}>General Contractor</MenuItem>
                        <MenuItem value={"Owner"}>Owner</MenuItem>
                      </Select>
                      {errors.stateId && (
                        <p className={styles.errorText}>{errors.stateId}</p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  required
                  placeholder={t("Signup.password")}
                  id="password"
                  label={t("Signup.password")}
                  name="password"
                  inputRef={passwordRef}
                  value={formDetails.password}
                  type={showPassword ? "text" : "password"}
                  onChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                  onBlur={() => handleBlur("password")}
                  error={!!errors.password}
                  helperText={
                    errors.password && (
                      <span className={styles.errorText}>
                        {errors.password}
                      </span>
                    )
                  }
                  InputProps={{
                    endAdornment: formDetails.password && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Icon path={mdiEyeOffOutline} size={1} />
                          ) : (
                            <Icon path={mdiEyeOutline} size={1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} className=" fieldWrapper">
                <TextField
                  fullWidth
                  required
                  placeholder={t("Signup.confirmPassword")}
                  label={t("Signup.confirmPassword")}
                  name="confirmPassword"
                  id="confirmPassword"
                  inputRef={confirmPasswordRef}
                  value={formDetails.confirmPassword}
                  onChange={(e) =>
                    handleInputChange("confirmPassword", e.target.value)
                  }
                  onBlur={() => handleBlur("confirmPassword")}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword && (
                      <span className={styles.errorText}>
                        {errors.confirmPassword}
                      </span>
                    )
                  }
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: formDetails.confirmPassword && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <Icon path={mdiEyeOffOutline} size={1} />
                          ) : (
                            <Icon path={mdiEyeOutline} size={1} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="primary-button login-button"
                  sx={{
                    mt: 3,
                    mb: 4,
                  }}
                  onClick={handleSave}
                  disabled={savingData}
                  startIcon={
                    savingData ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {t("Signup.sign_up")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className={styles.termsLink}>
          <Link
            className={styles.textLink}
            href="/assets/CPro360_Terms_and_Conditions.pdf"
            target="_blank"
            id="term_conditons_link"
          >
            {" "}
            {t("Signup.term_conditons_link")}{" "}
          </Link>
          <Link
            className={styles.textLink}
            href="/assets/CPro360_Privacy_Policy.pdf"
            target="_blank"
            id="privacy_policy_link"
          >
            {" "}
            {t("Signup.privacy_policy_link")}{" "}
          </Link>
        </div>
      </div>

      <div className={styles.login_left}>
        <div className={styles.login_left_content}>
          <img
            src={process.env.PUBLIC_URL + "/assets/new_logo_teal.svg"}
            width="175px"
          />
          <h2 id="welcome_text_right">{t("Signup.welcome_text_right")}</h2>
          <p id="sub_text_right">{t("Signup.sub_text_right")}</p>
          <Button id="read_more_btn" className={styles.white_button}> {t("Signup.read_more_btn")}</Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Signup;
