import { IAddParticipantInputFields } from "../interfaces";

interface ValidationErrors {
  [key: string]: string;
}



export const validateParticipant = (formDetails: IAddParticipantInputFields): ValidationErrors => {
  const errors: ValidationErrors = {};
 
  return errors;
};
