import UserAxios from "../UserAxios";

const getCostCodesWithCategoryList = async () => {
    const response = await UserAxios.get(`Settings/getCostCodesWithCategories`);
    return response.data;
}

const addCostCategory = async (category: any) => {
    const response = await UserAxios.post('Settings/CostCategory', category);
    return response.data;
}

const getCostCategories = async () => {
    const response = await UserAxios.get(`Settings/costcategories`);
    return response.data;
}

const addCostCode = async (costCodeDetails: any) => {
    const response = await UserAxios.post('Settings/CostCodes', costCodeDetails);
    return response.data;
}
// const fetchCategories=async()=>{
//     const response=await UserAxios.get(`Settings/costcategories`)
//     return response.data;
//   }

const getCostCodesAsync = async () => {
    const response = await UserAxios.post(`Settings/GetCostCodesAsync`);
    return response.data;
}

const SaveUserLanguageAsync = async (userId:any, language:any)=> {
   
    const response = await UserAxios.post(`User/SaveUserLanguage?userId=${userId}&language=${language}`);
    return response.data;
}
const settingService = { getCostCodesWithCategoryList, addCostCategory, getCostCategories, addCostCode, getCostCodesAsync ,SaveUserLanguageAsync}

export default settingService;