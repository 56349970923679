import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./Budget.module.scss";
import Icon from "@mdi/react";
import { mdiClose, mdiFormatListBulleted, mdiMagnify } from "@mdi/js";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { IDisplayTask } from "../../interfaces/IDisplayTask";
import { IProjectParam } from "../../interfaces";
import { AddBudget } from "../../containers";
import { scheduleService } from "../../api";
import { CustomLoader, CustomPagination } from "../../components";
import { decryptData } from "../../utils/CryptoJS";
import { useBannerContext } from "../../utils/BannerContext";
import { StringDecoder } from "string_decoder";
import { useTranslation } from "react-i18next";

const Budget: React.FC = () => {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [Tasklist, setTasklist] = useState<IDisplayTask[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [budgetView, setBudgetView] = React.useState<any[]>([]);
  const {t}=useTranslation();
  // Calculate the total number of pages
  const pageCount = Math.ceil(count / rowsPerPage);
  const { showBanner } = useBannerContext();
  const [searchKey, setSearchKey] = useState("");
  const handleEdit = (id: number) => {
    // Implement your edit logic here
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const rows = Tasklist.map((task) => ({
    id: task.taskId,
    materialCategory: task.category,
    materialVendor: task.taskName,
    materialPurchased: task.taskName,
    materialPurchaseDate: task.dueDate,
    materialComments: task.comments,
  }));

  // handle for drawer
  const [addEquipmentDrawerOpen, setAddEquipmentDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleAddEquipmentDrawerOpen = () => {
    setAddEquipmentDrawerOpen(true);
  };

  const handleClick = (event: any, buttonType: string) => {
    if (buttonType == "budget") {
      setAnchorEl(event.currentTarget);
    } else if (buttonType == "payment") {
      setAnchorE2(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };

  // Handle input changes
  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchKey(value);
  };
  //handle Export button click
  const fetchExportData = async (
    id: any,
    reportType: string,
    docTypeId: string
  ) => {
    setError(null);
    try {
      const data = await scheduleService.getExcelOrPDFExport(
        id,
        reportType,
        docTypeId
      );
      if (!data) {
        setSnackbarMessage("No data available for export.");
        setOpenSnackbar(true);
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      if (docTypeId == "1") {
        a.download = `${reportType.toUpperCase()}.pdf`;
      } else {
        a.download = `${reportType.toUpperCase()}.xlsx`;
      }
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      handleClose();
    } catch (err: any) {
      setSnackbarMessage("No data available for export.");
      setOpenSnackbar(true);
    }
  };

  // handle for closing drawer
  const handleAddEquipmentDrawerClose = () => {
    setAddEquipmentDrawerOpen(false);
  };

  // handle for drawer
  const [equipmentPaymentDrawerOpen, setEquipmentPaymentDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleEquipmentPaymentDrawerOpen = () => {
    setEquipmentPaymentDrawerOpen(true);
  };

  // handle for closing drawer
  const handleEquipmentPaymentDrawerClose = () => {
    setEquipmentPaymentDrawerOpen(false);
  };

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };

  //This is used to check if the text is truncated
  const isTruncated = (text: string, maxLength: number): boolean => {
    return text.length > maxLength;
  };

  //This is used to truncate the text
  const truncateText = (text: string, maxLength: number): string => {
    if (isTruncated(text, maxLength)) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  //Handling the fetching of data
  const [budgetList, setBudgetList] = useState<any[]>([]);

  useEffect(() => {
    getBudgetTotal();
  }, [budgetList, []]);

  const getBudgetTotal = async () => {
    if (budgetList.length != 0) {
      budgetList.forEach((item) => {
        item.budgetAmountTotal = item.budgetLineItems.reduce(
          (acc: any, curr: any) => acc + curr.amount,
          0
        );
        item.budgetPriceTotal = item.budgetLineItems.reduce(
          (acc: any, curr: any) => acc + curr.price,
          0
        );
      });
      setBudgetList(budgetList);
    }
  };
  const [categoryTotals, setCategoryTotals] = useState<{
    [key: string]: number;
  }>({});
  const [overallTotal, setOverallTotal] = useState<number>(0);

  //Handling the fetching of data
  const fetchData = async (id: any) => {
    setLoading(true);
    try {
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: undefined,
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: searchKey,
          isGantt: false,
        };
        const scheduleTaskListResponse = await scheduleService.getAllBudgets(
          id,
          params
        );
        setBudgetList(scheduleTaskListResponse.budget || []);
        // Calculate the total amount per category and overall

        setTimeout(() => {
          setLoading(true);
          const newCategoryTotals: { [key: string]: number } = {};
          let newOverallTotal = 0;
          budgetList.forEach((item: any) => {
            const categoryTotal = item.budgetLineItems.reduce(
              (sum: number, lineItem: any) => sum + lineItem.amount,
              0
            );
            newCategoryTotals[item.categoryNumber] = categoryTotal;
            const categoryPriceTotal = item.budgetLineItems.reduce(
              (sum: number, lineItem: any) => sum + lineItem.price,
              0
            );
            newOverallTotal += categoryPriceTotal;
          });

          setCategoryTotals(newCategoryTotals);
          setOverallTotal(scheduleTaskListResponse.totalPrice);
          setLoading(false);
        }, 3000);
        setCount(scheduleTaskListResponse.totalCount);
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(projectId);
  }, [projectId, page, rowsPerPage,searchKey]);

 

  const BudgetCategoryList: React.FC<{
    key: any;
    row: any;
    budgetKey: any;
  }> = ({ key, row }) => {
    const [openRow, setOpenRow] = React.useState(true);
    const truncatedText = truncateText(row.categoryName, 50);
    const showTooltip = isTruncated(row.categoryName, 50);
    const [editMode, setEditMode] = useState<{
      [key: number]: "amount" | null;
    }>({});
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (
      id: number,
      field: "amount",
      value: number,
      bkey: string
    ) => {
      var budgetObj = budgetList.find((item) => item.categoryNumber === bkey);
      var budgetLineItemsObj = budgetObj.budgetLineItems.find(
        (items: any) => items.budgetId === id
      );
      budgetLineItemsObj[field] = value;
      setBudgetList(budgetList);
      getBudgetTotal();
    };

    const handleCellClick = (id: number, field: "amount") => {
      setEditMode({ [id]: field });
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    };

    const handleBlur = (childElement: any) => {
      setTimeout(() => {
        setEditMode({ [childElement.budgetId]: null });
      }, 200);
      var customerId = parseInt(
        sessionStorage.getItem("customerId") as string,
        10
      );
      var userId = localStorage.getItem("userId") as string;

      var param = {
        id: childElement.budgetId,
        amount: childElement.amount,
        budgetType: childElement.budgetType,
        customerId: customerId,
        projectId: parseInt(projectId?.toString() || ""),
        userId: userId,
      };
      saveBudgetAmount(param);
    };
    const saveBudgetAmount = async (param: any) => {
      try {
        if (projectId) {
          await scheduleService.updateAllBudgetDataAsync(param);
          setLoading(false);
        }
      } catch (error: any) {
        setError(error.response.data.errors.join(", "));
        setLoading(false);
      }
    };

    return (
      <React.Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell
            component="td"
            width="30%"
            sx={{ minWidth: 250 }}
            className={styles.expand_button_row}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenRow(!openRow)}
              className={styles.expand_button}
            >
              {showTooltip ? (
                <Tooltip title={row.categoryName}>
                  <span>{truncatedText}</span>
                </Tooltip>
              ) : (
                <span>
                  {row.categoryNumber} {row.categoryName}
                </span>
              )}
            </IconButton>
          </TableCell>
          <TableCell width="25%" sx={{ minWidth: 200 }}>
            {row.description}
          </TableCell>
          <TableCell width="15%" sx={{ minWidth: 200 }}>
            {row.budgetType}
          </TableCell>
          <TableCell
            align="right"
            width="15%"
            sx={{ minWidth: 100 }}
            className={styles.expand_button}
          >
            $ {row.budgetAmountTotal}
          </TableCell>
          <TableCell
            align="right"
            width="15%"
            sx={{ minWidth: 100 }}
            className={styles.expand_button}
          >
            $ {row.budgetPriceTotal}{" "}
          </TableCell>
        </TableRow>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell className="p_0" colSpan={6}>
            <Collapse in={openRow} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {row.budgetLineItems.map(
                      (row1: any, index: any, budgetKey: any) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                        >
                          <TableCell
                            component="td"
                            scope="row"
                            width="30%"
                            sx={{ minWidth: 517 }}
                            className={styles.expand_button_row}
                            style={{ paddingLeft: 50 }}
                          >
                            {row1.costCodeId} {row1.costCodeName}
                          </TableCell>
                          <TableCell width="25%" sx={{ minWidth: 200 }}>
                            {row1.description}
                          </TableCell>
                          <TableCell width="15%" sx={{ minWidth: 200 }}>
                            {row1.budgetType}
                          </TableCell>
                          <TableCell
                            align="right"
                            width="15%"
                            sx={{ minWidth: 100 }}
                            onClick={() =>
                              handleCellClick(row1.budgetId, "amount")
                            }
                          >
                            {editMode[row1.budgetId] === "amount" ? (
                              <TextField
                                name="amount"
                                autoFocus
                                defaultValue={row1.amount}
                                //value={row1.amount}
                                //type="number"
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleInputChange(
                                    row1.budgetId,
                                    "amount",
                                    parseFloat(e.target.value),
                                    row.categoryNumber
                                  )
                                }
                                onBlur={() => handleBlur(row1)}
                                size="small"
                                // placeholder="$00,00,00"
                                fullWidth
                                className="textNumberField"
                                InputProps={{
                                  type: "number",
                                  sx: {
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                      display: "none",
                                    },
                                    "& input[type=number]": {
                                      MozAppearance: "textfield",
                                    },
                                  },
                                }}
                              />
                            ) : (
                              <>$ {row1.amount}</>
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                            width="15%"
                            sx={{ minWidth: 100 }}
                          >
                            $ {row1.price}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.search_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <TextField
            id="search"
            name={t("Budget.search")}
            placeholder={t("Budget.search")}
            variant="outlined"
            className="width30"
            value={searchKey} // Use searchKey directly here
            onChange={handleChange} // Updated handleChange function
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKey ? (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchKey("")} // Clear the search key
                    >
                      <Icon path={mdiClose} size={0.8} />
                    </IconButton>
                  ) : (
                    <Icon path={mdiMagnify} size={1} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* <Select
            size="small"
            labelId="status_placeholder"
            id="status"
            placeholder="Material"
            displayEmpty
            defaultValue={10}
            value={10}
            input={<OutlinedInput />}
            className="inlineSelect selectBox width30"
            // onChange={handleEntitiesChange}
            sx={{
              "& .MuiSelect-outlined": {
                display: "flex",
                alignItems: "center",
                gap: "10px",
              },
            }}
          >
            <MenuItem selected value={10}>
              <Icon path={mdiFormatListBulleted} size={1} /> Category View
            </MenuItem>
          </Select> */}
        </Grid>
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="contained"
            className="primary-button"
            onClick={(event) => handleClick(event, "payment")}
            sx={{
              "@media screen and (max-width:768px)": {
                width: "47% !important",
              },
            }}
          >
            {t("Budget.export_by_payment_btn")}
          </Button>
          <Menu
            anchorEl={anchorE2}
            open={Boolean(anchorE2)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => fetchExportData(projectId, "payment", "1")}
            >
              Export PDF
            </MenuItem>
            <MenuItem
              onClick={() => fetchExportData(projectId, "payment", "2")}
            >
              Export Excel
            </MenuItem>
          </Menu>

          <Button
            variant="contained"
            className="primary-button"
            onClick={(event) => handleClick(event, "budget")}
            sx={{
              "@media screen and (max-width:768px)": {
                width: "47% !important",
              },
            }}
          >
           {t("Budget.export_by_budget_btn")}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => fetchExportData(projectId, "budget", "1")}>
              Export PDF
            </MenuItem>
            <MenuItem onClick={() => fetchExportData(projectId, "budget", "2")}>
              Export Excel
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer
          component={Paper}
          className={styles.tableContainer}
          sx={{
            height: `${showBanner ? "calc(100vh - 400px)" : "calc(100vh - 380px)"
              }`,
          }}
        >
          {loading && <CustomLoader></CustomLoader>}
          <Table stickyHeader aria-label="collapsible table" size="medium">
            <TableHead className={styles.tableHeader}>
              <TableRow>
                <TableCell width="30%" sx={{ minWidth: 250 }}>
                {t("Budget.item")}
                </TableCell>
                <TableCell width="25%" sx={{ minWidth: 200 }}>
                {t("Budget.description")}
                </TableCell>
                <TableCell width="15%" sx={{ minWidth: 100 }}>
                {t("Budget.type")}
                </TableCell>
                <TableCell align="right" width="15%" sx={{ minWidth: 100 }}>
                {t("Budget.budgeted")}
                </TableCell>
                <TableCell align="right" width="15%" sx={{ minWidth: 100 }}>
                {t("Budget.actuals")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="h_100_p">
              {Array.isArray(budgetList) &&
                budgetList.map((row, index) => (
                  <BudgetCategoryList key={index} row={row} budgetKey={index} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          page={page}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          handleFirstPage={() => handlePageChange(0)}
          handleLastPage={() => handlePageChange(pageCount - 1)}
          handleNextPage={() => handlePageChange(page + 1)}
          handlePreviousPage={() => handlePageChange(page - 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <TableContainer>
          <Table size="small">
            <TableFooter>
              <TableRow className={styles.total_sum}>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell colSpan={2} align="right"></TableCell>
                <TableCell align="right">
                  {" "}
                  $ {overallTotal.toLocaleString()}{" "}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      <AddBudget
        open={addEquipmentDrawerOpen}
        onClose={handleAddEquipmentDrawerClose}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Budget;
