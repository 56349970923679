import { IVendorParam } from "../../interfaces";
import IDeleteVendorParam from "../../interfaces/IDeleteVendorParam";
import ProjectAxios from "../ProjectAxios";
import UserAxios from "../UserAxios";

const getVendor = async (customerId: number, param: IVendorParam) => {
  const response = await UserAxios.post(`Vendors/GetVendorListAsync/${customerId}`, param);
  return response.data;
};

const getVendorById = async (vendorId: any) => {
  const response = await UserAxios.get(`Vendors/GetVendorByIdAsync?vendorId=${vendorId}`);
  return response.data;
}

const getVendorList = async () => {
  const response = await UserAxios.get('Vendors/GetVendorTypes');
  return response.data;
}
const getContactsByVendorId = async (vendorId: number, customerId: number) => {
  try {
    const response = await ProjectAxios.get(`Vendors/GetContactsByVendorId`, {
      params: {
        VendorId: vendorId,
        CustomerId: customerId
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts by vendor ID:", error);
    throw error;
  }
};
const DeleteVendorAsync = async (vendorId: any, userId: any) =>{
  const response = await UserAxios.delete("/Vendors/DeleteVendorAsync", {
    data: {
      vendorId,
      userId,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });;
  return response.data;
}

const DeleteVendorDocumentAsync = async (attachmentId: number, userId: string) =>{
  const response = await UserAxios.delete(
    `/Vendors/DeleteVendorDocument?AttachmentId=${attachmentId}&userId=${userId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}


const UpdateVendorAsync = async (formData: FormData) =>{
  const response = await UserAxios.put(`Vendors/UpdateVendorAsync`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

const CreateVendorAsync = async (formData: FormData) =>{
  const response = await UserAxios.post(`Vendors/CreateVendorAsync`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}
const getUserAssignmentStatus = async (contactId: string, customerId: number, vendorId: number) => {
  try {
    const response = await ProjectAxios.get(`Vendors/GetUserAssignmentStatus`, {
      params: {
        userId: contactId,
        CustomerId: customerId,
        VendorId: vendorId
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching contacts by vendor ID:", error);
    throw error;
  }
};
const vendorServices = { getUserAssignmentStatus,getVendor, getVendorById, getVendorList, getContactsByVendorId,CreateVendorAsync, UpdateVendorAsync, DeleteVendorAsync,DeleteVendorDocumentAsync}

export default vendorServices;