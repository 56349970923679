import { Dialog, styled } from "@mui/material";
  
export const AddCostCodeDialogStyles = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '800px',
    borderRadius: '14px',
    padding: '30px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  '& .MuiDialogActions-root': {
  },
  '& .MuiFormControl-root': {
    paddingLeft: '0px !important'
  },
  '& .selectBox.MuiInputBase-root': {
    marginLeft: '0px !important'
  }
}));

  export default AddCostCodeDialogStyles;