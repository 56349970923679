import { IProjectParam } from "../../interfaces";
import ProjectAxios from "../ProjectAxios";


const getSchedules = async (projectId: number, userTypeId: number, userId: string, param: IProjectParam) => {
    const response = await ProjectAxios.post(`Projects/GetScheduleByProjectId?ProjectId=${projectId}&userTypeId=${userTypeId}&userId=${userId}`, param);
    return response.data;
}

const getAllBudgets = async (projectId: number, param: IProjectParam) => {
    const response = await ProjectAxios.post(`Projects/GetAllBudgets/${projectId}`, param);

    return response.data;
}
const updateAllBudgetDataAsync = async (param: any) => {
    const response = await ProjectAxios.put(`Projects/UpdateAllBudgetDataAsync`, param);

    return response.data;
}

const getExcelOrPDFExport = async (projectId: string | number, reportType: string, docTypeId: string) => {
    const response = await ProjectAxios.get(`Report/ExportScheduleToExcelorPDF?projectId=${projectId}&reportType=${reportType}&docTypeId=${docTypeId}`, {
        responseType: 'blob',
    });
    return response.data;
}
const getSchedulesForGanttChart = async (projectId: number) => {
    const response = await ProjectAxios.get(`Projects/GetAllSchedulesByProjectId?ProjectId=${projectId}`)
    return response.data;
}
const scheduleService = { getSchedules, getAllBudgets, updateAllBudgetDataAsync, getExcelOrPDFExport, getSchedulesForGanttChart }

export default scheduleService;