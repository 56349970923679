import { getDateFromDateSections } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { IProjectParam, IDeleteProjectParam, IParticipants, IPayment, IAttachmentNotification, IAddParticipant, IProject } from "../../interfaces";
import ProjectAxios from "../ProjectAxios";

const deleteProject = async (param: IDeleteProjectParam) => {
  const response = await ProjectAxios.delete('Projects/DeleteProjectAsync', {
    data: param
  });
  return response.data;
}

const getProjectByUserId = async (userId: any, param: IProjectParam) => {

  const response = await ProjectAxios.post(`Projects/GetProjectsByUserIdAsync/${userId}`, param)
  return response.data;
};
const getLookupDataAsync = async () => {
  const response = await ProjectAxios.get(`Projects/GetLookupDataAsync`)
  return response.data;
};
const getProjectTemplatesAsync = async () => {
  const response = await ProjectAxios.get('Projects/GetProjectTemplatesAsync');
  return response.data;
};
const getProjectByProjectId = async (projectId: any) => {
  const response = await ProjectAxios.get(`Projects/${projectId}`);
  return response.data;
}
const editProjectName = async (projectParam: any) => {
  const response = await ProjectAxios.put(`Projects/EditProjectName`, projectParam)
  return response.data;
};
const editProject = async (projectData: any, projectId: string) => {
  const formData = new FormData();
  formData.append('ProjectName', projectData.projectName);
  formData.append('StatusId', projectData.statusId.toString());
  formData.append('ProjectTypeId', projectData.projectTypeId.toString());
  formData.append('AddressId', projectData.addressId.toString());
  formData.append('Address1', projectData.address1);
  formData.append('Address2', projectData.address2);
  formData.append('ZipCode', projectData.zipCode);
  formData.append('StateId', projectData.stateId.toString());
  formData.append(
    'projectStartDate',
    projectData.projectStartDate
      ? new Date(projectData.projectStartDate).toISOString().split('T')[0]
      : ""
  );
  formData.append(
    'projectEndDate',
    projectData.projectEndDate
      ? new Date(projectData.projectEndDate).toISOString().split('T')[0]
      : ""
  );
  formData.append('Notes', projectData.notes);
  formData.append('TemplateId', projectData.templateId.toString());
  formData.append('UserId', projectData.userId);
  formData.append('ProjectId', projectId);
  formData.append('city', projectData.city);
  formData.append('description', projectData.description);
  // Append each array of projectparams
  projectData.projectParams.forEach((param: any, index: any) => {
    formData.append(`projectparams[${index}][paramName]`, param.paramName);
    formData.append(`projectparams[${index}][paramValue]`, param.paramValue);
    formData.append(`projectparams[${index}][paramUnitOfMeasure]`, param.paramUnitOfMeasure);
    formData.append(`projectparams[${index}][projectParamsId]`, param.projectParamsId);
  });
  projectData.files.forEach((file: any) => {
    formData.append('files', file);
  });
  formData.append('customerId', projectData.customerId);
  const response = await ProjectAxios.put(`Projects`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data
}
const getProjectParamByTemplateIdAsync = async (templateId: any) => {
  const response = await ProjectAxios.get(`Projects/GetProjectParamByTemplateIdAsync/${templateId}`);
  return response.data;
}
const getContactsList = async (customerId: number, searchParam: any) => {
  const response = await ProjectAxios.get(`Projects/GetContacts/${customerId}/${searchParam}`);
  return response.data;
}
const vendorsWithVendorTypeIds = async (customerId: number) => {
  const response = await ProjectAxios.get(`Projects/GetVendorAndTypes/${customerId}`)
  return response.data;
}
const addParticipantAsync = async (participantData: string) => {
  const response = await ProjectAxios.post(`Projects/AddParticipantAsync`, participantData, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return response.data
}
const GetProjectParticipantList = async (projectId: number, customerId: number, params: any): Promise<IParticipants> => {
  const response = await ProjectAxios.post(`Projects/GetProjectParticipantListAsync/${projectId}/${customerId}`, params)
  return response.data;
}

const AttachmentNotification = async (requestParam: IAttachmentNotification) => {
  const response = await ProjectAxios.post(`Communication/sendNotificationForAttachments`, requestParam)
  return response.data;
}
const GetParticipantAsync = async (userAccessId: number) => {
  const response = await ProjectAxios.get(`Projects/GetParticipant/${userAccessId}`)
  return response.data;
}
const EditParticipantAsync = async (requestParam: string) => {
  const response = await ProjectAxios.put(`Projects/EditParticipantAsync`, requestParam, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return response.data
}

const addProject = async (projectDetails: IProject) => {
  const formData = new FormData();

  formData.append('projectName', projectDetails.projectName);
  formData.append('statusId', projectDetails.statusId.toString());
  formData.append('projectTypeId', projectDetails.projectTypeId.toString());
  formData.append('projectStartDate', projectDetails.projectStartDate?.format("YYYY-MM-DD").toString() ?? "");
  formData.append('projectEndDate', projectDetails.projectEndDate?.format("YYYY-MM-DD").toString() ?? "");
  formData.append('notes', projectDetails.notes);
  formData.append('templateId', projectDetails.templateId.toString());
  formData.append('userId', projectDetails.userId);
  formData.append('address1', projectDetails.address1);
  formData.append('address2', projectDetails.address2);
  formData.append('zipCode', projectDetails.zipCode);
  formData.append('stateId', projectDetails.stateId.toString());
  formData.append('city', projectDetails.city.toString());
  // Append each array of projectparams
  projectDetails.projectParams.forEach((param, index) => {
    formData.append(`projectparams[${index}][paramName]`, param.paramName);
    formData.append(`projectparams[${index}][paramValue]`, param.paramValue);
    formData.append(`projectparams[${index}][paramUnitOfMeasure]`, param.paramUnitOfMeasure);
  });
  // Append each file
  projectDetails.files.forEach(file => {
    formData.append('files', file);
  });
  formData.append('description', projectDetails.description.toString());
  formData.append('customerId', projectDetails.customerId.toString());

  const response = await ProjectAxios.post(`Projects/CreateProjectAsync`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

const GetProjectByCustomerId = async (projectId: number) => {
  const response = await ProjectAxios.get(`Projects/GetProjectByCustomerId/${projectId}`)
  return response.data;
}

const projectService = {
  editProjectName, EditParticipantAsync, GetParticipantAsync, deleteProject, getProjectByUserId, editProject, getProjectByProjectId, getLookupDataAsync, getProjectTemplatesAsync, getProjectParamByTemplateIdAsync, getContactsList, vendorsWithVendorTypeIds, addParticipantAsync, GetProjectParticipantList
  , AttachmentNotification, addProject, GetProjectByCustomerId
};

export default projectService;