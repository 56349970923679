export const formatPhoneNumber = (phoneNumber: string) => {
  // Remove non-numeric characters
  let input = phoneNumber.replace(/\D/g, "");

  // Format the input value as (123) 456-7890
  if (input.length > 3 && input.length <= 6) {
    input = `(${input.slice(0, 3)}) ${input.slice(3)}`;
  } else if (input.length > 6) {
    input = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
  }

  return input;
};
