
import { Task } from "@mui/icons-material";
import { IProjectParam } from "../../interfaces";
import { IAddTasks } from "../../interfaces/IAddTasks";
import ProjectAxios from "../ProjectAxios";
import UserAxios from "../UserAxios";
import { EntityType } from "../../enums";

const getTasksByProjectId = async (projectId: Number, userTypeId: number, userId: string, param: IProjectParam) => {
  const response = await ProjectAxios.post(`/Projects/GetTasksByProjectId?ProjectId=${projectId}&userTypeId=${userTypeId}&userId=${userId}`, param);
  return response.data;
};

const createTaskAsync = async (payload: any) => {
  const response = await ProjectAxios.post(`/Projects/CreateTaskAsync`, payload, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return response.data
}

const updateTaskAsync = async (payload: any) => {
  try {
    const response = await ProjectAxios.put(`/Projects/UpdateTaskAsync`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating task:', error);
    throw error;
  }
};

const getTaskDetailsAsync = async (taskId: number) => {
  try {
    const response = await ProjectAxios.get(`/Projects/GetTaskByIdAsync`, {
      params: { taskId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching task details:', error);
    throw error;
  }
};

const deleteTask = async (taskId: any) => {
  const response = await ProjectAxios.delete(`/Projects/DeleteTask/${taskId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return response.data;
}

const uploadAttachment = async (task: any, files: Array<File>, entityTypeId: string) => {
  var userId = localStorage.getItem("userId");
  const formData = new FormData();
  formData.append('customerId', task.customerId);
  formData.append('entityTypeId', entityTypeId);
  formData.append('entityId', task.taskId.toString());
  formData.append('createdBy', userId?.toString() ?? "");
  formData.append('projectId', task.projectId);
  for (let i = 0; i < files.length; i++) {
    formData.append(`files`, files[i])
  }
  const response = await UserAxios.post(`attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data;
}
const printCheck = async (printPayload: any) => {
  const response = await ProjectAxios.post(`Report/checkPrintingPDF`, printPayload, {

    responseType: 'blob',

  });
  return response.data
}
const printEnvelope = async (printEnvelopePayload: any) => {
  const response = await ProjectAxios.post(`Report/CheckEnvelop`, printEnvelopePayload, {

    responseType: 'blob',

  });
  return response.data
}

const GetAddressByVendorId = async (assignedToVendorId: number) => {
  const response = await ProjectAxios.get(`Projects/GetAddressByAddressId/${assignedToVendorId}`)
  return response.data;
}
const GetAddressByCustomerId = async (customerId: number) => {
  const response = await ProjectAxios.get(`Projects/GetAddressByCustomerId/${customerId}`)
  return response.data;
}
const GetAdminDetailsByCustomerId = async (customerId: number) => {
  const response = await ProjectAxios.get(`User/GetAdminDetailsByCustomerId/${customerId}`)
  return response.data;
}
const TaskServices = {
  printCheck, printEnvelope, getTasksByProjectId, createTaskAsync,
  updateTaskAsync, getTaskDetailsAsync, deleteTask, uploadAttachment, GetAddressByVendorId,
  GetAddressByCustomerId, GetAdminDetailsByCustomerId
};

export default TaskServices;
