import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./lotDetails.module.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import Icon from "@mdi/react";
import { ICountry, IState } from "../../../interfaces";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { mdiCloudUpload } from "@mdi/js";
import { ILot } from "../../../interfaces/ILot";
import { decryptData } from "../../../utils/CryptoJS";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { billingService, LotService } from "../../../api";
import { EntityType } from "../../../enums";
import { useTranslation } from "react-i18next";
import { validateLot } from "../../../utils/LotValidation";


const LotDetails: React.FC = () => {
  const [states, setStates] = useState<IState[]>([]);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const location = useLocation();
  const projectId = location.state?.projectId;
  const lotId = location.state?.lotId;
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);

  const [error, setError] = useState<string | null>(null);
  const [documentTypeId, setDocumentTypeId] = useState('');
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoaderVisible, setLoaderIsVisible] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  //  const [errors, setErrors] = useState<{ [key in keyof ILot]?: string }>({});
  const [savingData, setSavingData] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    lotNumber: false,
    lotArea: false,
    addressLine1: false,
    city: false,
    state: false,
    postalCode: false,
    county: false,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    lotNumber: false,
    lotArea: false,
    addressLine1: false,
    city: false,
    state: false,
    postalCode: false,
    county: false,
  });
  const [formDetails, setFormDetails] = useState<ILot>({
    lotId: 0,
    customerId: userDetailsObj.customerId,
    projectId: 0,
    lotNumber: "",
    lotArea: 0,
    address: {
      addressId: 0,
      address1: "",
      address2: "",
      city: "",
      stateId: "",
      zipCode: "",
      county: ""
    },
    lotAddressId: 0,
    schoolDistrict: "",
    purchasePrice: 0,
    showInBudget: false,
    purchasedFrom: "",
    closingAttorney: "",
    closingDate: null,
    description: "",
    files: [],
  });

  const userId = localStorage.getItem("userId") as string;
  //Handles Create and Updated data of vendor
  const createLot = async (LotDetails: ILot) => {
    const formData = new FormData();
    formData.append("lotArea", LotDetails.lotArea.toString());
    formData.append("lotNumber", LotDetails.lotNumber);
    formData.append("projectId", projectId);
    formData.append("showInBudget", LotDetails.showInBudget.toString());
    formData.append(
      "closingDate",
      LotDetails.closingDate
        ? LotDetails.closingDate.format("DD-MM-YYYY")
        : ""
    );
    formData.append("schoolDistrict", LotDetails.schoolDistrict);
    formData.append("purchasedFrom", LotDetails.purchasedFrom);
    formData.append("closingAttorney", LotDetails.closingAttorney);
    formData.append("description", LotDetails.description);
    formData.append("purchasePrice", LotDetails.purchasePrice.toString());
    formData.append("createdBy", userId);
    formData.append("modifiedBy", userId);
    formData.append("lotId", LotDetails.lotId.toString());
    formData.append("customerId", userDetailsObj.customerId.toString());
    // Append Address
    formData.append("address.address1", LotDetails.address.address1);
    formData.append("address.address2", LotDetails.address.address2);

    formData.append("address.addressId", LotDetails.address.addressId.toString());
    formData.append("address.isActive", "true");
    formData.append("address.city", LotDetails.address.city);
    formData.append("address.stateId", LotDetails.address.stateId.toString());
    formData.append("address.zipCode", LotDetails.address.zipCode);
    formData.append("address.county", LotDetails.address.county);

    LotDetails.files
      .filter(file => !file.attachmentId) // Filter out the ones that already have an attachmentId
      .forEach((file, index) => {
        // Append only the files that do not have an attachmentId
        formData.append(`DocumentFiles[${index}].Documents`, file.documents); // Assuming documents is an object
        formData.append(`DocumentFiles[${index}].EntityTypeId`, file.entityTypeId.toString());
      });
    try {
      let response;

      if (projectId && LotDetails.lotId > 0) {
        response = await LotService.UpdateLotAsync(formData);
      } else {
        response = await LotService.CreateLotAsync(formData);
      }
      return response;
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
    }
  };
  useEffect(() => {
    if (projectId) {
      fetchLotDetails(projectId);
    }
  }, [projectId]);
 
  useEffect(() => {
      const isValid = validateForm();
      setIsFormValid(isValid);
  }, [formDetails]);

  const handleBlur = (field: string) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  const validateForm = () => {

    const newErrors = {
      lotNumber: formDetails.lotNumber === "",
      lotArea: !formDetails.lotArea,
      addressLine1: formDetails.address.address1 === "",
      city: formDetails.address.city === "",
      state: formDetails.address.stateId === "",
      postalCode: formDetails.address.zipCode === "",
      county: formDetails.address.county === "",
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  // Handle the form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (validateForm()) {
      e.preventDefault(); // Prevent default form submission
      setSavingData(true);

      const userDetailsObj: any = localStorage.getItem("userDetails");
      const userDetails = decryptData(userDetailsObj);
      const retrievedUserId = userDetails.userId;

      const formDetailsWithUserId = {
        ...formDetails,
        userId: retrievedUserId,
        projectId: formDetails.projectId, // Ensure projectId from formDetails
      };
      const errors = validateLot(formDetailsWithUserId);
      setValidationErrors(errors);
      // Check for validation errors
      if (Object.keys(errors).length === 0) {
        try {
          const response = await createLot(formDetailsWithUserId); // Call createLot

          if (response.isSuccess) {
            // Handle success response
            setSnackbarMessage(formDetailsWithUserId.lotId > 0 ? "Lot updated successfully." : "Lot saved successfully.");
            setLoaderIsVisible(false);
            setOpenSnackbar(true);

            // Navigate or fetch details based on success
            setTimeout(() => {
              navigate("/project-detail", { state: { projectId: projectId } });
            }, 1000);
            fetchLotDetails(projectId);
          }
        } catch (error: any) {

          // Handle specific error messages
          if (error.response?.data.errors[0] === "Lot Already Exists") {
            setValidationErrors({
              ...validationErrors,
              lotNumber: "Lot Number already exists", // Adjust based on your validation structure
            });
          } else {
            setError(error.response.data.errors.join(", "));
          }
        }
      }
      setSavingData(false); // Reset saving state
    }
  };

  const fetchLotDetails = async (projectId: String) => {
    try {
      const data = await LotService.getLotById(projectId);
      setTimeout(() => {

        // Map the data to your form structure
        const mappedData: ILot = {

          lotId: data.lotId || "",
          customerId: data.customerId || "",
          projectId: data.projectId || "",
          lotNumber: data.lotNumber || "",
          lotArea: data.lotArea || "",
          schoolDistrict: data.schoolDistrict || "",
          purchasePrice: data.purchasePrice || "",
          showInBudget: data.showInBudget || false,
          purchasedFrom: data.purchasedFrom || "",
          closingAttorney: data.closingAttorney || "",
          closingDate: data.closingDate ? dayjs(data.closingDate) : null, // Format date if needed
          description: data.description || "",
          lotAddressId: data.address.AddressId,
          address: {
            addressId: data.address?.addressId || 0, // Assuming addressId is a number
            address1: data.address?.address1 || "",
            address2: data.address?.address2 || "",
            city: data.address?.city || "",
            stateId: data.address?.stateId || "",
            zipCode: data.address?.zipCode || "",
            county: data.address?.county || ""
          },
          files: [],
        };
        // Assuming you have a state to store the form details
        setFormDetails(mappedData);
      }, 300);
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
    }
  };

  // Handle checkbox change
  const handleCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      showInBudget: isChecked,
    }));
  };
  // Handle change of document type dropdown
  const handleDocumentTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value; // Get the selected value
    setDocumentTypeId(selectedValue); // Set it to state
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormDetails({ ...formDetails, [name]: value });
  };
  useEffect(() => {
    const getCountryStateData = async () => {
      try {
        const data = await billingService.fetchCountryStateData();
        if (data) {
          setCountries(data.country);
          setStates(data.state);
        }
      } catch (error: any) {
        setErrors(error.response.data.errors.join(", "));
      }
    };
    getCountryStateData();
  }, []);


  const handleRemoveFileClick = (file: any) => {

    removeFile(file.attachmentId, file.documents.name, file.entityTypeId);
  };

  // To remove Files from the uploaded file list
  const removeFile = async (attachmentId: number | undefined, name: string, entityTypeId: number) => {

    if (attachmentId === undefined) {
      setFormDetails((prevDetails) => {
        const updatedDetails = { ...prevDetails };
        updatedDetails.files = prevDetails.files.filter((file) => {
          return !(file.documents.name === name && file.entityTypeId === entityTypeId);
        });
        return updatedDetails;
      });

    }
  };

  const handleFileChangeForDocumnetType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = ['.pdf', '.jpg', '.dwf', '.dwg', '.tif', '.jpeg', '.png'];
    // Check if document type is selected
    if (!documentTypeId) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, files: 'Please select a document type before uploading files.' }));
      return; // Early return if no document type is selected
    }

    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      const validFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, files: '' }));

        validFiles.forEach((file) => {
          formDetails.files.push({ documents: file, entityTypeId: parseInt(documentTypeId) });
        });

      }
      e.target.value = '';
    }

  };
  //handle when cancel button is clicked.
  const handleCancel = () => {
    navigate("/projects", { replace: true });
  };

  return (
    <div className={styles.tab_grid_container}>
      <Grid
        container
        className={styles.heading_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          container
          item
          xs={12}
          className={styles.chip_container}
          alignItems="center"
          justifyContent="flex-start"
        >
          {/* <h3 id="lot_title">{t("LotDetails.lot_title")}</h3> */}
        </Grid>
      </Grid>
      <Box component="form" noValidate sx={{ mt: 2 }} className={styles.form_scrollable}>

        {/* Lot Details Form */}
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid container item sm={5.5} spacing={2}>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="lotNumber"
                name="lotNumber"
                value={formDetails.lotNumber || ""}
                placeholder={t("LotDetails.lotNumber")}
                label={t("LotDetails.lotNumber")}
                variant="outlined"
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('lotNumber')}
                error={touchedFields.lotNumber && errors.lotNumber}
                helperText={touchedFields.lotNumber && errors.lotNumber ? "Lot Number is required" : ""}
              />
            </Grid>
            <Grid item xs={12} container className="fieldWrapper">
              <TextField
                id="lotArea"
                name="lotArea"
                value={formDetails.lotArea || ""}
                placeholder={t("LotDetails.lotArea")}
                label={t("LotDetails.lotArea")}
                variant="outlined"
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">acres</InputAdornment>
                  ),
                }}
                onBlur={() => handleBlur('lotArea')}
                error={touchedFields.lotArea && errors.lotArea}
                helperText={touchedFields.lotArea && errors.lotArea ? "Lot Area is required" : ""}
              />
            </Grid>

            <Grid item xs={12} container className="fieldWrapper">
              <TextField
                id="address1"
                name="address1"
                value={formDetails.address.address1 || ""}
                placeholder={t("LotDetails.address_placeholder")}
                label={t("LotDetails.address1")}
                variant="outlined"
                className="mb20"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    address: {
                      ...formDetails.address, // Spread the existing address object
                      address1: e.target.value // Update address1
                    }
                  })
                }
                onBlur={() => handleBlur('addressLine1')}
                error={touchedFields.addressLine1 && errors.addressLine1}
                helperText={touchedFields.addressLine1 && errors.addressLine1 ? "Address1 is required" : ""}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                id="address2"
                name="address2"
                value={formDetails.address.address2 || ""}
                placeholder={t("LotDetails.address_placeholder")}
                label={t("LotDetails.address2")}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    address: {
                      ...formDetails.address, // Spread the existing address object
                      address2: e.target.value // Update address1
                    }
                  })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              className="fieldWrapper flex_columns_in_mobile"
              alignItems="start"
            >
              <TextField
                id="city"
                name="city"
                value={formDetails.address.city || ""}
                placeholder={t("LotDetails.city")}
                label={t("LotDetails.city")}
                variant="outlined"
                className="inlineSelect oneThird"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    address: {
                      ...formDetails.address, // Spread the existing address object
                      city: e.target.value // Update address1
                    }
                  })
                }
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('city')}
                error={touchedFields.city && errors.city}
                helperText={touchedFields.city && errors.city ? "City is required" : ""}
              />
              <FormControl className="inlineSelect oneThird">
                <Autocomplete
                  size="small"
                  id="state-autocomplete"
                  options={states.filter(
                    (state) => state.countryId === Number(233)
                  )} // Filter states by countryId
                  value={
                    states.find(
                      (state) => state.stateId === formDetails.address.stateId
                    ) || null
                  } // Set value based on stateId
                  onChange={(event, newValue) => {
                    setFormDetails((prevDetails) => ({
                      ...prevDetails,
                      address: {
                        ...prevDetails.address, // Keep the existing address object
                        stateId: newValue ? newValue.stateId : "", // Update stateId based on selected state
                      }
                    }));
                  }}
                  getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("LotDetails.state")}
                      variant="outlined"
                      placeholder={t("LotDetails.state")}
                      onBlur={() => handleBlur('state')}
                      error={touchedFields.state && errors.state}
                      helperText={touchedFields.state && errors.state ? "State is required" : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              </FormControl>
              <TextField
                id="zipCode"
                value={formDetails.address.zipCode || ""}
                type="text"
                placeholder={t("LotDetails.zipCode")}
                label={t("LotDetails.zipCode")}
                name="zipcode"
                className="oneThird"
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    address: {
                      ...formDetails.address, // Spread the existing address object
                      zipCode: e.target.value // Update address1
                    }
                  })
                }
                InputLabelProps={{ shrink: true }}
                onBlur={() => handleBlur('postalCode')}
                error={touchedFields.postalCode && errors.postalCode}
                helperText={touchedFields.postalCode && errors.postalCode ? "Zip code is required" : ""}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="county"
                value={formDetails.address.county || ""}
                type="text"
                placeholder={t("LotDetails.county")}
                label={t("LotDetails.county")}
                name="county"
                className=""
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({
                    ...formDetails,
                    address: {
                      ...formDetails.address,
                      county: e.target.value
                    }
                  })
                }
                onBlur={() => handleBlur('county')}
                error={touchedFields.county && errors.county}
                helperText={touchedFields.county && errors.county ? "County is required" : ""}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="schoolDistrict"
                name="schoolDistrict"
                placeholder={t("LotDetails.schoolDistrict")}
                label={t("LotDetails.schoolDistrict_placeholder")}
                value={formDetails.schoolDistrict || ""}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, schoolDistrict: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6} className="fieldWrapper">
                <TextField
                  id="purchasePrice"
                  name="purchasePrice"
                  placeholder={t("LotDetails.purchasePrice_placeholder")}
                  label={t("LotDetails.purchasePrice")}
                  value={formDetails.purchasePrice || ""}
                  variant="outlined"
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }} className="fieldWrapper" >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formDetails.showInBudget}
                      onChange={handleCheckBox}
                      color="primary"
                    />
                  }
                  label={t("LotDetails.show_budget")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="purchasedFrom"
                name="purchasedFrom"
                placeholder={t("LotDetails.purchasedFrom_placeholder")}
                label={t("LotDetails.purchasedFrom")}
                value={formDetails.purchasedFrom || ""}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, purchasedFrom: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>





          </Grid>

          <Grid item container sm={5.5} spacing={2}>


            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="closingAttorney"
                name="closingAttorney"
                placeholder={t("LotDetails.closingAttorney")}
                label={t("LotDetails.closingAttorney")}
                value={formDetails.closingAttorney || ""}
                variant="outlined"
                onChange={(e) =>
                  setFormDetails({ ...formDetails, closingAttorney: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sm={6} container className="fieldWrapper">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("LotDetails.closing_date")}
                  value={formDetails.closingDate}
                  slotProps={{
                    textField: { InputLabelProps: { shrink: true } },
                  }}
                  onChange={(date: Dayjs | null) =>
                    setFormDetails((prevState) => ({
                      ...prevState,
                      closingDate: date,
                    }))
                  }
                />
              </LocalizationProvider>
            </Grid>
            {/* document type sector input */}
            <Grid item xs={12} className="fieldWrapper">
              <FormControl
                className="inlineSelect"
              >
                <InputLabel id="doc_type_placeholder" shrink={true}>
                  {t("LotDetails.documentType")}
                </InputLabel>
                <Select
                  labelId="doc_type_placeholder"
                  id="documentType"
                  placeholder="Type"
                  className="selectBox"
                  name="documentTypeId"
                  onChange={handleDocumentTypeChange}
                  value={documentTypeId}
                  input={<OutlinedInput notched label={t("LotDetails.documentType")} />}
                >
                  <MenuItem value={EntityType.warrantyDeed}>Warranty Deed</MenuItem>
                  <MenuItem value={EntityType.closingDocuments}>Closing Documents</MenuItem>
                  <MenuItem value={EntityType.paymentConfirmation}>Payment Confirmation</MenuItem>
                  <MenuItem value={EntityType.saleDeed}>Sale Deed</MenuItem>
                  <MenuItem value={EntityType.lot}>Others</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12} className="fieldWrapper">
              <FormControl error={!!validationErrors.files}>
                <Button
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  aria-label="Upload Files"
                  aria-placeholder="Click here to upload"
                  endIcon={
                    <Icon
                      className="uploadButtonIcon"
                      path={mdiCloudUpload}
                      size={1}
                    />
                  }
                  className={`uploadButton ${validationErrors.fileType ? "error" : ""
                    }`}
                >
                  {t("LotDetails.upload_btn_text")}
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                    className="d_none"
                    onChange={handleFileChangeForDocumnetType}
                  />
                </Button>

              </FormControl>
            </Grid>

            <Grid item xs={12} container className="fileListContainer fileListMax">
              <div className="fileListMax">

                {(documentTypeId == String(EntityType.warrantyDeed) ||
                  (formDetails.files && formDetails.files.length > 0 &&
                    formDetails.files.some(file => file.entityTypeId == 14))) && (
                    <>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label="Warranty Deed" size="small" />
                      </Divider>
                      <div>
                        {formDetails.files
                          ?.filter((file) => file.entityTypeId === EntityType.warrantyDeed)
                          .map((file, index) => (
                            <div key={index} className="fileItem">
                              <span>{file.documents.name}</span>
                              <svg
                                className="cancelIcon"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent drawer from opening
                                  handleRemoveFileClick(file);
                                }}
                              >
                                <HighlightOffIcon />
                              </svg>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                {(documentTypeId == String(EntityType.closingDocuments)
                  || (formDetails.files && formDetails.files.length > 0 &&
                    formDetails.files.some(file => file.entityTypeId == 15))) && (
                    <>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label="Closing Documents" size="small" />
                      </Divider>
                      <div>
                        {formDetails.files
                          ?.filter((file) => file.entityTypeId === EntityType.closingDocuments).map((file, index) => (
                            <div key={index} className="fileItem">
                              <span>{file.documents.name}</span>
                              <svg
                                className="cancelIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFileClick(file)
                                }}
                              >
                                <HighlightOffIcon />
                              </svg>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                {(documentTypeId == String(EntityType.paymentConfirmation) ||
                  (formDetails.files && formDetails.files.length > 0 &&
                    formDetails.files.some(file => file.entityTypeId == 16))) && (
                    <>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label="Payment Confirmation" size="small" />
                      </Divider>
                      <div>
                        {formDetails.files
                          ?.filter((file) => file.entityTypeId === EntityType.paymentConfirmation).map((file, index) => (
                            <div key={index} className="fileItem">
                              <span>{file.documents.name}</span>
                              <svg
                                className="cancelIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFileClick(file)
                                }}
                              >
                                <HighlightOffIcon />
                              </svg>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                {(documentTypeId == String(EntityType.saleDeed) ||
                  (formDetails.files && formDetails.files.length > 0 &&
                    formDetails.files.some(file => file.entityTypeId == 17))) && (
                    <>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label="Sale Deed" size="small" />
                      </Divider>
                      <div>
                        {formDetails.files
                          ?.filter((file) => file.entityTypeId === EntityType.saleDeed)
                          .map((file, index) => (
                            <div key={index} className="fileItem">
                              <span>{file.documents.name}</span>
                              <svg
                                className="cancelIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFileClick(file);
                                }}
                              >
                                <HighlightOffIcon />
                              </svg>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                {(documentTypeId == String(EntityType.lot) ||
                  (formDetails.files && formDetails.files.length > 0 &&
                    formDetails.files.some(file => file.entityTypeId == 18))) && (
                    <>
                      <Divider sx={{ width: "100%" }}>
                        <Chip label="Others" size="small" />
                      </Divider>
                      <div>
                        {formDetails.files
                          ?.filter((file) => file.entityTypeId === EntityType.lot).map((file, index) => (
                            <div key={index} className="fileItem">
                              <span>{file.documents.name}</span>
                              <svg
                                className="cancelIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFileClick(file)
                                }}
                              >
                                <HighlightOffIcon />
                              </svg>
                            </div>
                          ))}

                      </div>
                    </>
                  )}
              </div>

            </Grid>

            <Grid item xs={12} className="fieldWrapper">
              <TextField
                id="Description"
                name="Description"
                placeholder={t("LotDetails.Description")}
                label={t("LotDetails.Description")}
                value={formDetails.description || ""}
                variant="outlined"
                multiline
                rows={4}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, description: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box component="form" noValidate sx={{ mt: 1 }}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          item
          sm={12}
          sx={{ justifyContent: "flex-end", gap: 0 }}
          className="submitButtons"
        >
          <Grid
            item
            sm={8}
            className="submitButtons"
            sx={{ mt: "0 !important" }}
          >
            <Button
              type="button"
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
              onClick={handleCancel}
            >
              {t("LotDetails.cancel_btn")}
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="primary-button widthauto"
              name="Save"
              disabled={!isFormValid}
            >
              {t("LotDetails.save_btn")}
            </Button>

          </Grid>
        </Grid>

      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default LotDetails;
