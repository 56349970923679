import React from "react";
import styles from "./creditCards.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from '@mdi/react';
import { mdiContentCopy, mdiPencil, mdiTrashCanOutline } from '@mdi/js';
import { GridColDef } from "@mui/x-data-grid";
import { Box, Button, Grid, IconButton, MenuItem, Select, SelectChangeEvent, TextField, styled } from '@mui/material';

const CreditCards: React.FC = () => {
  const columns: GridColDef<(typeof rows)[number]>[] = [
    { 
      field: 'CreditCardType', 
      headerName: 'Credit Card Type',
      width: 120, 
      flex: 1,
      disableColumnMenu:true,
     },
    {
      field: 'IssuedTo',
      headerName: 'Issued To',
      width: 200,
      editable: false,
      disableColumnMenu:true,
      flex: 1.5,
    },
    {
      field: 'Last4Digits',
      headerName: 'Last 4-digits',
      width: 150,
      editable: false,
      disableColumnMenu:true,
      flex: 1.5,
    },
    {
      field: 'Expiry',
      headerName: 'Expiry',
      width: 130,
      editable: false,
      disableColumnMenu:true,
      flex: 1.5,
    },
    
    {
      field: 'editProject',
      headerName: '',
      width: 50,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          aria-label="edit"
          onClick={() => handleEdit(params.row.id)} // handleEdit function to be implemented
        >
          <Icon path={mdiPencil} aria-label="edit"  size={1} />
        </IconButton>
      )
    },
    {
      field: 'deleteProject',
      headerName: '',
      width: 50,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          aria-label="edit"
          onClick={() => handleEdit(params.row.id)} // handleEdit function to be implemented
        >
          <Icon path={mdiTrashCanOutline} aria-label="edit"  size={1} />
        </IconButton>
      )
    },
    
  ];

  const handleEdit = (id: number) => {
    // Implement your edit logic here
    console.log('Editing row with ID:', id);
  };


  let rows = [
    { id: 1, CreditCardType: 'Bank of America Visa', IssuedTo: 'Shirish Vadali', Last4Digits: '1234', Expiry: '12/2027 ' },
    { id: 2, CreditCardType: 'Bank of America Visa', IssuedTo: 'Shirish Vadali', Last4Digits: '1234', Expiry: '12/2027 ' },
    { id: 3, CreditCardType: 'Bank of America Visa', IssuedTo: 'Shirish Vadali', Last4Digits: '1234', Expiry: '12/2027 ' },
  ];
  // let rows : any = []

  return <div className={styles.Gridcontainer}>
    <Datatable rows={rows} columns={columns} />
  </div>;
};

export default CreditCards;
