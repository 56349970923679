import React, { useEffect, useState } from "react";
import styles from "./vendors.module.scss";
import Datatable from "../../components/datatable/datatable";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { useLocation, useNavigate } from "react-router-dom";
import vendorServices from "../../api/services/VendorService";
import { IVendorParam } from "../../interfaces";
import { decryptData } from "../../utils/CryptoJS";
import dayjs from "dayjs";
import { userService } from "../../api";
import { useTranslation } from "react-i18next";

const Vendors: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [venderList, setVenderList] = useState<any[]>([]);
  const pageCount = Math.ceil(count / rowsPerPage);
  const navigate = useNavigate();
  const { t } = useTranslation();
  var customerId = Number(localStorage.getItem("customerId"));

  //fetch the vendor list
  const fetchData = async () => {
    try {
      const params: any = {
        pageNumber: page + 1,
        pageSize: rowsPerPage,
        sortBy: "0",
        sortDescending: true,
        customerIds: "",
      };

      const userDetails = JSON.parse(
        localStorage.getItem("logedInUserDetails") || "{}"
      );
      let customerIds: any[] = [];

      userDetails?.userAccessDto?.forEach((data: any) => {
        if (data.userTypeId === 1 || data.userTypeId === 2) {
          customerIds.push(data.customerId);
        }
      });

      params.customerIds = customerIds.join(",");
      if (userDetails?.isSubscriptionAdmin && params.customerIds === "") {
        customerIds.push(customerId);
        params.customerIds = customerIds.join(",");
      }
      const vendorListResponse = await vendorServices.getVendor(
        customerId,
        params
      );
      setTimeout(() => {
        vendorListResponse.vendorDetails.coiStartDate = vendorListResponse
          .vendorDetails.coiStartDate
          ? dayjs(vendorListResponse.vendorDetails.coiStartDate)
          : null;
        vendorListResponse.vendorDetails.coiExpiryDate = vendorListResponse
          .vendorDetails.coiExpiryDate
          ? dayjs(vendorListResponse.vendorDetails.coiExpiryDate)
          : null;
        setVenderList(vendorListResponse.vendorDetails);
      }, 300);

      setCount(vendorListResponse.totalCount);
      setLoading(false);
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
      fetchData();
    }
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };
  const columns: GridColDef[] = [
    {
      field: "companyName",
      headerName: `${t("Vendors.vendor_company_name")}`,
      width: 120,
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (vendor) => {
        return (
          <div
            onClick={() => handleEdit(vendor.id)}
            className={styles.projectLink + ' cursor_pointer'}
          >
            <span>{vendor.value}</span>
          </div>
        );
      },
    },
    {
      field: "addressDetails",
      headerName: `${t("Vendors.address")}`,
      width: 120,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "companyURL",
      headerName: `${t("Vendors.url")}`,
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "vendorType",
      headerName: `${t("Vendors.type")}`,
      width: 150,
      minWidth: 250,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.vendorType.map((data: any, index: any) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    size="small"
                    color="primary"
                    checked={true}
                    disabled
                  />
                }
                label={data.vendorType}
                sx={{
                  display: "block",
                  color: "#222",
                  "& .Mui-checked.Mui-disabled": {
                    color: "var(--primary) !important",
                    padding: "6px",
                  },
                  "& .MuiFormControlLabel-label.Mui-disabled": {
                    color: "#222",
                    fontSize: "14px",
                    "@media screen and (max-width:768px)": {
                      fontSize: "12px !important",
                    },
                  },
                  "& svg": {
                    width: "0.875em !important",
                    height: "0.875em !important",
                  },
                }}
              />
            ))}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleEdit = (vendorId: any) => {
    navigate(`/edit-vendor`, { state: { vendorId } });
  };

  const rows = venderList.map((vendor: any) => ({
    id: vendor.vendorId,
    addressDetails: vendor.addressDetails,
    companyURL: vendor.companyURL,
    companyName: vendor.companyName,
    vendorType: vendor.type,
  }));

  return (
    <div className={styles.Gridcontainer}>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        onPageChange={handlePageChange}
        rowCount={count}
        paginationMode="server"
        loading={loading}
        error={error}
        pageSize={rowsPerPage}
        getRowHeight="auto"
        className="ProjectPage"
      />
    </div>
  );
};

export default Vendors;
