
import * as React from 'react';
import styles from "./datatable.module.scss";
import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { CustomPaginationProps } from '../../interfaces';

// Custom pagination component
const CustomPagination: React.FC<CustomPaginationProps> = ({ page, pageCount, handleFirstPage, handleLastPage, handleNextPage, handlePreviousPage, hidePagination }) => {

  const isFirstPage = page === 0;
  const isLastPage = page + 1 === pageCount;

  return (
    <>
      {hidePagination && <div className={styles.pagination_container}>
        <ul>
          <li onClick={!isFirstPage ? handleFirstPage : undefined}
            className={isFirstPage ? styles.disabled : ''}
          > <Icon path={mdiChevronDoubleLeft} size={1} /></li>

          <li
            onClick={!isFirstPage ? handlePreviousPage : undefined}
            className={isFirstPage ? styles.disabled : ''}
          >
            <Icon path={mdiChevronLeft} size={1} /></li>
          <li> Page <span className='activePage'> {page + 1}</span> of <span className='totalPage'> {pageCount} </span> </li>
          <li
            onClick={!isLastPage ? handleNextPage : undefined}
            className={isLastPage ? styles.disabled : ''}
          >
            <Icon path={mdiChevronRight} size={1} /></li>
          <li
            onClick={!isLastPage ? handleLastPage : undefined}
            className={isLastPage ? styles.disabled : ''}
          >
            <Icon path={mdiChevronDoubleRight} size={1} /></li>
        </ul>
      </div>}</>
  );
};
export default CustomPagination;

