import { IForgotPassword, ISignin } from "../interfaces";
import { IBillingInformation, IBillingInformationErrors } from "../interfaces";
import IUserDetails from "../interfaces/IUserDetails";

export const validateSignupForm = (formDetails: IUserDetails,t: (key: string) => string) => {
  const errors: Partial<IUserDetails> = {};

  if (!formDetails.firstName.trim()) {
    errors.firstName = t("validateSignupForm.firstname_empty"); //"Please enter your firstname.";
  } else if (/\d/.test(formDetails.firstName)) {
    errors.firstName = t("validateSignupForm.nameNoIntegers"); //"Name should not contain any integers.";
  } else if (!/^[a-zA-Z\s]+$/.test(formDetails.firstName)) {
    errors.firstName =  t("validateSignupForm.nameOnlyLettersSpaces");//"Name should only contain letters and spaces.";
  }
  if (!formDetails.lastName.trim()) {
    errors.lastName =  t("validateSignupForm.lastname"); //"Please enter your lastname.";
  } else if (/\d/.test(formDetails.lastName)) {
    errors.lastName =  t("validateSignupForm.nameNoIntegers"); //"Name should not contain any integers.";
  } else if (!/^[a-zA-Z\s]+$/.test(formDetails.lastName)) {
    errors.lastName =  t("validateSignupForm.nameOnlyLettersSpaces"); //"Name should only contain letters and spaces.";
  }

  if (!formDetails.email) {
    errors.email =  t("validateSignupForm.emailRequired");//"Please enter a email address.";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formDetails.email)
  ) {
    errors.email =  t("validateSignupForm.emailInvalid");//"Please enter a valid email address.";
  }

  if (!formDetails.companyName) {
    errors.companyName =  t("validateSignupForm.companyNameRequired"); //"Please enter your company name.";
  }

  if (!formDetails.address1) {
    errors.address1 =  t("validateSignupForm.addressRequired");//"Please enter address.";
  }
  if (!formDetails.contactNo) {
    errors.contactNo =  t("validateSignupForm.contactNoRequired");//"Please enter contact number.";
  }
  if (!formDetails.city) {
    errors.city =  t("validateSignupForm.cityRequired");//"Please enter city.";
  }
  if (!formDetails.stateId) {
    errors.stateId =  t("validateSignupForm.stateRequired");//"Please select State.";
  }
  if (!formDetails.zipCode) {
    errors.zipCode =  t("validateSignupForm.zipCodeRequired");//"Please enter zipcode.";
  }
  if (!formDetails.password) {
    errors.password =  t("validateSignupForm.passwordRequired");//"Please enter a password.";
  } else if (
    !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|\\:;"'<>,.?/~`])[A-Za-z\d!@#$%^&*()\-_+=\[\]{}|\\:;"'<>,.?/~`]{8,}/.test(
      formDetails.password
    )
  ) {
    errors.password = t("validateSignupForm.passwordRequirements"); //"Your password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.";
  }

  if (!formDetails.confirmPassword) {
    errors.confirmPassword =  t("validateSignupForm.confirmPasswordRequired");//"Please confirm your password.";
  } else if (formDetails.password !== formDetails.confirmPassword) {
    errors.confirmPassword =  t("validateSignupForm.passwordMismatch");//"Passwords do not match. Please try again.";
  }
  return errors;
};

export const validateBillingInformation = (
  formDetails: IBillingInformation, t: (key: string) => string
) => {
  const errors: IBillingInformationErrors = {
    city: "",
  };

  if (!formDetails.cardNumber.trim()) {
    errors.cardNumber = t("validateBillingInformation.cardNumberRequired");//"Card number is required.";
  } else if (!/\d/.test(formDetails.cardNumber)) {
    errors.cardNumber = t("validateBillingInformation.cardNumberOnlyIntegers");//"CardName should contain only integers.";
  } else if (/^[a-zA-Z\s]+$/.test(formDetails.cardNumber)) {
    errors.cardNumber = t("validateBillingInformation.cardNumberInvalid");//"Please enter a valid Card Number.";
  } else if (!/^\d{16}$/.test(formDetails.cardNumber)) {
    errors.cardNumber =
t("validateBillingInformation.cardNumber16Digits");//      "Please enter a valid card number. It must be 16 digits.";
  }
  if (!formDetails.expiryDate) {
    errors.expiryDate = t("validateBillingInformation.expiryDateRequired");//"Expiry date is required.";
  } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formDetails.expiryDate)) {
    errors.expiryDate = t("validateBillingInformation.expiryDateFormat");//"Please enter a valid expiry date (MM/YY).";
  } else {
    const [month, year] = formDetails.expiryDate.split("/").map(Number);
    const expiryDate = new Date(`20${year}-${month}-01`);
    const currentDate = new Date();
    currentDate.setDate(1);
    if (expiryDate < currentDate) {
      errors.expiryDate = t("validateBillingInformation.expiryDatePast");//"Expiry date cannot be in the past.";
    } else {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 10);

      if (expiryDate > maxDate) {
        errors.expiryDate = t("validateBillingInformation.expiryDateFuture"); // "Expiry date cannot be more than 10 years into the future.";
      }
    }
  }

  // Validate Security Code
  if (!formDetails.securityCode) {
    errors.securityCode = t("validateBillingInformation.securityCodeRequired"); //"Security Code is required.";
  } else if (!/^\d{3}$/.test(formDetails.securityCode)) {
    errors.securityCode = t("validateBillingInformation.securityCodeInvalid"); //"Please enter a valid Security Code.";
  }

  // Validate Subscription Plan
  if (!formDetails.planTerm) {
    errors.planTerm = t("validateBillingInformation.planTermRequired"); //"Please select a subscription plan.";
  }
  //validate users
  if (formDetails.users < 1) {
    errors.users = t("validateBillingInformation.minUsers"); //"The minimum number of users is 1.";
  } else if (formDetails.users > 99) {
    errors.users = t("validateBillingInformation.maxUsers"); //"The maximum number of users is 99.";
  }

  // Validate Billing Address
  if (!formDetails.address1) {
    errors.address1 = t("validateBillingInformation.address1Required"); //"Address 1 is required.";
  }

  // Validate State
  if (!formDetails.stateId) {
    errors.stateId = t("validateBillingInformation.stateRequired"); //"State is required.";
  }
  if (!formDetails.city) {
    errors.city = t("validateBillingInformation.cityRequired"); //"City is required";
  }

  // Validate Zip Code
  if (!formDetails.zipCode) {
    errors.zipCode = t("validateBillingInformation.zipCodeRequired"); //"Zip code is required.";
  } else if (/^[A-Za-z\s]+$/.test(formDetails.zipCode)) {
    errors.zipCode = t("validateBillingInformation.zipCodeInvalid"); //"Please enter a valid zip code.";
  }

  return errors;
};

export const validateResetPasswordForm = (formDetails: IForgotPassword, t: (key: string) => string
) => {
  const errors: IForgotPassword = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };

  if (!formDetails.newPassword) {
    errors.newPassword =t("validateResetPasswordForm.passwordRequired"); //"Please enter a password.";
  } else if (
    !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=\[\]{}|\\:;"'<>,.?/~`])[A-Za-z\d!@#$%^&*()\-_+=\[\]{}|\\:;"'<>,.?/~`]{8,}/.test(
      formDetails.newPassword
    )
  ) {
    errors.newPassword = t("validateResetPasswordForm.passwordRequirements"); 
      //"Your password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.";
  }

  if (!formDetails.confirmPassword) {
    errors.confirmPassword = t("validateResetPasswordForm.confirmPasswordRequired"); //"Please confirm your password.";
  } else if (formDetails.newPassword !== formDetails.confirmPassword) {
    errors.confirmPassword = t("validateResetPasswordForm.passwordMismatch"); //"Passwords do not match. Please try again.";
  }
  return errors;
};

export const validateSigninForm = (formDetails: ISignin,t: (key: string) => string
) => {
  const errors: Partial<ISignin> = {};
  if (!formDetails.email && formDetails.password) {
    errors.email = t("validateSigninForm.emailRequired");//"Email is required";
  } else if (!formDetails.password && formDetails.email) {
    errors.password = t("validateSigninForm.passwordRequired");//"Password is required";
  }
  return errors;
};
