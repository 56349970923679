import styles from "./routes.module.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import {
  Login,
  ForgetPassword,
  Signup,
  Projects,
  AddProject,
  LandingPage,
  Proposals,
  AddProposal,
  Estimates,
  AddSchedule,
  BidRequests,
  AddBidRequests,
  ContactDetails,
  CreditCards,
  AddCreditCards,
  ScheduleValues,
  PurchaseOrder,
  AddPurchaseOrder,
  ClientInvoices,
  AddClientInvoices,
  Timesheets,
  AddTimesheet,
  Tasks,
  AmountSpent,
  Payments,
  ProjectDetails,
  AddPayments,
  AddProjectTask,
  RolesPermissions,
  CompanySettings,
  Dashboard,
  ResetPassword,
  CostCode,
  UpdateProject,
  PricingPage,
  Schedule,
  ProjectDetail,
  Vendors,
  AddVendor,
  LotDetails
} from "../containers";
import { Header, NavigationBar, BreadcrumbsComponent } from "../components";
import { IRouteConfig } from "../interfaces";
import {
  mdiTextBoxEditOutline,
  mdiBriefcaseEye,
  mdiArrowLeftCircle,
  mdiCalculator,
  mdiCalendarMonth,
  mdiGavel,
  mdiCart,
  mdiCurrencyUsd,
  mdiClipboardTextClock,
  mdiClipboardList,
  mdiChartPie,
  mdiAccountCreditCard,
  mdiCreditCard,
  mdiBarcodeScan,
  mdiAccountMultiple,
  mdiViewDashboard,
  mdiClipboardListOutline,
  mdiAccountCogOutline,
  mdiAccountCog,
} from "@mdi/js";
import { AddTask } from "@mui/icons-material";
import ProtectedRoute from "./protectedRoute";
import IdleTimer from "../utils/IdleTimer";
import { ModalProvider } from "../utils/ModalContext";
import CustomModal from "../components/modal/Modal";
import { useDrawer } from "../utils/DrawerContext";
import React, { useEffect } from "react";
import { useBannerContext } from "../utils/BannerContext";
import { userService } from "../api";
import { useUserContext } from "../utils/UserContext";
import { useTranslation } from "react-i18next";

const AppRouter = () => {
  const [hideMenu, setHideMenu] = React.useState(false);
  const { t } = useTranslation();
  var userDetails: any = localStorage.getItem("logedInUserDetails");
  var userDetailsObj;
  const { email } = useUserContext();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (email && token) {
      userService.getUserDetails(email).then((response) => {
        userDetailsObj = response;
        if (userDetailsObj) localStorage.setItem("logedInUserDetails", JSON.stringify(userDetailsObj));
        var isSubscriptionAdmin = false;
        var isShowContactsAndAccountManagment = false;
        isSubscriptionAdmin = userDetailsObj.isSubscriptionAdmin;

        for (var i = 0; i < userDetailsObj.userAccessDto?.length; i++) {
          localStorage.setItem("userRoleId", userDetailsObj.userAccessDto[i].userTypeId);
          if (
            userDetailsObj.userAccessDto[i].userTypeId == "1" ||
            userDetailsObj.userAccessDto[i].userTypeId == "2"
          ) {
            isShowContactsAndAccountManagment = true;
            break;
          }
        }
        setHideMenu(isSubscriptionAdmin || isShowContactsAndAccountManagment);
      });
    }
  }, [email, userDetails, token]);

  const routes: IRouteConfig[] = [
    {
      path: "/",
      component: LandingPage,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: false,
    },
    {
      path: "/login",
      component: Login,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: false,
    },
    {
      path: "/signup",
      component: Signup,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: false,
    },
    {
      path: "/forgot-password",
      component: ForgetPassword,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: false,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: false,
    },
    {
      path: "/pricing",
      component: PricingPage,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: "",
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/dashboard",
      component: Dashboard,
      title: "",
      menuName: "Dashboard",
      buttonName: "",
      buttonLink: "",
      icon: mdiViewDashboard,
      iconSize: 1,
      isShow: true,
      protected: true,
    },
    {
      path: "/projects",
      component: Projects,
      title: "",
      menuName: "Projects",
      buttonName: "",
      buttonLink: "",
      icon: mdiBriefcaseEye,
      iconSize: 1,
      isShow: true,
      protected: true,
    },
    {
      path: "/add-project",
      component: AddProject,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiBriefcaseEye,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/project-detail",
      component: ProjectDetail,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiBriefcaseEye,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/vendors",
      component: Vendors,
      title: `${t("Vendors.vendor_title")}`,
      menuName: "Vendor/Contacts",
      buttonName: `${t("Vendors.add_vendor_btn")}`,
      buttonLink: "/add-vendor",
      icon: mdiAccountMultiple,
      iconSize: 1,
      isShow: hideMenu,
      protected: true,
    },
    {
      path: "/add-vendor",
      component: AddVendor,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/edit-vendor",
      component: AddVendor,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/cost-code",
      component: CostCode,
      title: "",
      menuName: "Cost Code",
      buttonName: "",
      buttonLink: "",
      icon: mdiBarcodeScan,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/credit-cards",
      component: CreditCards,
      title: "Payment Methods",
      menuName: "Payment Methods",
      buttonName: "New Card",
      buttonLink: "/add-credit-cards",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/add-credit-cards",
      component: AddCreditCards,
      title: "Add Credit Cards",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: " ",
      component: Projects,
      title: "",
      menuName: "Project Types",
      buttonName: "",
      buttonLink: "",
      icon: mdiClipboardList,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/company-setting",
      component: CompanySettings,
      title: "",
      menuName: "Account Management",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCog,
      iconSize: 1,
      isShow: hideMenu,
      protected: true,
    },
    {
      path: "/schedule",
      component: Schedule,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
    {
      path: "/Lot",
      component: LotDetails,
      title: "",
      buttonName: "",
      buttonLink: "",
      icon: mdiAccountCreditCard,
      iconSize: 1,
      isShow: false,
      protected: true,
    },
  ];

  const { drawerOpen } = useDrawer();
  const { showBanner } = useBannerContext();
  useEffect(() => {
    // Force DataGrid to resize when drawer state changes
    window.dispatchEvent(new Event("resize"));
  }, [drawerOpen]);

  return (
    <>
      <ModalProvider>
      <IdleTimer timeout={8 * 60 * 60 * 1000} /> {/* Set timer for 8 hours */}
        <Router>
          <div className={styles.layout}>
            {<Header />}
            <div
              className={
                `${styles.content} +  content_with_margin` +
                " " +
                `${showBanner ? "showTopBanner" : " "}`
              }
            >
              {email && <NavigationBar routes={routes} />}
              <div
                className={
                  `${styles.contentInner}` +
                  " " +
                  `${drawerOpen
                    ? styles.drawerOpenClass
                    : styles.drawerClosedClass
                  }`
                }
              >
                {<BreadcrumbsComponent routes={routes} />}
                <Routes>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        route.protected ? (
                          <ProtectedRoute>
                            <route.component pageTitle={route.title} />
                          </ProtectedRoute>
                        ) : (
                          <route.component pageTitle={route.title} />
                        )
                      }
                    />
                  ))}
                </Routes>
              </div>
            </div>
          </div>
        </Router>
        <CustomModal />
      </ModalProvider>
    </>
  );
};

export default AppRouter;
