import React, { useState } from "react";
import styles from "./forgetPassword.module.scss";
import {
  Box,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccount, mdiLock } from "@mdi/js";
import { useLocation, useNavigate } from "react-router-dom";
import { userService } from "../../api";
import { transform } from "typescript";
import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language";
const ForgetPassword: React.FC = () => {
  const location = useLocation();
  const emailFromParams = location.state?.email;
  const [formDetails, setFormDetails] = useState({ email: emailFromParams });
  const [emailError, setEmailError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormDetails({ ...formDetails, email: event.target.value });
    setEmailError(null);
    setIsDisabled(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsDisabled(true);
    if (!formDetails.email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(formDetails.email)) {
      setEmailError("Invalid email format");
      return;
    }
    if (!emailError) {
      try {
        const language: Language = localStorage.getItem('language') === 'en' ? Language.en : Language.es;
        const response = await userService.emailVerify(formDetails.email,language);
        if (response.isEmailExists && response.isEmailVerified) {
          const responce = await userService.forgotPasswordLink(
            formDetails.email, language
          );
          if (responce.isSuccess) {
            if (language === Language.en) {
              setSnackbarMessage("Password reset link has been sent to your email");
            } else if (language === Language.es) {
              setSnackbarMessage("El enlace para restablecer la contraseña ha sido enviado a tu correo electrónico");
            }
            setOpenSnackbar(true);
          } else {
            setSnackbarMessage("Failed to send password reset link");
            setOpenSnackbar(true);
          }
        } else {
          setEmailError("Unverified Email");
        }
      } catch (error: any) {
        setEmailError("An error occurred. Please try again later.");
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.login_left}>
        <div className={styles.login_left_content}>
          <img
            src={process.env.PUBLIC_URL + "/assets/new_logo_teal.svg"}
            width="175px"
            alt="Logo"
          />
          <h2 id="welcome_text_left">{t('forgetPassword.welcome_text_left')}</h2>
          <p id="sub_text_left">{t('forgetPassword.sub_text_left')}</p>
          <Button id="read_more_btn" className={styles.white_button} sx={{ padding: "8px 30px" }}>
            {t('forgetPassword.read_more_btn')}
          </Button>
        </div>
      </div>
      <div className={styles.login_right}>
        <div className={styles.login_right_content}>
          <h3 id="welcome_text_right">{t("forgetPassword.welcome_text_right")}</h3>
          <p id="sub_text_right">
            {t("forgetPassword.sub_text_right")}
          </p>
          {emailError && <p className={styles.errorText}>{emailError}</p>}
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailid"
              placeholder={t("forgetPassword.emailid")}
              name="emailid"
              className={styles.textField}
              onChange={handleEmailChange}
              value={formDetails.email}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiAccount} size={1} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isDisabled}
              id="send_btn"
              sx={{
                mt: 2,
                mb: 2,
                fontFamily: "Nunito Sans",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                borderRadius: "4px",
                background: "var(--primary)",
                padding: "18px 26px !important",
                boxShadow: "none !important",
              }}
            >
              {t("forgetPassword.send_btn")}
            </Button>
            <Button
              type="button"
              href="/login"
              fullWidth
              variant="outlined"
              sx={{
                mt: 2,
                mb: 2,
                fontSize: "15px",
                background: "#FFF",
                borderColor: "var(--primary)",
                color: "var(--primary)",
                padding: "14px 26px !important",
                boxShadow: "none !important",
                textTransform: "none !important",
              }}
            >
              {t("forgetPassword.back_to_login_btn")}
            </Button>
          </Box>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};
export default ForgetPassword;
