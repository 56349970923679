import UserAxios from "../UserAxios";

const CreateLotAsync = async (formData: FormData) =>{
  
  const response = await UserAxios.post(`Projects/CreateLotAsync`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

const UpdateLotAsync = async (formData: FormData) =>{
  const response = await UserAxios.put(`Projects/UpdateLotViewModel`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}
const getLotById = async (projectId: any) => {
  
  const response = await UserAxios.get(`Projects/GetLotByIdAsync?projectId=${projectId}`);
  return response.data;
}
const LotService= { CreateLotAsync,UpdateLotAsync,getLotById}

export default LotService;