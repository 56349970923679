import React from "react";
import styles from "./addCreditCards.module.scss";
import { Box, Button, Grid, MenuItem, Select, TextField, styled } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Icon from '@mdi/react';
import { mdiCloudUpload } from "@mdi/js";


const AddCreditCards: React.FC = () => {


  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
  return <div className={styles.addProjectcontainer}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <h3>Add Credit Card</h3>
      </Grid>
    </Grid>
    <Box component="form" noValidate sx={{ mt: 1 }}>
    <Grid container spacing={3} alignItems="flex-start">
      <Grid container item xs={6}>
        <Grid item container className={styles.fieldWrapper}>
          <label htmlFor="name">Credit Card Type:</label>
          <TextField
            placeholder="Credit Card Type"
            variant="outlined"
            className="width70"
          />
        </Grid>
        <Grid item container className={styles.fieldWrapper}>
          <label htmlFor="name">Issued To:</label>
          <TextField
            placeholder="Issued To"
            variant="outlined"
            className="width70"
          />
        </Grid>
        <Grid item container className="fieldWrapper">
          <Grid item xs={6} className="multiColumns">
              <label id="status_placeholder" htmlFor="name" className="FirstLabel">Last 4-digits:</label>
              <TextField
                placeholder="1234"
                variant="outlined"
              />
          </Grid>
          <Grid item xs={6} className="multiColumns">
              <label id="type" htmlFor="name" className="no_margin">Expiry::</label>
              <TextField
                placeholder="MM/YYYY"
                variant="outlined"
              />
          </Grid>
        </Grid> 
      </Grid>

      <Grid container item xs={12} className={styles.submitButtons}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
          >
            Save
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="primary-button widthauto"
          >
            Save & Add Another Project
          </Button>
      </Grid>
    </Grid>
    </Box>
  </div>;
};

export default AddCreditCards;
