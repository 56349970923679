import { jwtDecode } from 'jwt-decode';

// To validate token 
export const ValidateToken = (): void => {
    var token = localStorage.getItem("token");
    if (token) {
        var jwtData = jwtDecode(token);
        const currentTime = Math.floor(new Date().getTime() / 1000);
        const tokenExpirationTime = jwtData?.exp ?? 0;
        if (tokenExpirationTime && !(tokenExpirationTime < currentTime)) {
            window.location.href = "/projects";
        }
    }
};