import React, { useEffect, useState } from "react";
import styles from "./scheduleChart.module.scss";
import { Box, Grid, Tooltip } from "@mui/material";
import TimeLine from "react-gantt-timeline";
import { useLocation } from "react-router-dom";
import { scheduleService } from "../../../api"; // Import the new service function
import { IProjectParam } from "../../../interfaces";
import { UserTypeId } from "../../../enums";
import AddProjectTask from "../../Tasks/AddProjectTask/AddProjectTask";
import { useTranslation } from "react-i18next";

const ScheduleChart = (props: any) => {
  const location = useLocation();
  const {t}=useTranslation();
  const projectId = location.state?.projectId;
  const [count, setCount] = React.useState(0);
  const [contact, setContact] = useState<any>(null);
  const [scheduleDate, setScheduleDate] = useState<any>(null);
  // Fetch schedules for the Gantt chart
  const [scheduleTaskList, setScheduleTaskList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  // handle for drawer
  const [addTaskDrawerOpen, setAddTaskDrawerOpen] = React.useState(false);
  const [refreshTask, setRefreshTask] = useState(false);
  useEffect(() => {
    if (refreshTask) {
      fetchData(projectId);
    }
  }, [refreshTask]);
  // handle for closing drawer
  const handleAddTaskDrawerClose = () => {
    setSelectedTask(null);
    setAddTaskDrawerOpen(false);
  };

  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };

  const handleTaskClick = (Schedules: any) => {
    if (Schedules && Schedules.id) {
      const selectedTaskData = {
        id: Schedules.id,
        name: Schedules.name,
        assignedToVendorId: Schedules.assignedToVendorId || null,
        assignedToUserId: Schedules.assignedToUserId || null,
      };
      setSelectedTask(selectedTaskData);
      setAddTaskDrawerOpen(true);
    } else {
      console.error("Clicked task has no ID or is invalid.");
    }
  };
  const fetchData = async (projectId: number) => {
    try {
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: 1,
          pageSize: 0,
          sortBy: undefined,
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: props.searchKey,
          isGantt: true,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const data = await scheduleService.getSchedules(
          projectId,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );
        setScheduleTaskList(data.schedules || []);
        setCount(data.totalCount);
        setContact(data.contact);
        setScheduleDate(data.scheduleDate);
        if (data) {
          // Define an array of color options
          const colorOptions: string[] = [
            "#3F4BDB",
            "#00BAE5",
            "#7C71FF",
            "#00B69B",
            "#4AD991",
            "#46E8AF",
          ];

          const schedules = data.schedules.map((task: any) => ({
            id: task.scheduleId,
            name: task.scheduleName,
            start: new Date(task.scheduleDate),
            end: new Date(task.scheduleEndDate),
            color:
              colorOptions[Math.floor(Math.random() * colorOptions.length)], // Randomly select a color
          }));
          setScheduleTaskList(schedules);
          setLoading(false);
        }
      }
    } catch (error: any) {
      setError(
        error.response?.data?.errors?.join(", ") || "Failed to load data"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (projectId) {
    fetchData(projectId);
    // }
  }, [projectId, props.searchKey]);

  const config = {
    header: {
      top: {
        style: {
          backgroundColor: "var(--primary)",
          color: "white",
          fontSize: 16,
        },
      },
      middle: {
        style: {
          backgroundColor: "var(--primary)",
          color: "white",
          fontSize: 14,
        },
      },
      bottom: {
        style: {
          background: "white",
          fontSize: 12,
          color: "grey",
        },
        selectedStyle: {
          background: "linear-gradient( #d011dd ,#d011dd)",
          fontWeight: "bold",
          color: "white",
        },
      },
    },
    taskList: {
      enabled: true,
      width: "100%",
      maxWidth: "100%",
      title: {
        label: t("ScheduleChart.schedule_label"),
        style: {
          backgroundColor: "#FFF",
          borderBottom: "solid 1px #ddd",
          color: "#000",
          fontWeight: "bold",
          textAlign: "left",
          padding: "10px 5px",
          fontSize: 16,
        },
      },
      task: {
        // The items inside the list diplaying the task
        height: 50,
        style: {
          backgroundColor: "#FFF",
          borderBottom: "solid 1px #ddd",
          color: "#000",
          textAlign: "left",
          padding: "10px 5px",
          fontSize: 14,
        },
      },
      verticalSeparator: {
        style: {
          backgroundColor: "#FFF",
        },
        grip: {
          style: {
            backgroundColor: "var(--primary)",
          },
        },
      },
    },
    dataViewPort: {
      rows: {
        style: {
          backgroundColor: "#FFF",
          borderBottom: "solid 0.5px #EEE",
          width: "100%",
          maxWidth: "100%",
        },
      },
      task: {
        showLabel: true,
        style: {
          position: "relative",
          borderRadius: 6,
          color: "#FFF",
          textAlign: "right",
          fontSize: 14,
          top: "50%",
          right: "-100%",
        },
      },
    },
  };

  return (
    <Grid item container>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 330px)",
          maxWidth: "100%",
          mx: "auto",
          mt: 2,
          overflow: "hidden",
        }}
      >
        <TimeLine
          data={scheduleTaskList}
          columns={[{ title: "Task", key: "name", width: 200 }]}
          timeScale={{ unit: "day", step: 1 }}
          viewMode="month"
          barColor="var(--primary)"
          barheight={50}
          itemheight={30}
          config={config}
          nonEditableName={true}
          width={100}
          onSelectItem={(task: any) => handleTaskClick(task)} // Use onSelectItem instead
        />
      </Box>
      <AddProjectTask
        open={addTaskDrawerOpen}
        onClose={handleAddTaskDrawerClose}
        task={selectedTask}
        refreshTask={handleDataFromChild}
      />
    </Grid>
  );
};

export default ScheduleChart;
