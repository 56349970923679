import { IProjectParam } from "../../interfaces";
import UserAxios from "../UserAxios";


const addEquipment = async (equipmentDetails: any) => {
  const response = await UserAxios.post(`Equipment/createEquipment`, equipmentDetails)
  return response.data
}
const getEquipment = async (projectId: any, userTypeId: number, userId: string, param: IProjectParam) => {
  const response = await UserAxios.post(`Equipment/getEquipmentsByProjectId?ProjectId=${projectId}&userTypeId=${userTypeId}&userId=${userId}`, param)
  return response.data
}
const getEquipmentById = async (equipmentId: any) => {
  const response = await UserAxios.get(`Equipment/${equipmentId}`)
  return response.data
}
const updateEquipment = async (equipmentDetails: any) => {
  const response = await UserAxios.post(`Equipment/updateEquipment`, equipmentDetails)
  return response.data
}

const uploadAttachment = async (equipmentDetails: any, files: Array<File>, entityTypeId: string) => {
  const formData = new FormData();
  formData.append('customerId', equipmentDetails.customerId);
  formData.append('entityTypeId', entityTypeId);//8=equipment 
  formData.append('entityId', equipmentDetails.equipmentId.toString());
  formData.append('createdBy', equipmentDetails.modifiedBy);
  formData.append('projectId', equipmentDetails.projectId);
  for (let i = 0; i < files.length; i++) {
    formData.append(`files`, files[i])
  }
  const response = await UserAxios.post(`attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data;
}
const deleteEquipment = async (equipmentId: any) => {
  const response = await UserAxios.delete(`Equipment/deleteEquipment/${equipmentId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
  return response.data;
}

const equipmentService = { addEquipment, getEquipmentById, getEquipment, updateEquipment, uploadAttachment, deleteEquipment };
export default equipmentService;