import styles from "./charts.module.scss";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
  Chart as Chartjs,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { BorderColor } from "@mui/icons-material";
import plugin from "dayjs/plugin/utc";

Chartjs.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Charts = (props: { type: string; paymentPaid: any }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        border: {
          display: false,
          dash: [2, 2],
        },
        grid: {
          color: "rgba(72,128,255,0.4)",
          tickBorderDash: [0, 6],
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
          dash: [2, 2],
        },
        grid: {
          color: "rgba(72,128,255,0.4)",
          tickBorderDash: [0, 6],
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide default legend
      },
      tooltip: {
        enabled: true, // Enables hover tooltips
      },
      title: {
        display: true,
        text: "Task Breakdown",
      },
    },
  };
  const data = {
    labels: ["Total", "Completed", "Pending"],
    datasets: [
      {
        label: "Tasks Breakdown",
        data: [40, 20, 20],
        backgroundColor: ["var(--primary)", "#00B69B", "#F8961E"],
        borderColor: "rgba(72,128,255,0.4)",
        borderWidth: 1,
        barPercentage: 0.25,
        categoryPercentage: 0.5,
        borderRadius: 100,
        borderSkipped: false,
      },
    ],
  };
  const options1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#333",
        titleColor: "#fff",
        bodyColor: "#fff",
        padding: 12,
        displayColors: true,
        callbacks: {
          title: function () {
            return "AMOUNT SPENT";
          },
          label: function (tooltipItem: any) {
            return ` ${tooltipItem.label}: $${tooltipItem.raw}`;
          },
          labelColor: function (tooltipItem: any) {
            return {
              backgroundColor:
                tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex],
              borderColor: "#fff",
              borderWidth: 1,
              borderRadius: 4,
            };
          },
        },
      },
    },
  };

  const customerData = {
    labels: ["Labor", "Materials", "Equipment"],
    datasets: [
      {
        // label: 'My First Dataset',
        data: [
          props.paymentPaid.taskAmountPaid,
          props.paymentPaid.materialAmountPaid,
          props.paymentPaid.equipmentAmountPaid,
        ],
        backgroundColor: ["#4BC0C0", "#1c3f3a", "#C29D60"],
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={styles.chart_container}>
      {props.type === "Bar" ? <Bar options={options} data={data} /> : null}

      {props.type === "Doughnut" ? (
        customerData.datasets[0].data[0] == 0 &&
        customerData.datasets[0].data[1] == 0 &&
        customerData.datasets[0].data[2] == 0 ? (
          <div className={styles.no_data}>No payments available</div>
        ) : (
          <Doughnut data={customerData} options={options1} />
        )
      ) : null}
    </div>
  );
};

export default Charts;
