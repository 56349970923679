import { ReactNode, createContext, useContext, useState } from "react";

// Banner Context
interface BannerContextProps {
  showBanner: boolean;
  setShowBanner: (value: boolean) => void;
}

const BannerContext = createContext<BannerContextProps | undefined>(undefined);

export const useBannerContext = () => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error("useBannerContext must be used within a BannerProvider");
  }
  return context;
};

export const BannerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showBanner, setShowBanner] = useState(false);

  return (
    <BannerContext.Provider value={{ showBanner, setShowBanner }}>
      {children}
    </BannerContext.Provider>
  );
};
