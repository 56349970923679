import dayjs from "dayjs";
import { IAddEquipment } from "../interfaces/IAddEquipment";


interface ValidationErrors {
  [key: string]: string;
}

export const equimentValidate = (equipment: IAddEquipment): ValidationErrors => {
  const errors: ValidationErrors = {};
  if (!equipment.costCodeId.trim()) {
    errors.costCodeId = "Category is required.";
  }
  if (!equipment.equipmentName.trim()) {
    errors.equipmentName = "Equipment name is required";
  }
  if (!equipment.rentDate) {
      errors.rentDate = "Date is required";
  }

  return errors;
};