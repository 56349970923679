import { useEffect, useRef } from 'react';
import { useModal } from './ModalContext';

interface IdleTimerProps {
    timeout: number; // Timeout in milliseconds
}

const IdleTimer: React.FC<IdleTimerProps> = ({ timeout }) => {
    const { openModal}: any = useModal();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    
    const resetTimer = () => {
        localStorage.setItem('lastActivity', Date.now().toString());
    };
    
    useEffect(() => {
        const handleUserActivity = () => {
            resetTimer();
        };
        // check idle time 
        const checkIdleTime = () => {
            const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0');
            if (Date.now() - lastActivity >= timeout) {
                const token = localStorage.getItem('token');
                if (token) {
                    openModal("Your session is expired due to inactivity. Please Sign In again.");
                }
            }
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('scroll', handleUserActivity);     
        window.addEventListener('storage', checkIdleTime);

        resetTimer();
 
        timerRef.current = setInterval(checkIdleTime, 1000);

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('scroll', handleUserActivity);
            window.removeEventListener('storage', checkIdleTime);
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [timeout]);

    return null;
};

export default IdleTimer;
