import { useTranslation } from "react-i18next";
import styles from "./languageMenu.module.scss";
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { settingService } from "../../api";

const LanguageMenu = () => {
  const LANGUAGES = [
    { label: "English", code: "en" },
    { label: "Spanish", code: "es" },
  ];

  const { i18n } = useTranslation();
  const [emailError, setEmailError] = React.useState<string | null>(null);
  // Function to make the API call
  const updateLanguageInDB = async (userId: string, languageCode: number) => {
    try {
      const response = await settingService.SaveUserLanguageAsync(userId, languageCode)
      // Check if the response is successful by looking at the `isSuccess` property
      if (response.isSuccess) {
        console.log('Language updated successfully:', response);
      } else {
        throw new Error('Failed to update language');
      }
    } catch (error) {
      setEmailError("An error occurred. Please try again later.");
    }
  };

  const onChangeLang = (e: SelectChangeEvent) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    localStorage.setItem("language", lang_code);
    const userId = localStorage.getItem('userId');
    if (userId) {
      const languageCode = lang_code === "en" ? 1 : 2;
      updateLanguageInDB(userId, languageCode);
    } else {
      console.error('User ID not found in localStorage');
    }
  };

  // // Handle user login to set the language in DB if it's not set
  // React.useEffect(() => {
  //   const userId = localStorage.getItem('userId');
  //   const storedLanguage = localStorage.getItem('language');
  //   if (userId && storedLanguage) {
  //     const languageCode = storedLanguage === "en" ? 1 : 2;
  //     updateLanguageInDB(userId, languageCode);
  //   }
  // }, []);

  return (
    <nav>
      <FormControl className={styles.language_menu}>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={i18n.language}
          onChange={onChangeLang}
          inputProps={{ shrink: "false" }}
        >
          {LANGUAGES.map(({ code, label }) => (
            <MenuItem key={code} value={code}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </nav>
  );
};

export default LanguageMenu;
