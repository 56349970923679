import React, { useEffect, useState } from "react";
import styles from "./costCode.module.scss";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiMagnify } from "@mdi/js";
import { settingService } from "../../api";
import {
  ITabPanelProps,
  ICostCategoryProps,
  ICostCodeProps,
} from "../../interfaces";
import { decryptData } from "../../utils/CryptoJS";
import { AddCostCategory, AddCostCode } from "../../containers";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      className={styles.tabpanel_container}
    >
      {value === index && (
        <Box component="div" sx={{ mt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CostCode: React.FC = () => {
  const [openAddcode, setOpenAddcode] = React.useState(false);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [costCategorieswithCostCodes, setCostCategoriesWithCostCodes] =
    useState([]);
  const [addCostCategoryError, setAddCostCategoryError] = useState<
    string | null
  >(null);
  const [addCostCodeError, setAddCostCodeError] = useState<string | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  var userDetails: any = localStorage.getItem("userDetails");
  var userDetailsObj = decryptData(userDetails);
  // const subAccessNumber = userDetailsObj.subAccessNumber;

  const costCategoryObj: ICostCategoryProps = {
    categoryName: "",
    categoryNumber: "",
    createdBy: userDetailsObj.userId,
    // subAccessNumber: ''
  };
  const addCostCodeObj: ICostCodeProps = {
    CostCategoryId: 0,
    CostCodeNumber: "",
    CostCodeName: "",
    CostCodeDescription: "",
    CreatedBy: userDetailsObj.userId,
    // SubAccessNumber: ''
  };

  const [costCodeDetails, setCostCodeDetails] = useState({
    ...addCostCodeObj,
  });
  const [categoryDetails, setCategoryDetails] = useState({
    ...costCategoryObj,
  });
  //Handling the cost code dialog open
  const handleClickOpenAddCodeDialog = async () => {
    setOpenAddcode(true);
  };
  //Handling the cost code dialog close
  const handleClickCloseAddCodeDialog = () => {
    setOpenAddcode(false);
    costCodeDetails.CostCategoryId = 0;
    costCodeDetails.CostCodeName = "";
    costCodeDetails.CostCodeNumber = "";
    costCodeDetails.CostCodeDescription = "";
    setAddCostCodeError(null);
  };
  //Handling the cost category dialog open
  const handleClickOpenAddCategoryDialog = () => {
    setOpenAddCategory(true);
  };
  //Handling the cost category dialog close
  const handleCloseAddCategoryDialog = () => {
    setOpenAddCategory(false);
    categoryDetails.categoryName = "";
    categoryDetails.categoryNumber = "";
    setAddCostCategoryError(null);
  };

  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "5px",
      marginBottom: "-1px",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "#1814F3",
    },
    ".MuiButtonBase-root": {
      borderRadius: "10px 10px 0px 0px",
      padding: "0px 20px",
      minHeight: "35px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(24, 20, 243, 0.05) !important",
      color: "#000 !important",
    },
  });

  const [value, setValue] = useState(0);
  //Handling the change of data
  const handleChange = (evet: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  //Handling the fetching of data
  const fetchData = async () => {
    // if(userDetailsObj.SubAccessNumber){
    try {
      const costcoderesponse =
        await settingService.getCostCodesWithCategoryList();
      setCostCategoriesWithCostCodes(costcoderesponse.costCodesWithCategories);
    } catch (error) {
      console.error(error);
    }
    // }
  };

  useEffect(() => {
    fetchData();
  }, []);
  //This is used to check if the text is truncated
  const isTruncated = (text: string, maxLength: number): boolean => {
    return text.length > maxLength;
  };
  //This is used to truncate the text
  const truncateText = (text: string, maxLength: number): string => {
    if (isTruncated(text, maxLength)) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  function Row(props: { row: any }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const truncatedText = truncateText(row.categoryName, 50);
    const showTooltip = isTruncated(row.categoryName, 50);

    return (
      <React.Fragment>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell width="50px">
            {/* <Checkbox
              color="primary"
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            /> */}
          </TableCell>
          <TableCell
            component="td"
            width="40%"
            className={styles.expand_button_row}
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              className={styles.expand_button}
            >
              {open ? (
                <Icon path={mdiChevronUp} size={1} />
              ) : (
                <Icon path={mdiChevronDown} size={1} />
              )}
              {row.categoryNumber} -{" "}
              {showTooltip ? (
                <Tooltip title={row.categoryName}>
                  <span> {truncatedText}</span>
                </Tooltip>
              ) : (
                <span>{row.categoryName}</span>
              )}
            </IconButton>
          </TableCell>
          {/* <TableCell width='40%' component="td" scope="row">
           
          </TableCell> */}

          <TableCell width="58%">
            {visibleDiv === "categoryContainer" && (
              <span>{row.costCodes.length}</span>
            )}
          </TableCell>
        </TableRow>
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell className="pv_0" colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {row.costCodes.map((costCodes: any) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={costCodes.codeId}
                      >
                        <TableCell width="50px">
                          {/* <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          /> */}
                        </TableCell>
                        <TableCell
                          component="td"
                          scope="row"
                          width="41%"
                          className={styles.expand_button_row}
                        >
                          {row.categoryNumber}-{costCodes.costCodeNumber}{" "}
                          {costCodes.costCodeName}
                        </TableCell>
                        <TableCell width="58%">
                          {visibleDiv === "codeContainer" && (
                            <span>{costCodes.costCodeDescription}</span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const [visibleDiv, setVisibleDiv] = useState<
    "codeContainer" | "categoryContainer"
  >("codeContainer");

  const handleCodeContainer = () => {
    setVisibleDiv("codeContainer");
  };

  const handleCategoryContainer = () => {
    setVisibleDiv("categoryContainer");
  };

  return (
    <Grid className={styles.Gridcontainer}>
      <Grid container className={styles.whiteBox} alignItems="flex-start">
        <Grid item xs={12} className={styles.whiteBoxInner}>
          <CustomTabs
            value={value}
            className={styles.tab_title}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Cost Code" />
            <Tab label="Price Book" />
          </CustomTabs>
          <TabPanel value={value} index={0}>
            {apiError && <p className={styles.errorText}>{apiError}</p>}
            <Grid
              container
              className={styles.search_container}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid
                container
                item
                xs={6}
                className={styles.chip_container}
                alignItems="center"
                justifyContent="flex-start"
              >
                <Typography variant="h3">
                  {visibleDiv === "codeContainer" && <b> Cost Code </b>}
                  {visibleDiv === "categoryContainer" && <b> Cost Category </b>}
                </Typography>
                <Chip
                  label="Code"
                  className={`${
                    visibleDiv === "codeContainer" ? styles.active : ""
                  } ${styles.chip_button}`}
                  onClick={handleCodeContainer}
                />
                <Chip
                  label="Category"
                  className={`${
                    visibleDiv === "categoryContainer" ? styles.active : ""
                  } ${styles.chip_button}`}
                  onClick={handleCategoryContainer}
                />
              </Grid>
              <Grid
                className={styles.search_container_inner}
                container
                item
                xs={6}
                alignItems="center"
                justifyContent="flex-end"
              >
                {/* {visibleDiv === 'codeContainer' && (
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  className={styles.marginRight25 + " outline-button widthauto"}
                  onClick={handleClickOpenAddCodeDialog}
                >
                  + Add Code
                </Button>
              )} */}
                {/* {visibleDiv === 'categoryContainer' && (
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  className={styles.marginRight25 + " outline-button widthauto"}
                  onClick={handleClickOpenAddCategoryDialog}
                >
                  + Add Category
                </Button>
              )} */}
                <TextField
                  placeholder="Search"
                  variant="outlined"
                  className="width30"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon path={mdiMagnify} size={1} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-start">
              {visibleDiv === "codeContainer" && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="50px">
                            {/* <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          /> */}
                          </TableCell>
                          <TableCell width="40%">Code</TableCell>
                          <TableCell width="58%">Description</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {costCategorieswithCostCodes.map((row, index) => (
                          <Row key={index} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
              {visibleDiv === "categoryContainer" && (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell width="50px">
                            {/* <Checkbox
                            color="primary"
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                          /> */}
                          </TableCell>
                          <TableCell width="40%">Category</TableCell>
                          <TableCell width="58%">Codes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={styles.category_row}>
                        {costCategorieswithCostCodes.map((row, index) => (
                          <Row key={index} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}></TabPanel>
        </Grid>
      </Grid>
      <AddCostCode open={openAddcode} onClose={handleClickCloseAddCodeDialog} />

      <AddCostCategory
        open={openAddCategory}
        onClose={handleCloseAddCategoryDialog}
      />
    </Grid>
  );
};
export default CostCode;
