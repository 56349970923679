import UserAxios from "../UserAxios";

const sendEmailReminder = async (payload: any) => {
    const response = await UserAxios.post(`ScheduleSetting`, payload, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return response.data
}

const scheduleSettingService = { sendEmailReminder }

export default scheduleSettingService;