import React, { useState, useRef, useEffect } from "react";
import styles from "./landingpage.module.scss";
import {
  Box,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiEmailOutline } from "@mdi/js";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { userService } from "../../api";
import { ValidateToken } from "../../utils/AuthValidation";
import { encryptVerifyEmail } from "../../utils/CryptoJS";
import { LanguageMenu } from "../../components";
import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language";

const LandingPage: React.FC = () => {
  useEffect(() => {
    ValidateToken();
  }, []);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState<{ email?: string }>({});
  const { t } = useTranslation();
  const language: Language = localStorage.getItem('language') === 'en' ? Language.en : Language.es;
  const MyTextField = styled(TextField)({
    "& .MuiFormControl-root": {
      margin: "0px",
    },
    "& .MuiInputBase-root": {
      borderRadius: "50px",
      width: "100%",
      padding: "8px 28px",
      paddingRight: "40%",
      border: "1px solid var(--secondary)",
      "& fieldset": {
        border: "0px",
      },
    },
    "& .MuiFormLabel-root": {
      color: "blue",
    },
  });
  const FreetrailButton = styled(Button)`
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 50px;
    padding: 16px 30px !important;
    box-shadow: none !important;
    position: absolute;
    top: 31px;
    right: 10px;
    width: 40%;
    background: linear-gradient(107deg, var(--primary) 0%, var(--primary) 100%);
    text-decoration: none;
    text-transform: none;

    @media screen and (max-width: 768px) {
      width: 80%;
      position: relative;
      margin: auto;
      font-size: 16px;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  `;

  const emailInputRef = useRef<HTMLInputElement>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cursorPosition = event.target.selectionStart;
    setEmail(event.target.value);
    setErrors({});
    if (emailInputRef.current) {
      setTimeout(() => {
        emailInputRef.current!.selectionStart = cursorPosition;
        emailInputRef.current!.selectionEnd = cursorPosition;
      }, 0);
    }
  };

  const validateEmail = (email: string) => {
    const errors: { email?: string } = {};
    if (!email) {
      errors.email = `${t('Landing-page.email_address_is_required')}`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = "Invalid email";
    }
    return errors;
  };

  const onRedirectToSignup = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();

    const validationErrors = validateEmail(email);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await userService.emailVerify(email, language);
      const { isEmailExists, isEmailVerified } = response;

      if (isEmailExists && isEmailVerified) {
        navigate("/login", {
          state: {
            showSnackbar: true,
            message: language === Language.en ? "Your account already exists. Please proceed to sign-in." : "Tu cuenta ya existe. Por favor, procede a iniciar sesión.",
          },
        });
      } else if (isEmailExists && !isEmailVerified) {
        let message;
        if (language === 1) {
          message = "A confirmation email has already been sent to your email address. Please verify your email to activate your free trial.";
        } else  {
          message = "Un correo electrónico de confirmación ya ha sido enviado a su dirección de correo electrónico. Por favor, verifique su correo electrónico para activar su prueba gratuita.";
        }
        setSnackbarMessage(message);
        setOpenSnackbar(true);
      } else if (!isEmailExists && !isEmailVerified) {
        const encryptedEmail = encryptVerifyEmail(email);
        if (encryptedEmail == null) {
          setSnackbarMessage("An error occurred. Please try again.");
          setOpenSnackbar(true);
          return;
        }
        navigate(`/signup?email=${encryptedEmail}&fromFreeTrial=true`); // Pass fromFreeTrial param
      }
    } catch (error) {
      console.error("Error checking email status", error);
      setSnackbarMessage("An error occurred. Please try again.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.landingContainer}>
      <div className={styles.landingpage_header}>
        <img
          src={process.env.PUBLIC_URL + "/assets/new_logo_teal.svg"}
          width="175px"
        />
        <div className={styles.d_flex}>
          <LanguageMenu />
          <Link className={styles.white_button} to="/login" id="sign_in_btn">
            {" "}
            {t('Landing-page.sign_in_btn')}{" "}
          </Link>
        </div>
      </div>
      <div className={styles.landingpage_content}>
        <h1 id="welcome_message">{t('Landing-page.welcome_message')}</h1>
        <p id="welcome_sub_message">
          {" "}
          {t('Landing-page.welcome_sub_message')}{" "}
        </p>
        <div className={styles.landingPage_form}>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <MyTextField
              margin="normal"
              required
              fullWidth
              id="emailid"
              placeholder={t('Landing-page.enter_email_placeholder')}
              name="emailid"
              autoFocus
              onChange={handleEmailChange}
              value={email}
              error={!!errors.email}
              helperText={errors.email}
              inputRef={emailInputRef}
              size="medium"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon path={mdiEmailOutline} size={1} />
                  </InputAdornment>
                ),
              }}
            />
            <FreetrailButton
              type="submit"
              fullWidth
              id="start_for_free_btn"
              variant="contained"
              className="freetrail_button"
              onClick={onRedirectToSignup}
            >
              {t('Landing-page.start_for_free_btn')}
            </FreetrailButton>
          </Box>
        </div>
      </div>
      <img
        src={process.env.PUBLIC_URL + "/assets/dashboard-img2.png"}
        className={styles.dashboard_img}
      />

      <div className={styles.svg_img_container}>
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1200 120"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svgShape}
        >
          <path d="M321.39 56.44c58-10.79 114.16-30.13 172-41.86 82.39-16.72 168.19-17.73 250.45-.39C823.78 31 906.67 72 985.66 92.83c70.05 18.48 146.53 26.09 214.34 3V0H0v27.35a600.21 600.21 0 00321.39 29.09z" />
        </svg>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default LandingPage;
