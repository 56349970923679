import { Drawer, styled } from "@mui/material";

export const StyledDrawer = styled(Drawer)(({ open, theme }) => ({
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: open ? "40%" : 0, // Adjust this width as needed
    boxSizing: "border-box",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden", // Prevent horizontal scrollbars
    // Add the media query
    [theme.breakpoints.down("sm")]: {
      width: open ? "80%" : 0, // Adjust this width for small screens
    },
  },
}));

export default StyledDrawer;
