import React, { useEffect, useState } from "react";
import styles from "./header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Icon from "@mdi/react";
import {
  mdiBell,
  mdiChevronLeftCircle,
  mdiCog,
  mdiMenu,
  mdiPackageVariantClosed,
} from "@mdi/js";
import { sharedService, userService } from "../../api";
import { useUserContext } from "../../utils/UserContext";
import { BillingInfoDrawer, Dashboard } from "../../containers";
import { BillingInformation } from "../../containers";
import { encryptData } from "../../utils/CryptoJS";
import { useBannerContext } from "../../utils/BannerContext";
import { dateToDateOnlyString } from "../../utils/convertToDate";
import NotificationDialog from "../notificationDialog/notificationDialog";
import { logout } from "../../utils/logout";
import { useDrawer } from "../../utils/DrawerContext";
import LanguageMenu from "../LanguageMenu/LanguageMenu";


const Header: React.FC = () => {
  const NotificationTitle = "Your free trial is over!";
  const NotificationContent =
    "Your free trial period has ended. Please update your billing information to continue using premium features.";
  const NotificationButtonText = "Update the billing information";
  const navigate = useNavigate();
  const [addBillingOpen, setAddBillingOpen] = React.useState(false);
  const { email } = useUserContext();
  const [contactMenu, setContactMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [settingMenu, setSettingMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [projectMenu, setProjectMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [avatarMenu, setAvatarMenu] = React.useState<null | HTMLElement>(null);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { showBanner, setShowBanner } = useBannerContext();
  const [message, setMessage] = useState("");
  const [productEndDate, setProductEndDate] = useState<string | null>(null);
  const [isSubscriptionAdmin, setIsSubscriptionAdmin] = useState(null);
  const [open, setOpen] = React.useState(false);
  //free trial expired popup
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false);
  const openContactMenu = Boolean(contactMenu);
  const openSettingMenu = Boolean(settingMenu);
  const openProjectMenu = Boolean(projectMenu);
  const openAvatarMenu = Boolean(avatarMenu);
  const [userName, setUserName] = useState("");
  const [showBilling, setShowBilling] = React.useState(false);
  const [userRoles, setUserRoles] = useState<any>(null);
  const handleAddCardClick = () => {
    setShowBilling(true);
  };
  const handleCloseBilling = () => {
    setShowBilling(false);
  };
  const onContactMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
    setContactMenu(event.currentTarget);
  };
  const onSettingMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
    setSettingMenu(event.currentTarget);
  };
  const onProjectMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
    setProjectMenu(event.currentTarget);
  };

  const onAvatarMenuClicked = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenu(event.currentTarget);
  };

  const handleClose = () => {
    setContactMenu(null);
    setSettingMenu(null);
    setProjectMenu(null);
    setAvatarMenu(null);
  };
    //Handle drawerOpen
    const handleDrawerOpen = () => {
      setAddBillingOpen(true);
    };
  
    //Handle drawerClose
    const handleDrawerClose = () => {
      setAddBillingOpen(false);
    };

  const handleContactDetails = () => {
    navigate("/vendors", { replace: true });
    setContactMenu(null);
  };

  const handleCostCode = () => {
    navigate("/cost-code", { replace: true });
    setSettingMenu(null);
  };
  const handleCompanySetting = () => {
    navigate("/company-setting", { replace: true });
    setSettingMenu(null);
  };
  const handleRolesPermissions = () => {
    navigate("/roles-permissions", { replace: true });
    setSettingMenu(null);
  };
  const handleProjectList = () => {
    navigate("/projects", { replace: true });
    setProjectMenu(null);
  };
  const handleAddProject = () => {
    if (userRoles?.create_project) {
      navigate("/add-project", { replace: true });
      setProjectMenu(null);
    }
  };

  const handleBillingRedirect = () => {
    // Redirect to the target page and pass the tab index (1) using state
    navigate("/company-setting", { state: { tab: 1 } });
  };

  const location = useLocation();
  const pageName = location.pathname;
  const token = localStorage.getItem("token");
  const isvalidNavigationPage = React.useMemo(() => {
    const avoidNavigationPages = [
      "/",
      "/login",
      "/signup",
      "/forgot-password",
      "/reset-password",
      "/pricing",
    ];
    if (avoidNavigationPages.includes(pageName)) {
      return true;
    }
    return false;
  }, [pageName]);
  //free trial expired popup
  useEffect(() => {
    if (productEndDate && isSubscriptionAdmin) {
      const currentDate = new Date();
      const currentDateOnly = dateToDateOnlyString(currentDate);
      if (currentDateOnly > productEndDate) {
        setOpenNotificationDialog(true);
      } else {
        setOpenNotificationDialog(false);
      }
    }
  }, [productEndDate, isSubscriptionAdmin]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size
  const { drawerOpen, toggleDrawer } = useDrawer();

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        if (email && token) {
          const userDetails = await userService.getUserDetails(email);
          setUserName(
            userDetails.userSubscriptionDetail.firstName +
            " " +
            userDetails.userSubscriptionDetail.lastName
          );
          if (userDetails) {
            localStorage.setItem("userId", userDetails.userId);
            localStorage.setItem("customerId", userDetails.customerId);
            localStorage.setItem("email", userDetails.email);
            localStorage.setItem("userRoleId", userDetails.userAccessDto[0]?.userTypeId);
          }
          if (userDetails && userDetails.userSubscriptionDetail) {
            const data = userDetails.userSubscriptionDetail;
            const encryptedUserSubDetails = encryptData(data);
            localStorage.setItem("userDetails", encryptedUserSubDetails);
            setDaysRemaining(userDetails.userSubscriptionDetail.remainingDays);
            if (userDetails.userSubscriptionDetail.remainingDays === "1") {
              setMessage("Your trial period ends today.");
            } else {
              setMessage(
                `Your trial period ends in ${userDetails.userSubscriptionDetail.remainingDays} days.`
              );
            }
            setShowBanner(userDetails.userSubscriptionDetail.isDisplayBanner);
            //free trial expired popup
            setProductEndDate(
              userDetails.userSubscriptionDetail.productEndDate
            );
            setIsSubscriptionAdmin(userDetails.isSubscriptionAdmin);
            setUserRoles(await sharedService.getUserRolesPermissions());
          } else {
            setError("User details or subscription information not available.");
          }
        }
      } catch (error: any) {
        console.error("Error fetching user details:", error);
        setError(error.response.data.errors.join(", "));
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [email, setShowBanner, token]);

  if (isvalidNavigationPage) return null;
  return (
    <div className={`${styles.header_container} header_fixed_container`}>
      {showBanner && (
        <div className={styles.notification_bar}>
          {message} Please{" "}
          <Link onClick={handleDrawerOpen}>click here</Link> to purchase a
          subscription and retain all your data created so far.
        </div>
      )} 
      <header>
        <div className={styles.logoContainer}>
          {isMobile && (
            <IconButton
              className={styles.drawerOpenButton}
              onClick={toggleDrawer}
            >
              <Icon
                path={drawerOpen ? mdiChevronLeftCircle : mdiMenu}
                size={1}
              />
            </IconButton>
          )}

          <Link href="/dashboard">
            <img
              src={
                isMobile ? "./mobile_logo.svg" : "./assets/new_logo_white.svg"
              }
              alt="logo"
            />
          </Link>
        </div>
        <div className={styles.rightOptions}>
          {/* <div className={styles.contactMenu}>
            <Button
              id="contact-button"
              aria-controls={openContactMenu ? "contact-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openContactMenu ? "true" : undefined}
              onClick={onContactMenuClicked}
              className={styles.contactMenuButton}
            >
              Contacts
            </Button>
            <Menu
              id="contact-menu"
              aria-labelledby="contact-button"
              anchorEl={contactMenu}
              open={openContactMenu}
              onClose={handleClose}
              className={styles.headerDropMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleContactDetails}>Users</MenuItem>
              <MenuItem onClick={handleClose}>
                Sub-Contractors / Vendors
              </MenuItem>
              <MenuItem onClick={handleClose}>Manufacturers</MenuItem>
            </Menu>
          </div>
          <div className={styles.contactMenu}>
            <IconButton
              id="settings-button"
              aria-controls={openSettingMenu ? "settings-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openSettingMenu ? "true" : undefined}
              onClick={onSettingMenuClicked}
              className={styles.iconMenuButton}
            >
              <Icon path={mdiCog} size={1} />
            </IconButton>
            <Menu
              id="settings-menu"
              aria-labelledby="settings-button"
              anchorEl={settingMenu}
              open={openSettingMenu}
              onClose={handleClose}
              className={styles.headerDropMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={handleCompanySetting}>
                Company Settings
              </MenuItem>
              <MenuItem onClick={handleRolesPermissions}>
                Roles & Permissions
              </MenuItem>
              <MenuItem onClick={handleCostCode}>Cost Codes</MenuItem>
              <MenuItem onClick={handleClose}>Credit Cards</MenuItem>
            </Menu>
          </div> */}
          <LanguageMenu />
          <div className={styles.contactMenu}>
            <IconButton
              id="notification-button"
              aria-controls={openProjectMenu ? "project-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProjectMenu ? "true" : undefined}
              onClick={onProjectMenuClicked}
            >
              <Badge badgeContent={2} className={styles.notification_icon}>
                <Icon path={mdiBell} size={1} />
              </Badge>
            </IconButton>
            {/* <IconButton
              id="project-button"
              aria-controls={openProjectMenu ? "project-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProjectMenu ? "true" : undefined}
              onClick={onProjectMenuClicked}
              className={styles.iconMenuButton}
            >
              <Icon path={mdiPackageVariantClosed} size={1} />
            </IconButton> */}
            <Menu
              id="notification-menu"
              aria-labelledby="notification-button"
              anchorEl={projectMenu}
              open={openProjectMenu}
              onClose={handleClose}
              className={styles.headerDropMenu}
            >
              <MenuItem onClick={handleProjectList}>
                Welcome to CPro360 application
              </MenuItem>
              <MenuItem onClick={handleAddProject}>
                Task Assignment Notification
              </MenuItem>
            </Menu>
          </div>
          <div className={styles.userProfile}>
            <div className={styles.welcomeText}>{userName}</div>
            <Avatar
              className={styles.avatarCircle}
              sx={{ bgcolor: "var(--White)" }}
              alt={userName}
              src="/broken-image.jpg"
              onClick={onAvatarMenuClicked}
            />
            <Menu
              id="avatar-menu"
              aria-labelledby="avatar-button"
              anchorEl={avatarMenu}
              open={openAvatarMenu}
              onClose={handleClose}
              className={styles.headerDropMenu}
            >
              <MenuItem onClick={handleClose}>User Profile</MenuItem>
              <MenuItem onClick={logout}>Sign Out</MenuItem>
            </Menu>
          </div>
          {
            // <BillingInformation
            //   open={showBilling}
            //   onClose={handleCloseBilling}
            // />
          }
        </div>
        <BillingInfoDrawer
          open={addBillingOpen}
          onClose={handleDrawerClose}
          // onSaveContact={handleSaveContact}
          // rowIndex={null}
        ></BillingInfoDrawer>
      </header>
      {openNotificationDialog ? (
        <NotificationDialog
          open={open}
          title={NotificationTitle}
          content={NotificationContent}
          buttonText={NotificationButtonText}
        />
      ) : null}
    </div>
  );
};

export default Header;
