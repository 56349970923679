
interface ValidationErrors {
  [key: string]: string;
}

export const validateNote = (note: string): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!note.trim()) {
    errors.note = "Note is required.";
  }
  return errors;
};