import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import esTranslations from './locales/es/translation.json';

// Define the type for the resources
const resources = {
  en: {
    translation: enTranslations
  },
  es: {
    translation: esTranslations
  }
} as const; // Use "as const" for strict typing

// Initialize i18next
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'en',
  fallbackLng: 'en', // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes by default
  },
});

// Save the language when changed
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
