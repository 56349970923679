import React, { useRef, useState } from "react";
import styles from "./addDocumentNote.module.scss";
import {
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { StyledDrawer } from "../../../components";
import { IAddTaskDrawerProps } from "../../../interfaces";
import { validateNote } from "../../../utils/DocumentValidation";

const AddDocumentNote: React.FC<IAddTaskDrawerProps> = ({
  open,
  onClose,
  onSaveNote,
}) => {
  const [noteDetails, setNoteDetails] = useState<any>({
    notes: "",
  });
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const noteRef = useRef<HTMLInputElement>(null);


  // To handle field error focus
  const handleFocus = (errors: any) => {
    if (errors.note) {
      noteRef.current?.focus();
    }
  };
  // handle for closing drawer
  const handleAddNoteDrawerClose = () => {
    setNoteDetails({ notes: "" });
    if (onClose) {
      onClose();
    }
  };

  //To handle save notes
  const handleSaveNote = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    var error = validateNote(noteDetails.notes);
    setValidationErrors(error);
    handleFocus(error);

    if (Object.keys(error).length === 0) {// Proceed only if there are no validation errors
      onSaveNote!({
        ...noteDetails,
        createdDate: "",
        noteId: 0,
      });
      handleAddNoteDrawerClose();
    }
  }

  return (
    <StyledDrawer
      className={styles.rightDrawer}
      open={open}
      anchor="right"
      onClose={onClose}
    >
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        onSubmit={handleSaveNote}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            <h3>Add New Note</h3>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container className="fieldWrapper">
              <TextField
                id="note"
                placeholder="Notes"
                label="Notes"
                multiline
                rows={4}
                inputRef={noteRef}
                value={noteDetails.notes}
                error={!!validationErrors.note}
                helperText={validationErrors.note}
                onChange={(e) =>
                  setNoteDetails({ ...noteDetails, notes: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.submitButtons + " submitButtons"}
        >
          <Button
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
            onClick={handleAddNoteDrawerClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="primary-button widthauto"
          >
            Save
          </Button>
        </Grid>
      </Box>
    </StyledDrawer>
  );
};

export default AddDocumentNote;
