
import dayjs from "dayjs";
import { IProject, ProjectParams } from "../interfaces";

interface ValidationParamErrors {
  [item: string]: string;
}
export const validateProjectParam = (projectParams: any, templateParams: any = []): ValidationParamErrors => {
  const errors: ValidationParamErrors = {};
  for (const item in templateParams) {
    const value = projectParams[item];
    if (!value.paramValue.trim() && templateParams[item].isRequired) {
      errors[value.paramName] = templateParams[item].validationErrorMessage;
    }
  }
  return errors;
}


