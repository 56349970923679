import React from "react";
import { NavLink, useLocation, useMatch } from "react-router-dom";
import styles from "./navigationBar.module.scss";
import { ICustomNavLinkProps, IBreadcrumbsProps } from "../../interfaces";
import Icon from "@mdi/react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
} from "@mui/material";
import { mdiChevronLeftCircle, mdiMenu } from "@mdi/js";
import { useDrawer } from "../../utils/DrawerContext";
import { useBannerContext } from "../../utils/BannerContext";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<{ open: boolean }>(({ theme, open }) => ({
  width: open ? drawerWidth : 56,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    width: open ? drawerWidth : 0, // Adjust this width for small screens
  },
  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : 56,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    top: "60px",
    [theme.breakpoints.down("sm")]: {
      width: open ? drawerWidth : 0, // Adjust this width for small screens
    },
  },
}));

const StyledListItemText = styled(ListItemText)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginLeft: "15px",
  "& .MuiListItemText-primary": {
    fontWeight: "700",
    fontSize: "14px",
  },
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "0px",
});

const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0px 3px",
});

const NavigationBar: React.FC<IBreadcrumbsProps> = ({ routes }) => {
  const { drawerOpen, toggleDrawer } = useDrawer();
  const { showBanner } = useBannerContext();

  const toggleDrawerFalse = () => {
    if (drawerOpen) toggleDrawer(false);
  };
  const CustomNavLink: React.FC<ICustomNavLinkProps> = ({
    to,
    children,
    exact = false,
  }) => {
    const match = useMatch({ path: to, end: exact });
    // Check if the current route starts with `/project-details`
    const matchForProjectDetails = useMatch({
      path: "/project-detail*", // Use a wildcard to match any sub-route under `/project-details`
    });

    const matchForVendorDetails = useMatch({
      path: "/add-vendor*", // Use a wildcard to match any sub-route under `/project-details`
    });
    const matchForEditVendorDetails = useMatch({
      path: "/edit-vendor*", // Use a wildcard to match any sub-route under `/project-details`
    });

    // Apply active class if either the current path matches `to` or we are on /projects or /project-details
    const isActive =
      match ||
      (to === "/projects" && (match || matchForProjectDetails)) ||
      (to === "/vendors" &&
        (match || matchForVendorDetails || matchForEditVendorDetails));

    return (
      <NavLink to={to} className={isActive ? styles.active : ""}>
        {children}
      </NavLink>
    );
  };

  const routesElement = React.useMemo(
    () =>
      routes.map(
        (route, index) =>
          route.isShow && (
            <StyledListItem
              key={index}
              onClick={toggleDrawerFalse}
              className={styles.navItem}
            >
              <CustomNavLink to={route.path} exact>
                <Tooltip title={route.menuName} placement="right">
                  <Icon path={route.icon} />
                </Tooltip>
                <StyledListItemText primary={route.menuName} />
              </CustomNavLink>
            </StyledListItem>
          )
      ),
    [routes]
  );

  const location = useLocation();
  const pageName = location.pathname;

  const isvalidNavigationPage = React.useMemo(() => {
    const avoidNavigationPages = [
      "/",
      "/login",
      "/signup",
      "/forgot-password",
      "/reset-password",
      "/roles-permissions",
      "/contact-details",
      "/pricing",
    ];
    return avoidNavigationPages.includes(pageName);
  }, [pageName]);

  // const [drawerOpen, setDrawerOpen] = React.useState(false);
  // const handleDrawerToggle = () => {
  //   setDrawerOpen((prevOpen) => !prevOpen);
  // };

  if (isvalidNavigationPage) return null;
  return (
    <StyledDrawer
      className={styles.nav}
      variant="permanent"
      open={drawerOpen}
      classes={{
        paper: `${showBanner ? styles.showTopBannerNav : " "}`, // Apply the custom class to MuiDrawer-paper
      }}
    >
      <DrawerHeader>
        <IconButton className={styles.drawerOpenButton} onClick={toggleDrawer}>
          <Icon path={drawerOpen ? mdiChevronLeftCircle : mdiMenu} size={1} />
        </IconButton>
      </DrawerHeader>
      <List className={styles.navList}>{routesElement}</List>
    </StyledDrawer>
  );
};

export default NavigationBar;
