import React, { useEffect, useRef, useState } from "react";
import styles from "./addMaterials.module.scss";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Icon from "@mdi/react";
import { mdiCloudUpload } from "@mdi/js";
import { TimePicker } from "@mui/x-date-pickers";
import {
  IAddTaskDrawerProps,
  IAttachmentNotification,
  IProject,
  IVendorandVendorTypes,
  IVendorWithTypeIds,
} from "../../../interfaces";
import { Datatable, DeleteDialog, SMSSwitch, StyledDrawer } from "../../../components";
import { billingService, projectService, settingService, sharedService } from "../../../api";
import {
  IcostCode,
  IMaterialInformation,
} from "../../../interfaces/IMaterialInformation";
import { useLocation } from "react-router-dom";
import ICategoryWithCategoryIds from "../../../interfaces/ICategoryWithCategoryIds";
import IAddContacts from "../../../interfaces/IAddContacts";
import vendorServices from "../../../api/services/VendorService";
import dayjs, { Dayjs } from "dayjs";
import materialService from "../../../api/services/MaterialService";
import { EntityType, LookupTypeId, PaymentMethods } from "../../../enums";
import { decryptData } from "../../../utils/CryptoJS";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { validateMaterial } from "../../../utils/MaterialValidation";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import TaskServices from "../../../api/services/TaskServices";
const AddMaterials: React.FC<IAddTaskDrawerProps> = ({
  open,
  onClose,
  refreshTask,
  material,
}) => {
  const { t } = useTranslation();
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const columns: GridColDef[] = [
    {
      field: "amountPaid",
      headerName: t("AddMaterials.amountPaidColumn"),
      width: 100,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      flex: 0.8,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => <span>$ {params.value} </span>,
    },
    {
      field: "paymentDate",
      headerName: t("AddMaterials.paymentDate"),
      width: 150,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      flex: 0.8,
    },
    {
      field: "paidToCompanyId",
      headerName: t("AddMaterials.paidToCompanyId"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "paymentMethodId",
      headerName: t("AddMaterials.paymentMethodId"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1.3,
    },
    {
      field: "description",
      headerName: t("AddMaterials.description"),
      width: 200,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 2,
    },
    {
      field: "paymentMemo",
      headerName: t("AddMaterials.paymentMemoColumn"),
      width: 100,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      flex: 0.7,
    },
  ];
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = React.useState(0);
  const pageCount = Math.ceil(count / rowsPerPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  const location = useLocation();
  const projectId = location.state?.projectId;
  var customerId = parseInt(sessionStorage.getItem("customerId") as string, 10);
  var userId = localStorage.getItem("userId") as string;
  const [printEnvelope, setPrintEnvelope] = useState(false);
  const [printCheck, setPrintCheck] = useState(false);
  const [includeDigitalSignature, setIncludeDigitalSignature] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const now = new Date();
  const currentDate = `${now.getFullYear()}${String(now.getDate()).padStart(2, '0')}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string; }>({});
  const [units, setUnits] = useState<any[]>([]);
  const [lookupValues, setLookupValues] = useState<any>({});
  const [lookupTypes, setLookupTypes] = useState<any[]>([]);

  const materialObj: IMaterialInformation = {
    customerId: customerId,
    projectId: projectId,
    vendorId: 0,
    createdBy: userId,
    materialName: "",
    description: "",
    budgetId: 0,
    budgetLineItemId: 0,
    materialId: 0,
    amount: 0,
    purchaseDate: null,
    createdDate: null,
    isDeleted: false,
    modifiedBy: "",
    modifiedDate: null,
    costCodeId: "",
    assignedToUserId: "",
    payments: [],
    unitOfMeasure: 0,
    quantity: 0
  };

  const [formDetails, setFormDetails] =
    useState<IMaterialInformation>(materialObj);
  const [selectedVendorTypeId, setSelectedVendorTypeId] = useState<number | "">("");
  const [vendors, setVendors] = useState<IVendorWithTypeIds[]>([]);
  const [vendorTypes, setVendorTypes] = useState<IVendorandVendorTypes[]>([]);
  const [filteredCategory, setfilteredCategory] = useState<ICategoryWithCategoryIds[]>([]);
  const [categoryTypes, setCategoryTypes] = useState<IcostCode[]>([]);
  const [ContactsTypes, setContactsTypes] = useState<IAddContacts[]>([]);
  const [filteredContacts, setfilteredContacts] = useState<IAddContacts[]>([]);
  const [selectedCategoryTypeId, setSelectedCategoryTypeId] = useState<number | string | "">("");
  const [selectedContactId, setSelectedContactId] = useState<string | "">("");
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [addTaskDrawerOpen, setAddTaskDrawerOpen] = React.useState(false);
  const [savingData, setSavingData] = useState(false);
  const [attachmentNotification, setAttachmentNotification] = useState<IAttachmentNotification>();
  var userId = userId;
  const [paymentDetails, setPaymentDetails] = useState({
    checkPaidTo: "",
    customerId: customerId,
    projectId: projectId,
    paidToCompanyId: 0,
    category: "",
    amountPaid: 0,
    paymentMethodId: 1,
    paymentMemo: "",
    description: "",
    taskId: 0,
    materialId: 0,
    equipmentId: 0,
    workOrderId: 0,
    paymentDate: new Date().toISOString(),
    createdBy: userId,
    createdDate: new Date().toISOString(),
    checKNumber: 0,
  });
  const [files, setFiles] = useState<any>([]);
  const [paymentFiles, setPaymentFiles] = useState<any>([]);
  const [errors, setErrors] = useState({
    materialName: false,
    costCodeId: false,
    purchaseDate: false,
    amountPaid: false,
    paymentMemo: false,
    checkPaidTo: false,
    checkNumber: false,
    description: false,
    vendorId: false,
    amount: false
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  // toggle event for drawer
  const handleDrawerToggle = () => {
    setAddTaskDrawerOpen(false);
    setDeleteDialogOpen(false);
    resetForm();
    setErrors({
      materialName: false,
      costCodeId: false,
      purchaseDate: false,
      amountPaid: false,
      paymentMemo: false,
      checkPaidTo: false,
      checkNumber: false,
      description: false,
      vendorId: false,
      amount: false
    });
    setTouchedFields({});
    setValidationErrors({});
    setFormTouched(false);
    if (onClose) {
      onClose();
    }
  };
  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formDetails, paymentDetails, printCheck, printEnvelope]);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  // To handle and push the file in filesArray
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = [
      ".pdf",
      ".jpg",
      ".dwf",
      ".dwg",
      ".tif",
      ".jpeg",
      ".png",
    ];

    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const validFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        const temp: any = [];
        files?.forEach((f: any) => {
          if (temp.filter((q: any) => q.name === f.name).length === 0)
            temp.push(f);
        });

        validFiles?.forEach((f: any) => {
          if (temp.filter((q: any) => q.name === f.name).length === 0)
            temp.push(f);
        });
        setFiles(temp);
      }
    }
  };
  const handlePaymentFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = [
      ".pdf",
      ".jpg",
      ".dwf",
      ".dwg",
      ".tif",
      ".jpeg",
      ".png",
    ];

    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const validFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        const temp: any = [];
        paymentFiles?.forEach((f: any) => {
          if (temp.filter((q: any) => q.name === f.name).length === 0)
            temp.push(f);
        });

        validFiles?.forEach((f: any) => {
          if (temp.filter((q: any) => q.name === f.name).length === 0)
            temp.push(f);
        });
        setPaymentFiles(temp);
      }
    }
  };
  // To remove Files from the uploded file list
  const removeFile = (fileName: string) => {
    const updatedFiles = files.filter((file: any) => file.name !== fileName);
    setFiles(updatedFiles);
  };
  const removePaymentFile = (fileName: string) => {
    const updatedFiles = paymentFiles.filter((file: any) => file.name !== fileName);
    setFiles(updatedFiles);
  };
  const fetchVendorData = async () => {
    try {
      const data = await projectService.vendorsWithVendorTypeIds(customerId);
      if (data) {
        setVendorTypes(data.vendorWithTypeIds);
        setVendors(data.vendorWithTypeIds);
      } else {
        console.error("Failed to fetch vendor type data");
      }
    } catch (error) {
      console.error("Failed to fetch the vendor type data");
    }
  };
  //to get LookupData for status projectype and Unit
  const getLookupData = async () => {
    const data = await projectService.getLookupDataAsync();

    if (data.isSuccess) {
      setLookupTypes(data.lookupType);
      setLookupValues(data.lookupValues);

      const unitTypes = data.lookupValues.filter(
        (value: any) => value.lookupTypeID === LookupTypeId.UnitType
      );
      setUnits(unitTypes);
    }
  };
  const [userRoles, setUserRoles] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCategoryData();
        await fetchVendorData();
        await getLookupData();
        setUserRoles(await sharedService.getUserRolesPermissions());
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    fetchData();
  }, []);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>();
  const fetchContacts = async (vendorId: number) => {
    setContactsTypes([])
    try {
      const response = await vendorServices.getContactsByVendorId(
        vendorId,
        customerId
      );
      setfilteredContacts(response);
      setContactsTypes(response.contacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setfilteredContacts([]);
    }
  };
  const handleVendorTypeChange = (event: SelectChangeEvent<number>) => {
    const selectedVendorId = event.target.value as number;
    setSelectedVendorTypeId(selectedVendorId);
    setFormDetails((prevState) => ({
      ...prevState,
      vendorId: selectedVendorId,
      vendorName: selectedVendorId.toString(),
    }));
    fetchContacts(selectedVendorId);
  };
  const resetForm = () => {
    setFormDetails({
      customerId: customerId,
      projectId: projectId,
      vendorId: 0,
      createdBy: userId,
      materialName: "",
      description: "",
      budgetId: 0,
      budgetLineItemId: 0,
      materialId: 0,
      amount: 0,
      purchaseDate: null,
      createdDate: null,
      isDeleted: false,
      modifiedBy: "",
      modifiedDate: null,
      costCodeId: "",
      assignedToUserId: "",
      payments: [],
      unitOfMeasure: 0,
      quantity: 0
    });
    setSelectedVendorTypeId("");
    setSelectedCategoryTypeId("");
    setSelectedContactId("");
    setErrors({
      materialName: false,
      costCodeId: false,
      purchaseDate: false,
      amountPaid: false,
      paymentMemo: false,
      checkPaidTo: false,
      checkNumber: false,
      description: false,
      vendorId: false,
      amount: false
    });
    setFiles([]);
    setPaymentDetails({
      checkPaidTo: "",
      customerId: customerId,
      projectId: 0,
      paidToCompanyId: 0,
      category: "",
      amountPaid: 0,
      paymentMethodId: 1,
      paymentMemo: "",
      description: "",
      taskId: 0,
      materialId: 0,
      equipmentId: 0,
      workOrderId: 0,
      paymentDate: new Date().toISOString(),
      createdBy: userId,
      createdDate: new Date().toISOString(),
      checKNumber: 0,
    });
    setPaymentToggle(false);
    setSelectedPaymentMethod(null);
  };

  const validateForm = () => {
    const paymentFieldsRequired = printCheck || printEnvelope;
    const paymentFieldsToggleRequired = paymentToggle;
    const newErrors = {
      materialName: formDetails.materialName === "",
      costCodeId: !formDetails.costCodeId,
      purchaseDate: formDetails.purchaseDate === null,
      checkPaidTo: paymentFieldsRequired && !paymentDetails.checkPaidTo,
      checkNumber: paymentFieldsRequired && !paymentDetails.checKNumber,
      paymentMemo: paymentFieldsRequired && !paymentDetails.paymentMemo,
      description: paymentFieldsRequired && !paymentDetails.description,
      amount: (paymentFieldsRequired || paymentFieldsToggleRequired) && !formDetails.amount,
      amountPaid: (paymentFieldsRequired || paymentFieldsToggleRequired) && !paymentDetails.amountPaid,
      vendorId: (paymentFieldsRequired || paymentFieldsToggleRequired) && !formDetails.vendorId,
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some(Boolean);
  };
  //fetching category
  const fetchCategoryData = async () => {
    try {
      const data = await settingService.getCostCodesAsync();
      if (data) {
        setCategoryTypes(data.costCodesList);
        setfilteredCategory(data.costCodesList);
      } else {
        console.error("Failed to fetch vendor type data");
      }
    } catch (error) {
      console.error("Failed to fetch the vendor type data");
    }
  };

  const handleCategoryChange = (
    event: React.SyntheticEvent,
    newValue: {
      costCodeId: string;
      categoryNumber: string;
      categoryName: string;
      costCodeNumber: string;
      costCodeName: string;
    } | null
  ) => {

    const selectedCategoryId = newValue?.costCodeId || "";
    setSelectedCategoryTypeId(selectedCategoryId);
    setFormDetails((prevState) => ({
      ...prevState,
      costCodeId: selectedCategoryId,
    }));

  };

  const handleContactChange = (event: SelectChangeEvent<string>) => {
    const selectedContactId = event.target.value as string;
    setSelectedContactId(selectedContactId);
    setFormDetails((prevState) => ({
      ...prevState,
      assignedToUserId: selectedContactId,
    }));
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value === "",
    }));
  };
  const handleSelectChange = (event: SelectChangeEvent<number>) => {
    const { name, value } = event.target;
    setFormDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (open && !material) {
      resetForm();
    }
  }, [open, material]);
  useEffect(() => {
    (async () => {
      await fetchCategoryData();
      await fetchVendorData();
      await getLookupData();
      setUserRoles(await sharedService.getUserRolesPermissions());
    })();
    if (material) {
      setSelectedCategoryTypeId(material.costCodeId);
      setSelectedContactId(material.createdBy);
      setSelectedVendorTypeId(material.vendorId);
      setFormDetails((prevState) => ({
        ...prevState,
        ...material,
        purchaseDate: material.purchaseDate
          ? dayjs(material.purchaseDate)
          : null,
        projectId: material.projectId,
      }));
    } else {
      resetForm();
    }
  }, [material]);
  useEffect(() => {
    if (material && material.vendorId) {
      fetchContacts(material.vendorId);
    }
  }, [material]);

  const handleSaveAddMaterialData = async () => {
    if (validateForm()) {
      try {
        setSavingData(true);
        const formDetailsWithUserId = {
          ...formDetails,
          userId: userId,
        };
        const errors = validateMaterial(formDetailsWithUserId);
        // Check for errors and exit if any exist
        if (Object.keys(errors).length > 0) {
          setSavingData(false);
          return; // Exit early if there are validation errors
        }

        if (Object.keys(errors).length === 0) {
          try {
            // Convert date to the required format
            const formattedDate = formDetails.purchaseDate
              ? formDetails.purchaseDate.format("YYYY-MM-DD")
              : null;

            const payload = {
              ...formDetails,
              purchaseDate: formattedDate,
              createdDate: new Date(),
              projectId: projectId,
              createdBy: userId,
              entityTypeName: "Material",
              payments: paymentToggle ? [paymentDetails] : [], // Ensure payments format
            };

            const pd = JSON.parse(sessionStorage.getItem("currentProject") || "{}");

            const PaymentPayload = {
              ...paymentDetails,
              materialId: payload.materialId,
              paidToCompanyId: selectedVendorTypeId,
              paymentMethodId: selectedPaymentMethod,
              projectId: projectId,
              projectName: pd.projectName,
              entityName: payload.materialName,
              entityTypeName: "Material",
              budgetAmount: payload.amount,
              payments: paymentToggle ? [paymentDetails] : [],
            };

            if (material) {
              // Update existing task
              var updateMaterialResponse = await materialService.updateMaterial(payload);
            }
            else {
              // Create new task
              var materialResponse = await materialService.createMaterial(payload);
              payload.materialId = materialResponse.materialId;
            }

            // payment API
            if (paymentToggle) {
              const pd = JSON.parse(sessionStorage.getItem("currentProject") || "{}");
              if (Object.keys(errors).length === 0) {
                PaymentPayload.materialId = payload.materialId;
                const paymentresponse = await billingService.Payment(PaymentPayload);
              }
            }

            // Check for print check condition
            if (printCheck) {
              const ApprovedByResponse = await TaskServices.GetAdminDetailsByCustomerId(customerId);
              // Concatenate FirstName and LastName
              const ApprovedBy = `${ApprovedByResponse.firstName} ${ApprovedByResponse.lastName}`;
              const checkPayment = {
                includeDigitalSignature: includeDigitalSignature,
                taskApprovedBy: ApprovedBy,
                payToVendor: vendors.find(x => x.companyId === PaymentPayload.paidToCompanyId)?.companyName
              };
              const printPayload = {
                ...checkPayment,
                materialId: payload.materialId,
                checkMemo: PaymentPayload?.paymentMemo || '',
                description: PaymentPayload?.description || '',
                amount: PaymentPayload?.amountPaid || 0,
                taskHandledBy: ContactsTypes.find(x => x.userId === payload.assignedToUserId)?.firstName + ' '
                  + ContactsTypes.find(x => x.userId === payload.assignedToUserId)?.lastName,
                approveDate: new Date().toISOString().slice(0, 10)
              };
              try {
                const printResponse = await TaskServices.printCheck(printPayload);
                const fileUrl = printResponse.fileUrl;
                const url = window.URL.createObjectURL(new Blob([printResponse]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `${printPayload.payToVendor}_${currentDate}_Check.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                handleClose();
                setPrintCheck(false);
              } catch (error) {
                console.error("Failed to print check:");
              }
            }

            // Check for print envelope condition
            if (printEnvelope) {
              const mailToAddressDetails = await TaskServices.GetAddressByVendorId(Number(PaymentPayload.paidToCompanyId));
              const mailFromAddressDetails = await TaskServices.GetAddressByCustomerId(customerId);
              const mailToAddress = {
                companyName: vendors.find(x => x.companyId === PaymentPayload.paidToCompanyId)?.companyName,
                addressLine1: mailToAddressDetails.addressDetail.addressLine1,
                addressLine2: mailToAddressDetails.addressDetail.addressLine2,
                city: mailToAddressDetails.addressDetail.city,
                state: mailToAddressDetails.addressDetail.stateName,
                postalCode: mailToAddressDetails.addressDetail.postalCode,
              };
              const mailFromAddress = {
                companyName: vendors.find(x => x.companyId === PaymentPayload.paidToCompanyId)?.companyName,
                addressLine1: mailFromAddressDetails.addressDetail.addressLine1,
                addressLine2: mailFromAddressDetails.addressDetail.addressLine2,
                city: mailFromAddressDetails.addressDetail.city,
                state: mailFromAddressDetails.addressDetail.stateName,
                postalCode: mailFromAddressDetails.addressDetail.postalCode,
              };
              const printEnvelopePayload = {
                mailToAddress: mailToAddress,
                mailFromAddress: mailFromAddress
              };

              try {
                const printResponse = await TaskServices.printEnvelope(printEnvelopePayload);
                const fileUrl = printResponse.fileUrl;
                const url = window.URL.createObjectURL(new Blob([printResponse]));
                const a = document.createElement("a");
                a.href = url;
                a.download = `${mailToAddress.addressLine1}_${currentDate}_Envelope.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
                handleClose();
                setPrintEnvelope(false);
              } catch (error) {
                console.error("Failed to print envelope:");
              }
            }

            // Attachment API
            if (paymentFiles.length > 0) {
              var response = await materialService.uploadAttachment(
                payload,
                paymentFiles,
                EntityType.payment.toString()
              );
            }
            if (files.length > 0) {
              var response = await materialService.uploadAttachment(
                payload,
                files,
                EntityType.material.toString()
              );
              const allFileNames = files
                .map((file: File) => file.name)
                .join(", ");
              handleDrawerToggle();
              setSavingData(false);
            }

            handleDrawerToggle();
            setSavingData(false);
            setFiles([]);
            if (refreshTask) refreshTask(true);

          } catch (error: any) {
            console.error("Error:", error);
            setSavingData(false);
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setSavingData(false);
      }

      handleDrawerToggle();
      setSavingData(false);
      setFiles([]);
      if (refreshTask) refreshTask(true);
    }
  };

  const handleDelete = async () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteConfirm = async () => {
    refreshTask(false);
    handleDrawerToggle();
    const response = await materialService.deleteMaterial(material);
    setDeleteDialogOpen(false);
    if (response.isSuccess) {
      handleDrawerToggle();
      refreshTask(true);
    }
  };
  //Code for Payment
  const handlePaymentChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number | string>
  ) => {
    const { name, value } = e.target;
    setPaymentDetails({
      ...paymentDetails,
      [name]: value,
    });
  };

  const paymentMethodOptions = Object.entries(PaymentMethods)
    .filter(([key, value]) => !isNaN(Number(value))) // Filter to keep numeric values
    .map(([key, value]) => (
      <MenuItem key={key} value={value}>
        {key}
      </MenuItem>
    ));
  const handlePaymentMethodChange = (event: SelectChangeEvent<number>) => {
    setSelectedPaymentMethod(Number(event.target.value));
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  // Determine if a field should be disabled based on userTypeId
  const isFieldDisabled = (fieldName: string) => {
    if (userRoles?.add_edit_task) {
      return false;
    }
    return (fieldName === "comments" || fieldName === "attachments") ? ((fieldName === "comments" || fieldName === "attachments") && userRoles?.add_edit_comments_attachments) : true;
  };
  // Determine if the payment toggle should be shown
  const showPaymentToggle = userRoles?.make_payments;

  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    var rows = material?.payments?.map((payment: any) => ({
      id: payment.paymentId,
      paidToCompanyId: vendors.find(x => x.companyId === payment.paidToCompanyId)?.companyName,
      paymentMethodId: PaymentMethods[payment.paymentMethodId],
      paymentMemo: payment.paymentMemo,
      amountPaid: payment.amountPaid,
      description: payment.description,
      paymentDate: payment.paymentDate
        ? dayjs(payment.paymentDate).format("YYYY-MM-DD")
        : null,
    }));
    setRows(rows);

  }, [material]);

  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };

  const handleRowClick = (params: any) => {
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page
  };

  const handlePaymentToggle = async (event: React.KeyboardEvent | React.MouseEvent) => {
    setPaymentToggle(!paymentToggle);
    console.log("event.key", event.type)
    if (event.type === "click") {
      event.type = "Enter"; // will call `onChange`
    }
  };

  // Handle toggle change for digital signature
  const handleToggleChange = (event: any) => {
    setIncludeDigitalSignature(event.target.checked);
  };


  return (
    <StyledDrawer className={styles.rightDrawer} open={open} anchor="right">
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            <h3>{material ? t("AddMaterials.edit_material") : t("AddMaterials.add_material")}</h3>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item container className="fieldWrapper">
              <FormControl
                className="inlineSelect"
                disabled={isFieldDisabled("category")}
              >
                <Autocomplete
                  size="small"
                  id="category-autocomplete"
                  options={categoryTypes}
                  getOptionLabel={(option) =>
                    `${option.categoryNumber}: ${option.categoryName} ${option.costCodeNumber}: ${option.costCodeName}`
                  }
                  value={
                    categoryTypes.find(
                      (category) =>
                        category.costCodeId === selectedCategoryTypeId
                    ) || null
                  } // Full object, not just the ID
                  onChange={(event, newValue) =>
                    handleCategoryChange(event, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("AddMaterials.category")}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Tab") {
                          event.key = "Enter"; // will call `onChange`
                        }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                      onBlur={() => handleBlur('costCodeId')}
                      error={touchedFields.costCodeId && errors.costCodeId}
                      helperText={
                        (touchedFields.costCodeId && errors.costCodeId ? "Category is required" : "")
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item container className="fieldWrapper">
              <TextField
                name="materialName"
                placeholder={t("AddMaterials.material_name")}
                label={t("AddMaterials.material")}
                required
                variant="outlined"
                value={formDetails.materialName}
                onChange={handleChange}
                disabled={isFieldDisabled("materialName")}
                onBlur={() => handleBlur('materialName')}
                error={touchedFields.materialName && errors.materialName}
                helperText={touchedFields.materialName && errors.materialName ? "Material Name is required" : ""}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>
            <Grid item sm={6} container className="fieldWrapper">
              <TextField
                name="quantity"
                placeholder="0"
                label="Quantity"
                variant="outlined"
                value={formDetails.quantity || ""}
                onChange={handleChange}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item sm={6} container className="fieldWrapper">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="unit_placeholder">UOM</InputLabel>
                <Select
                  label="UOM"
                  name="unitOfMeasure"
                  value={formDetails.unitOfMeasure}
                  onChange={handleSelectChange}
                  labelId="unit_placeholder"
                  id="unitOfMeasure"
                  className="selectBox"
                  input={<OutlinedInput label="UOM" />}
                >
                  {units.map((unit) => (
                    <MenuItem key={unit.lookupValueID} value={unit.lookupValueID}>
                      {unit.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item container className="fieldWrapper">
              <FormControl
                className="inlineSelect"
                disabled={isFieldDisabled("vendor")}
                error={touchedFields.vendorId && errors.vendorId}
              >
                <InputLabel id="vendor_placeholder">{t("AddMaterials.vendor")}</InputLabel>
                <Select
                  name="vendor"
                  labelId="vendor_placeholder"
                  id="vendor"
                  placeholder="Vendor"
                  label={t("AddMaterials.vendor")}
                  className="selectBox"
                  onBlur={() => handleBlur('vendorId')}
                  onChange={handleVendorTypeChange}
                  value={selectedVendorTypeId}
                  input={<OutlinedInput notched label={t("AddMaterials.vendor")} />}
                >
                  {vendors.map((vendorType) => (
                    <MenuItem
                      key={vendorType.companyId}
                      value={vendorType.companyId}
                    >
                      {vendorType.companyName}
                    </MenuItem>
                  ))}
                </Select>
                {touchedFields.vendorId && errors.vendorId && (
                  <FormHelperText>Vendor is required</FormHelperText> // Display helper text
                )}
              </FormControl>
            </Grid>
            <Grid item container className="fieldWrapper">
              <FormControl
                className="inlineSelect"
                disabled={isFieldDisabled("contactPerson")}
              >
                <InputLabel id="contactPerson">{t("AddMaterials.contactPerson")}</InputLabel>
                <Select
                  name="contactPerson"
                  labelId="contactPerson"
                  id="status"
                  placeholder={t("AddMaterials.contactPerson")}
                  label={t("AddMaterials.contactPerson")}
                  className="selectBox"
                  onChange={handleContactChange}
                  onBlur={() => handleBlur('assignedToUserId')}
                  value={formDetails.assignedToUserId || ""}
                  input={<OutlinedInput notched label="Contact Person" />}
                >
                  {ContactsTypes.length > 0 ? (
                    ContactsTypes.map((contacts) => (
                      <MenuItem key={contacts.userId} value={contacts.userId}>
                        {contacts.firstName} {contacts.lastName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No contacts available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item sm={6} container className="fieldWrapper">
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="purchaseDate"
                    label={t("AddMaterials.purchaseDate")}
                    value={formDetails.purchaseDate}
                    onChange={(newValue: Dayjs | null) => {
                      setFormDetails((prevState) => ({
                        ...prevState,
                        purchaseDate: newValue,
                      }));
                    }}
                    disabled={isFieldDisabled("purchaseDate")}
                    onAccept={() => handleBlur('purchaseDate')}  // Trigger touch handling when the user selects a date
                    onClose={() => handleBlur('purchaseDate')}
                    slotProps={{
                      textField: {
                        required: true,
                        error: touchedFields.purchaseDate && errors.purchaseDate,
                        helperText: (touchedFields.purchaseDate && errors.purchaseDate ? "Purchase Date is required" : ""),
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item sm={6} container className="fieldWrapper">
              <TextField
                name="amount"
                placeholder="0.00"
                label={t("AddMaterials.amount")}
                variant="outlined"
                value={formDetails.amount || ""}
                onChange={handleChange}
                onBlur={() => handleBlur('amount')}
                error={touchedFields.amount && errors.amount}
                helperText={touchedFields.amount && errors.amount ? "Amount is required" : ""}
                InputProps={{
                  type: "number",
                  sx: {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  disabled: isFieldDisabled("amount"),
                }}
              />
            </Grid>
            {/* <Grid item container className="fieldWrapper">
              <label htmlFor="name">Credit Card used:</label>
              <Select
                labelId="status_placeholder"
                id="status"
                placeholder="Status"
                className="selectBox"
                sx={{ width: "36% !important" }}
                value={1}
              >
                 <MenuItem value={1}>Visa</MenuItem>
        <MenuItem value={2}>MasterCard</MenuItem>
        <MenuItem value={3}>American Express</MenuItem>
              </Select>
            </Grid> */}
            <Grid item container className="fieldWrapper">
              <TextField
                name="description"
                id="note"
                placeholder={t("AddMaterials.comments")}
                label={t("AddMaterials.comments")}
                multiline
                rows={4}
                value={formDetails.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              container
              className="fieldWrapper"
              alignItems={"flex-start"}
            >
              <label>{t("AddMaterials.attachments_lable")}:</label>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                aria-placeholder="Click here to upload"
                endIcon={
                  <Icon
                    className="uploadButtonIcon"
                    path={mdiCloudUpload}
                    size={1}
                  />
                }
                className="uploadButton"
              >
                {t("AddMaterials.upload_btn")}
                <input
                  type="file"
                  multiple
                  accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                  className="d_none"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid item container className="fieldWrapper fileListContainer">
              {files?.map((file: any, index: number) => (
                <div key={index} className="fileItem">
                  <span>{file.name}</span>
                  <svg
                    className="cancelIcon"
                    onClick={() => removeFile(file.name)}
                  >
                    <HighlightOffIcon />
                  </svg>
                </div>
              ))}
            </Grid>
          </Grid>
          {showPaymentToggle && !isFieldDisabled("payment") ? (<>
            <Grid
              item
              xs={12}
              className=" fieldWrapper"
              gap={3}
              alignItems="center"
            >
              <h4>{t("AddMaterials.payment_title")}</h4>
              <Button
                fullWidth
                variant="contained"
                className="primary-button widthauto"
                onClick={handlePaymentToggle}
              >
                {t("AddMaterials.add_payment_btn")}
              </Button>
            </Grid>

            {paymentToggle ? (
              <>
                <Grid item container className="fieldWrapper">
                  <FormControl className="inlineSelect">
                    <InputLabel id="paidby-select">Payment Method</InputLabel>
                    <Select
                      name="paidBy"
                      labelId="paidby-select"
                      placeholder={t("AddMaterials.paidBy")}
                      label={t("AddMaterials.paidBy")}
                      className="selectBox"
                      value={selectedPaymentMethod || ""}
                      onChange={handlePaymentMethodChange}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        {t("AddMaterials.selectPaymentType")}
                      </MenuItem>
                      {paymentMethodOptions}
                    </Select>
                  </FormControl>
                </Grid>
                {selectedPaymentMethod === PaymentMethods.BankAccount
                  || selectedPaymentMethod === PaymentMethods.CreditCard
                  || selectedPaymentMethod === PaymentMethods.ACH ? (
                  <>
                    <Grid item container spacing={2} className="fieldWrapper">
                      {selectedPaymentMethod === PaymentMethods.BankAccount
                        || selectedPaymentMethod === PaymentMethods.CreditCard
                        || selectedPaymentMethod === PaymentMethods.ACH ? (
                        <>
                          <Grid item xs={6}>
                            <TextField
                              name="checkPaidTo"
                              placeholder={t("AddMaterials.checkPaidTo")}
                              label={t("AddMaterials.checkPaidTo")}
                              variant="outlined"
                              value={paymentDetails.checkPaidTo || ""}
                              onChange={handlePaymentChange}
                              onBlur={() => handleBlur('checkPaidTo')}
                              error={touchedFields.checkPaidTo && errors.checkPaidTo}
                              helperText={touchedFields.checkPaidTo && errors.checkPaidTo ? "Paid to is required" : ""}
                              inputProps={{ maxLength: 55 }}
                            />
                          </Grid>
                        </>) : null}
                      <Grid item xs={6}>
                        <TextField
                          name="amountPaid"
                          placeholder={t("AddMaterials.amountPaid")}
                          label={t("AddMaterials.amountPaid")}
                          variant="outlined"
                          // sx={{ width: "36% !important" }}
                          value={paymentDetails.amountPaid || ""}
                          onChange={handlePaymentChange}
                          onBlur={() => handleBlur('amountPaid')}
                          error={touchedFields.amountPaid && errors.amountPaid}
                          helperText={touchedFields.amountPaid && errors.amountPaid ? "Amount Paid is required" : ""}
                          inputProps={{ maxLength: 20 }}
                          InputProps={{
                            type: "number",
                            sx: {
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                              {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                            },
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>) : null}
                {selectedPaymentMethod === PaymentMethods.BankAccount
                  ? (
                    <>
                      <Grid item container spacing={2} className="fieldWrapper">
                        <Grid item xs={6}>
                          <TextField
                            name="paymentMemo"
                            placeholder={t("AddMaterials.paymentMemo")}
                            label={t("AddMaterials.paymentMemo")}
                            variant="outlined"
                            value={paymentDetails.paymentMemo || ""}
                            onChange={handlePaymentChange}
                            onBlur={() => handleBlur('paymentMemo')}
                            error={touchedFields.paymentMemo && errors.paymentMemo}
                            helperText={touchedFields.paymentMemo && errors.paymentMemo ? "Payment memo is required" : ""}
                            inputProps={{ maxLength: 55 }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            name="checKNumber"
                            placeholder={t("AddMaterials.checkNumber")}
                            label={t("AddMaterials.checkNumber")}
                            variant="outlined"
                            value={paymentDetails.checKNumber || ""}
                            onChange={handlePaymentChange}
                            onBlur={() => handleBlur('checkNumber')}
                            error={touchedFields.checkNumber && errors.checkNumber}
                            helperText={touchedFields.checkNumber && errors.checkNumber ? "Check number is required" : ""}
                            inputProps={{ maxLength: 20 }}
                          />
                        </Grid>
                      </Grid>
                    </>) : null}
                {selectedPaymentMethod === PaymentMethods.BankAccount
                  || selectedPaymentMethod === PaymentMethods.CreditCard || selectedPaymentMethod === PaymentMethods.ACH ? (
                  <>
                    <Grid item container className="fieldWrapper">
                      <TextField
                        name="description"
                        id="comment"
                        placeholder={t("AddMaterials.comment")}
                        label={t("AddMaterials.comment")}
                        multiline
                        rows={4}
                        value={paymentDetails.description || ""}
                        onChange={handlePaymentChange}
                        onBlur={() => handleBlur('description')}
                        error={touchedFields.description && errors.description}
                        helperText={touchedFields.description && errors.description ? "Final payment comment is required" : ""}
                        inputProps={{ maxLength: 255 }}
                      />
                    </Grid>

                    <Grid
                      item
                      container
                      className="fieldWrapper"
                      alignItems={"flex-start"}
                    >
                      <label>{t("AddMaterials.attachments_lable")}:</label>
                      <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        aria-placeholder="Click here to upload"
                        endIcon={
                          <Icon
                            className="uploadButtonIcon"
                            path={mdiCloudUpload}
                            size={1}
                          />
                        }
                        className="uploadButton"
                      >
                        {t("AddMaterials.upload_btn")}
                        <input
                          type="file"
                          multiple
                          accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                          className="d_none"
                          onChange={handlePaymentFileChange}
                        />
                      </Button>
                    </Grid>
                    <Grid item container className="fieldWrapper fileListContainer">
                      {paymentFiles?.map((file: any, index: number) => (
                        <div key={index} className="fileItem">
                          <span>{file.name}</span>
                          <svg
                            className="cancelIcon"
                            onClick={() => removePaymentFile(file.name)}
                          >
                            <HighlightOffIcon />
                          </svg>
                        </div>
                      ))}
                    </Grid>
                  </>) : null}
                {selectedPaymentMethod === PaymentMethods.BankAccount
                  ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        className="fieldWrapper"
                        gap={3}
                        alignItems="center"
                      >
                        <label htmlFor="name"> {t("AddMaterials.print_envelope_label")}:</label>
                        <SMSSwitch
                          checked={printEnvelope}
                          onChange={(e) => setPrintEnvelope(e.target.checked)}
                          inputProps={{ "aria-label": "recieve sms", readOnly: true }}
                        />
                      </Grid>
                      <Grid
                        item
                        className="fieldWrapper"
                        xs={6}
                        gap={3}
                        alignItems="center"
                      >
                        <label htmlFor="name"> {t("AddMaterials.print_check_label")}:</label>
                        <SMSSwitch
                          checked={printCheck}
                          onChange={(e) => setPrintCheck(e.target.checked)}
                          inputProps={{ "aria-label": "recieve sms", readOnly: true }}
                        />
                      </Grid>
                      <Grid
                        item
                        className=" fieldWrapper"
                        xs={6}
                        gap={3}
                        alignItems="center"
                      >
                        <label htmlFor="name"> {t("AddMaterials.include_sign")}:</label>
                        <SMSSwitch
                          checked={includeDigitalSignature}
                          onChange={handleToggleChange}
                          inputProps={{ "aria-label": "recieve sms", readOnly: true }}
                          disabled={!printCheck}
                        />
                      </Grid>
                    </>) : null}
              </>
            ) : null}

            {rows?.length > 0 &&
              <Grid
                item
                xs={12}
                className=" fieldWrapper"
                gap={3}
                alignItems="center"
              >
                <Datatable
                  rows={rows}
                  columns={columns}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  pagination
                  paginationMode="server"
                  onPageChange={handlePageChange}
                  rowCount={count}
                  loading={loading}
                  error={error}
                  onRowClick={handleRowClick}
                  onPageSizeChange={handlePageSizeChange}
                  autoHeight={true}
                  getRowHeight="auto"
                />
              </Grid>}
          </>) : null}
        </Grid>
        <Grid
          container
          item
          sm={12}
          sx={{ justifyContent: "space-between", gap: 0 }}
          className={styles.submitButtons + " submitButtons"}
        >
          <Grid item sm={4}>
            {material ? (
              <Button
                fullWidth
                variant="outlined"
                className="primary-button red-button widthauto"
                onClick={handleDelete}
              >
                {t("AddMaterials.delete_btn")}
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            sm={8}
            className="submitButtons"
            sx={{ mt: "0 !important" }}
          >
            <Button
              fullWidth
              variant="outlined"
              className="outline-button widthauto"
              onClick={handleDrawerToggle}
            >
              {t("AddMaterials.cancel_btn")}
            </Button>

            <Button
              fullWidth
              variant="contained"
              className="primary-button widthauto"
              onClick={handleSaveAddMaterialData}
              disabled={!isFormValid || savingData}
              startIcon={
                savingData ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {savingData ? t("AddMaterials.saving_btn") : t("AddMaterials.save_btn")}{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={deleteDialogTitle}
          cancelbuttonText={dialogCancelButtonText}
          submitButtonText={dialogDeleteButtonText}
          cancelbuttonLink={handleDeleteCancel}
          submitButtonLink={handleDeleteConfirm}
        />
      )}
    </StyledDrawer>
  );
};

export default AddMaterials;
