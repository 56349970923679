import { IContactDetails, IForgotPassword, ISignin, IUser } from "../../interfaces";
import { IEmailCheckResponse } from "../../interfaces";
import UserAxios from "../UserAxios";
import { IProject } from "../../interfaces";

const signUp = async (userDetails: any) => {
  const response = await UserAxios.post("User", userDetails);
  return response.data;
};

const validateEmail = async (email: string) => {
  const response = await UserAxios.post(`User/VerifyEmail/${email}`);
  return response.data;
};

const emailVerify = async (email: string, isLanguage: number): Promise<IEmailCheckResponse> => {
  const response = await UserAxios.post<IEmailCheckResponse>(
    `User/confirmemail?email=${encodeURIComponent(email)}&islanguage=${isLanguage}`
  );
  return response.data;
};

const login = async (SigninObj: ISignin) => {
  const response = await UserAxios.post(`User/login`, SigninObj);
  return response.data;
};


const getUserDetails = async (email: string) => {
  const response = await UserAxios.get(`User/getuserdetail?email=${encodeURIComponent(email)}`);
  return response.data;
};

const resetPassword = async (passwordObj: IForgotPassword) => {
  const response = await UserAxios.put(`User/reset-password`, passwordObj)
  return response.data;
}

const forgotPasswordLink = async (email: string, isLanguage: Number) => {
  const response = await UserAxios.post(`User/forgotPasswordLink?email=${encodeURIComponent(email)}&language=${isLanguage}`)
  return response.data;
}

const getDashboardData = async (userId: string) => {
  const response = await UserAxios.get(`Dashboard?userId=${userId}`)
  return response.data;
}

const getUserTypes = async () => {
  const response = await UserAxios.get(`User/getUserTypes`)
  return response.data;
}

const createUser = async (userDetails: IUser) => {
  const response = await UserAxios.post("User/CreateUser", userDetails);
  return response.data;

};

const updateUser = async (userDetails: IUser) => {
  const response = await UserAxios.post("User/updateUser", userDetails);
  return response.data;

};

const UpdateVendorContact = async (userDetails: IContactDetails) => {
  const response = await UserAxios.post("Vendors/UpdateVendorContact", userDetails);
  return response.data;
};
const getUserByCustomerId = async (customerId: number, searchText: any) => {

  const response = await UserAxios.post(`User/getUserByCustomerId/${customerId}`, searchText);
  return response.data;
};
const deleteUser = async (userId: string, customerId: number) => {
  const response = await UserAxios.delete(`User?userId=${userId}&customerId=${customerId}`);
  return response.data;
}

const userService = { signUp, emailVerify, validateEmail, login, getUserDetails, resetPassword, forgotPasswordLink, getDashboardData, getUserTypes, createUser, getUserByCustomerId, updateUser, deleteUser, UpdateVendorContact };

export default userService;

