import dayjs from "dayjs";
import { IVendor } from "../interfaces/IVendor";
import { ILot } from "../interfaces/ILot";

interface ValidationErrors {
  [key: string]: string;
}

export const validateLot = (lot: ILot): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!lot.lotNumber.trim()) {
    errors.lotNumber = "Lot Number is required.";
  }

  if (!lot.lotArea) {
    errors.lotArea = "Lot Area is required.";
  }
  if (!lot.address.address1.trim()) {
    errors.address1 = "Address Line 1 is required.";
  }
  if (!lot.address.city.trim()) {
    errors.city = "City is required.";
  }
  if (!lot.address.zipCode.trim()) {
    errors.zipCode = "Zip code is required.";
  }
  if (!lot.address.stateId) {
    errors.stateId = "State is required.";
  }
  if (!lot.address.county.trim()) {
    errors.city = "County is required.";
  }
  return errors;
};
