import React, { useEffect, useState } from "react";
import styles from "./notificationDialog.module.scss";
import styled from "@emotion/styled";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { INotificationDialogProps } from "../../interfaces";
import { BillingInformation } from "../../containers";
import { useNavigate } from "react-router-dom";

const NotificationDialog: React.FC<INotificationDialogProps> = ({
  title,
  content,
  buttonLink,
  buttonText,
}) => {
  const NotificationDialogContainer = styled(Dialog)({
    "& .MuiPaper-root": {
      maxWidth: "400px",
      borderRadius: "14px",
      padding: "25px 30px",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      alignContent: "center",
      alignItem: "center",
      textAlign: "center",
    },
    "& .MuiDialogTitle-root": {
      padding: "0px",
    },
    "& .MuiDialogContent-root": {
      paddingBottom: "10px",
    },
    "& .MuiDialogActions-root": {},
  });

  const [nofiticationDialogOpen, setNofiticationDialogOpen] =
    React.useState(false);
  const [openBilling, setOpenBilling] = React.useState(false);

  const onClickNotificationDialogOpen = () => {
    setNofiticationDialogOpen(true);
  };
  const onClickBillingDialogClose = () => {
    setOpenBilling(false);
  };
  const onClickBillingDialogOpen = () => {
    setOpenBilling(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setNofiticationDialogOpen(true); // Set open state to true on component mount
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const onClickNotificationDialogClose = () => {
    setNofiticationDialogOpen(false);
  };
  const onSubmitNotificationDialog = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    onClickNotificationDialogClose(); // Close notification dialog
    navigate("/company-setting", { state: { tab: 1 } });
  };
  return (
    <NotificationDialogContainer
      open={nofiticationDialogOpen}
      className={styles.notification_dialog}
      PaperProps={{
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          onClickNotificationDialogClose();
        },
      }}
    >
      <DialogTitle>
        <img src="./assets/new_logo_teal.svg" />
      </DialogTitle>
      <DialogContent>
        <h4>{title}</h4>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          className="primary-button"
          onClick={onSubmitNotificationDialog}
        >
          {buttonText}
        </Button>
      </DialogActions>
      {/* <BillingInformation
        open={openBilling}
        onClose={onClickBillingDialogClose}
      /> */}
    </NotificationDialogContainer>
  );
};

export default NotificationDialog;
