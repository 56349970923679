import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useModal } from '../utils/ModalContext';

const ProtectedRoute = ({ children }: any) => {
  const { openModal}:any = useModal();
  const token = localStorage.getItem("token");
  return (
    !token ? <Navigate to="/login" /> : (ValidateJwtExpiration(token) ? openModal("Your session is expired. Please Sign In again.") : children)
  );
};

const ValidateJwtExpiration = (token: string) => {
  var jwtData = jwtDecode(token);
  const currentTime = Math.floor(new Date().getTime() / 1000);
  const tokenExpirationTime = jwtData?.exp ?? 0;
  return (tokenExpirationTime && (tokenExpirationTime < currentTime)) ? true : false ;
}
export default ProtectedRoute;
