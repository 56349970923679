import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./resetPassword.module.scss";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiEyeOffOutline, mdiEyeOutline } from "@mdi/js";
import { userService } from "../../api"; // Import your userService here
import { validateResetPasswordForm } from "../../utils/validation";
import { IForgotPassword } from "../../interfaces";
import { decryptVerifyEmail } from "../../utils/CryptoJS";
import { useTranslation } from "react-i18next";

const ResetPassword: React.FC = () => {
  const formObj: IForgotPassword = {
    email: "",
    newPassword: "",
    confirmPassword: "",
  };
  const {t}=useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const emailFromParams = params.get("eid") || "";
  let decryptedEmail = "";
  if (emailFromParams) {
    decryptedEmail = decryptVerifyEmail(emailFromParams);
  }
  const [errors, setErrors] = useState<Partial<IForgotPassword>>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formDetails, setFormDetails] = useState({
    ...formObj,
    email: decryptedEmail,
  });
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => event.preventDefault();

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => event.preventDefault();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormDetails({ ...formDetails, newPassword: event.target.value });
    setPasswordError(null);
    setIsDisabled(false);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormDetails({ ...formDetails, confirmPassword: event.target.value });
    setPasswordError(null);
    setIsDisabled(false);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleBlur = (fieldName: keyof IForgotPassword) => {
    const newErrors = validateResetPasswordForm({
      ...formDetails,
      [fieldName]: formDetails[fieldName],
    },t);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsDisabled(true);
    const validationErrors = validateResetPasswordForm(formDetails,t);
    setErrors(validationErrors);
    if (!errors.confirmPassword && !errors.newPassword) {
      try {
        const response = await userService.resetPassword(formDetails);
        if (response.isSuccess) {
          setSnackbarMessage("Password reset successfully");
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          setIsDisabled(false);
          setPasswordError("Failed to reset password");
        }
      } catch (error: any) {
        setIsDisabled(false);
        setPasswordError("Failed to reset password");
      }
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.login_left}>
        <div className={styles.login_left_content}>
          <img
            src={process.env.PUBLIC_URL + "/assets/logo_white.svg"}
            width="175px"
            alt="Logo"
          />
          <h2>Welcome Back to CPro360</h2>
          <p>A comprehensive project management software</p>
          <Button className={styles.white_button} sx={{ padding: "8px 30px" }}>
            Read More
          </Button>
        </div>
      </div>
      <div className={styles.login_right}>
        <div className={styles.login_right_content}>
          <h3>Reset Your Password</h3>
          <p>Enter a new password for {formDetails.email}</p>
          {errors.newPassword ? (
            <p className={styles.errorText}>{errors.newPassword}</p>
          ) : errors.confirmPassword ? (
            <p className={styles.errorText}>{errors.confirmPassword}</p>
          ) : (
            passwordError && <p className={styles.errorText}>{passwordError}</p>
          )}

          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleSubmit}
          >
            <FormControl className="fieldWrapper">
              <TextField
                margin="normal"
                required
                fullWidth
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="New Password"
                label="New Password"
                name="password"
                className={styles.textField}
                autoFocus
                value={formDetails.newPassword}
                onBlur={() => handleBlur("newPassword")}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Icon path={mdiEyeOffOutline} size={1} />
                        ) : (
                          <Icon path={mdiEyeOutline} size={1} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl className="fieldWrapper">
              <TextField
                margin="normal"
                required
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm Password"
                label="Confirm Password"
                name="confirmPassword"
                className={styles.textField}
                value={formDetails.confirmPassword}
                onBlur={() => handleBlur("confirmPassword")}
                onChange={handleConfirmPasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <Icon path={mdiEyeOffOutline} size={1} />
                        ) : (
                          <Icon path={mdiEyeOutline} size={1} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isDisabled}
              sx={{
                mt: 3,
                mb: 4,
                fontFamily: "Nunito Sans",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                borderRadius: "4px",
                background: "var(--primary)",
                padding: "18px 26px !important",
                boxShadow: "none !important",
              }}
            >
              Reset Password
            </Button>
          </Box>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default ResetPassword;
