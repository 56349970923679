import styles from "./customLoader.module.scss";

export const CustomLoader: React.FC = () => {
  return (
    <div className={styles.loader_container}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default CustomLoader;
