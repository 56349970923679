import { IUser } from "../interfaces";

interface ValidationErrors {
  [key: string]: string;
}

// Regular expressions for validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9\s-]{3,20}$/;

export const ValidateUser = (user: IUser): ValidationErrors => {
  const errors: ValidationErrors = {};
   // Check for required fields
   if (!user.firstName.trim()) {
    errors.firstName = "First name is required.";
  }
  if (!user.lastName.trim()) {
    errors.lastName = "Last name is required.";
  }
  if (!user.contactNo.trim()) {
    errors.contactNo = "Primary Phone is required.";
  } else if (!phoneRegex.test(user.contactNo)) {
    errors.contactNo = "Primary Phone is not a valid phone number.";
  }
  if (!user.email.trim()) {
    errors.email = "Email is required.";
  } else if (!emailRegex.test(user.email)) {
    errors.email = "Email is not a valid email address.";
  }
  if (!user.projectList) {
    errors.projectList = "Access To projects is required.";
  }
  if (!user.userTypeId) {
    errors.userTypeId = "User Role is required.";
  }
  return errors;
};
