import React, { useEffect, useState } from "react";
import styles from "./schedule.module.scss";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { ITabPanelProps } from "../../interfaces";
import {
  ScheduleCalendar,
  ScheduleChart,
  ScheduleList,
} from "../../containers";
import Icon from "@mdi/react";
import {
  mdiAccountMultiple,
  mdiCalendarMonth,
  mdiChartGantt,
  mdiClose,
  mdiCloseThick,
  mdiCross,
  mdiFormatListCheckbox,
  mdiMagnify,
} from "@mdi/js";
import { useLocation } from "react-router-dom";
import { scheduleService } from "../../api";
import IContact from "../../interfaces/IContact";
import { useTranslation } from "react-i18next";

function TabPanel(props: ITabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      className={styles.tabpanel_container}
    >
      {value === index && (
        <Box component="div" sx={{ mt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Schedule: React.FC = () => {
  const CustomTabs = styled(Tabs)({
    "& .MuiTabs-flexContainer": {
      borderBottom: "0.4px solid rgb(0, 0, 0)",
    },
    "& .MuiTabs-indicator": {
      height: "5px",
      marginBottom: "-1px",
      borderRadius: "10px 10px 0px 0px",
      backgroundColor: "var(--primary)",
    },
    ".MuiButtonBase-root": {
      borderRadius: "10px 10px 0px 0px",
      padding: "0px 20px",
      minHeight: "35px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(24, 20, 243, 0.05) !important",
      color: "#000 !important",
    },
  });
  const ToggleButtonGroupStyled = styled(ToggleButtonGroup)({
    "& .MuiToggleButton-root": {
      color: "#000",
      gap: "8px",
      "&.Mui-selected": {
        color: "#FFF",
        backgroundColor: "var(--primary)",
      },
    },
  });
  //   const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  //       padding: "13.5px 14px",
  //       color: "#000",
  //       gap: "8px",
  //       "&.Mui-selected": {
  //         color: "#FFF",
  //         backgroundColor: "#1814F3",
  //       },
  //     },
  // `;

  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const projectId = location.state?.projectId;
  const {t}=useTranslation();
  //Handling the change of data for Tabs
  const handleChange = (evet: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [selectValue, setselectValue] = React.useState("chart_view");
  // Handle changes for select dropdowns
  const handleSelectChange = (event: SelectChangeEvent) => {
    setselectValue(event.target.value as string);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectSearch) {
      setSelectSearch("");
    }
    setSearchKey(event.target.value);
  };

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newScheduleView: string
  ) => {
    setselectValue(newScheduleView);
  };
  useEffect(() => {
    sessionStorage.setItem("ActiveTabIndex", JSON.stringify(2));
  });
  const [searchKey, setSearchKey] = useState<string>("");
  const [contact, setContact] = useState<IContact[]>([]);
  const [scheduleDate, setScheduleDate] = useState<any>();
  //const [selectedDate, setselectedDate] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [assigneName, setAssigneeName] = useState<string | null>(null);
  const [selectField, setSelectField] = useState(false);
  const [selectSearch, setSelectSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState(null);

  // const handleSelectChangeDate = (event: SelectChangeEvent) => {
  //   setSearchKey(event.target.value as string);
  //   setselectedDate(event.target.value as string);
  // };

  const handleSelectChangeDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (value === "today") {
      const today = new Date().toISOString().split("T")[0]; // Format as YYYY-MM-DD
      setSelectedDate(today);
      setSelectField(true);
      setSelectSearch(today);
    } else {
      setSelectedDate(value);
      setSelectField(true);
      setSelectSearch(value);
    }
  };
  const handleAssigneeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedId = event.target.value;
    const selectedOption = contact.find(
      (option: IContact) => option.id === selectedId
    );
    if (selectedOption) {
      setAssigneeName(selectedOption.email); // Set the email in assigneeName
      setSelectSearch(selectedOption.email);
      setSelectField(true);
    }
  };

  const handleDataFromChild = (childData: IContact[]) => {
    setContact(childData); // Update state with the data from the child
  };

  const handleDataScheduleDate = (childData: string) => {
    setScheduleDate(childData);
  };
  const handleFilterByAssignee = (childData: string) => {
    setAssigneeName(childData);
  };

  //handle Export
  const handleExport = async (docTypeId: string) => {
    setError(null);
    try {
      const data = await scheduleService.getExcelOrPDFExport(
        projectId,
        "schedule",
        docTypeId
      );

      // Check if data is null or empty
      if (!data || data.byteLength === 0) {
        setSnackbarMessage("No data available for export.");
        setOpenSnackbar(true);
        return;
      }

      // Proceed with the export
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      if (docTypeId == "1") {
        a.download = "Schedules.pdf";
      } else {
        a.download = "Schedules.xlsx";
      }
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      handleClose();
    } catch (err: any) {
      setSnackbarMessage("An error occurred during export.");
      setError(err.message);
      setOpenSnackbar(true);
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container className={styles.Gridcontainer}>
      <Grid container className={styles.whiteBox} alignItems="flex-start">
        <Grid
          container
          className={styles.search_container}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid
            container
            item
            sm={9}
            className={styles.chip_container}
            alignItems="center"
            justifyContent="flex-start"
          >
            <ToggleButtonGroupStyled
              className={styles.toggle_button_group}
              value={selectValue}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
              size="small"
            >
              <ToggleButton value="chart_view" id="chart_view">
                <Icon path={mdiChartGantt} size={1} /> {t("Schedule.chart_view")}
              </ToggleButton>
              <ToggleButton value="list_view" id="list_view">
                <Icon path={mdiFormatListCheckbox} size={1} /> {t("Schedule.list_view")}
              </ToggleButton>
              <ToggleButton value="calendar_view" id="calendar_view">
                <Icon path={mdiCalendarMonth} size={1} /> {t("Schedule.calendar_view")}
              </ToggleButton>
            </ToggleButtonGroupStyled>
            <FormControl
              className="fieldWrapper width20"
              sx={{
                "@media screen and (max-width:768px)": {
                  width: "47% !important",
                },
              }}
            >
              <TextField
                id="search"
                placeholder={t("Schedule.search")}
                label={t("Schedule.search")}
                variant="outlined"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchKey ? (
                        <IconButton
                          aria-label="clear search"
                          onClick={() => setSearchKey("")} // Clear the search key
                        >
                          <Icon path={mdiClose} size={0.8} />
                        </IconButton>
                      ) : (
                        <Icon path={mdiMagnify} size={1} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {selectValue === "list_view" && (
              <FormControl
                className="fieldWrapper width20"
                sx={{
                  "@media screen and (max-width:768px)": {
                    width: "47% !important",
                  },
                }}
              >
                <TextField
                  select
                  placeholder="Assignee"
                  variant="outlined"
                  label={t("Schedule.assignee_label")}
                  value={
                    contact.find((option) => option.email === assigneName)
                      ?.id || ""
                  }
                  onChange={handleAssigneeChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon path={mdiAccountMultiple} size={1} />
                      </InputAdornment>
                    ),
                    endAdornment: assigneName && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setAssigneeName(""); // Clear assignee name state
                          setSelectSearch("");
                          // setValue("");       // Clear TextField value
                        }}
                      >
                        <Icon path={mdiClose} size={0.8} />
                      </IconButton>
                    ),
                  }}
                  sx={{
                    "@media screen and (max-width:768px)": {
                      width: "47% !important",
                    },
                  }}
                >
                  <MenuItem disabled key={0} value={0}>
                    Assignee
                  </MenuItem>
                  {contact?.map((option: IContact) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firstName} {option.lastName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          </Grid>
          {selectValue === "list_view" && (
            <Grid
              className={styles.chip_container}
              container
              item
              sm={3}
              alignItems="center"
              justifyContent="flex-end"
              sx={{
                "@media screen and (max-width:768px)": {
                  mt: "16px !important",
                },
              }}
            >
              <FormControl
                //className="width30"
                sx={{
                  width: "50% !important",
                  "@media screen and (max-width:768px)": {
                    width: "47% !important",
                  },
                }}
              >
                <InputLabel id="type_placeholder">Date</InputLabel>
                <TextField
                  select
                  value={selectedDate || " "}
                  className="selectBox"
                  label={t("Schedule.date")}
                  //inputProps={{ "aria-label": "Without label" }}
                  onChange={handleSelectChangeDate}
                  InputProps={{
                    endAdornment: selectedDate && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setSelectedDate("");
                          setSelectField(false);
                          setSelectSearch("");
                        }}
                      >
                        <Icon path={mdiClose} size={0.8} />
                      </IconButton>
                    ),
                  }}
                >
                  <MenuItem value="today">Today</MenuItem>
                  {scheduleDate?.map((option: any) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <Button
                variant="contained"
                className="primary-button"
                onClick={handleClick}
                sx={{
                  "@media screen and (max-width:768px)": {
                    width: "47% !important",
                  },
                }}
              >
                {t("Schedule.export_btn")}{" "}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleExport("1")}>
                  Export PDF
                </MenuItem>
                <MenuItem onClick={() => handleExport("2")}>
                  Export Excel
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={styles.whiteBoxInner}>
          {selectValue === "chart_view" && (
            <ScheduleChart
              onSendData={handleDataFromChild}
              onSendScheduleDate={handleDataScheduleDate}
              searchKey={searchKey.length < 3 ? "" : searchKey}
            ></ScheduleChart>
          )}
          {selectValue === "list_view" && (
            <ScheduleList
              onSendData={handleDataFromChild}
              onSendScheduleDate={handleDataScheduleDate}
              onSendAssigneeDetails={handleFilterByAssignee}
              searchKey={searchKey.length < 3 ? "" : searchKey}
              selectKey={selectSearch}
            ></ScheduleList>
          )}
          {selectValue === "calendar_view" && (
            <ScheduleCalendar
              onSendData={handleDataFromChild}
              onSendScheduleDate={handleDataScheduleDate}
              searchKey={searchKey.length < 3 ? "" : searchKey}
            ></ScheduleCalendar>
          )}
          {/* <CustomTabs value={tabValue} className={styles.tab_title} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Gantt chart" />
          <Tab label="List" />
          <Tab label="Calendar" />
        </CustomTabs>
        <TabPanel value={tabValue} index={0}>
         
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
            
            
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          
        </TabPanel> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Schedule;
