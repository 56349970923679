import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface UserContextType {
  email: string | null;
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;
}

const UserContext = createContext<UserContextType>({
  email: null,
  setEmail: () => {},
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [email, setEmail] = useState<string | null>(()=>{
    const storedEmail = localStorage.getItem('email');
    return storedEmail || null;})
  useEffect(() => {
    if (email) {
      localStorage.setItem('email', email);
    } else {
      localStorage.removeItem('email');
    }
  }, [email]);
  
  return (
    <UserContext.Provider value={{ email, setEmail }}>
      {children}
    </UserContext.Provider>
  );
};
