// DocumentViewer.tsx

import React, { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import Zoom from 'react-medium-image-zoom'; // Ensure this is installed
import PrintIcon from '@mui/icons-material/Print'; // Adjust based on your imports
import { mdiDownload, mdiCloseCircleOutline } from '@mdi/js'; // Adjust based on your icon library
import Icon from '@mdi/react'; // Ensure this is correctly imported
import styles from './DocumentViewerVendor.module.scss'; // Import your CSS module
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { StyledDrawer } from '../../../components';


type Document = {
  uri: string;
  fileType: string;
  fileName: string;
};
interface DocumentViewerVendorProps {
  open: boolean;
  selectedDocument: Document[];
  showDocument: boolean;
  closePreview: () => void;
}
const DocumentViewerVendor: React.FC<DocumentViewerVendorProps> = ({
  open,
  selectedDocument,
  showDocument,
  closePreview,
}) => {
  const handleDownload = async () => {
    const fileName = selectedDocument[0]?.fileName;
    const uri = selectedDocument[0]?.uri;

    if (!uri || !fileName) return;

    try {
      const response = await fetch(uri, { mode: "cors" });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Failed to download the file:", error);
    }
  };

  const handlePrint = async (event: React.MouseEvent) => {
    event.preventDefault();
    const uri = selectedDocument[0]?.uri;

    if (selectedDocument[0]?.fileType === "jpg") {
      printImage(uri);
    } else {
      window.open(uri);
    }
  };

  const printImage = (imageUrl: string) => {
    const printFrame = document.createElement("iframe");
    document.body.appendChild(printFrame);
    printFrame.style.position = "absolute";
    printFrame.style.top = "-10000px";
    printFrame.style.left = "-10000px";

    const img = printFrame.contentDocument?.createElement("img");
    if (img) {
      img.src = imageUrl;
      img.style.width = "100%";
      img.style.height = "100%";
      printFrame.contentDocument?.body.appendChild(img);
    }
    printFrame.contentWindow?.focus();
    printFrame.contentWindow?.print();
  };

  return (
    <StyledDrawer className={styles.rightDrawer} anchor="right" open={open} onClose={closePreview}>
      <Grid item container sm={12} className={styles.document_container}>
        {showDocument && selectedDocument.length > 0 && (
          <>
            <Grid item sm={12} container className={styles.document_viewer}>
              <Grid
                item
                container
                sm={12}
                className={styles.previewCloseButton}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                {selectedDocument[0].fileType !== "pdf" && (
                  <IconButton onClick={handleDownload}>
                    <Icon path={mdiDownload} size={1} color="#000" />
                  </IconButton>
                )}
                <IconButton onClick={handlePrint}>
                  <PrintIcon sx={{ color: "#000" }} />
                </IconButton>
                <IconButton onClick={closePreview}>
                  <Icon path={mdiCloseCircleOutline} size={1} color="#000" />
                </IconButton>
              </Grid>
              <Grid item sm={12} container>
                {['png', 'jpg', 'jpeg'].includes(selectedDocument[0].fileType) ? (
                  <Zoom>
                    <img
                      src={selectedDocument[0].uri}
                      alt={selectedDocument[0].fileName}
                      onLoad={() => console.log("Image loaded successfully.")}
                      onError={(e) => {
                        console.log("Error loading image: ", e);
                        
                      }
                      }
                      style={{ width: "100%", maxHeight: "80vh" }}
                    />
                  </Zoom>
                ) : (
                  <DocViewer
                    documents={selectedDocument}
                    pluginRenderers={DocViewerRenderers}
                    style={{ overflow: "auto", width: "100%" }}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </StyledDrawer>
  );
};

export default DocumentViewerVendor;