import { ICostCategoryProps, ICostCodeProps } from "../interfaces";

interface ValidationErrors {
    [key: string]: string;
  }
  
  export const validateCostCodeDetails=(formDetails: ICostCodeProps):ValidationErrors=>{
    const errors:ValidationErrors={};
    if(!formDetails.CostCodeNumber){
      errors.CostCodeNumber="Cost Code number is required."
    }
    else if(/^[a-zA-Z\s]+$/.test(formDetails.CostCodeNumber)){
      errors.CostCodeNumber ="Enter a valid costcode Number."
    }
    if(!formDetails.CostCodeName){
      errors.CostCodeName="Cost Code name is required."
    }
    if(!formDetails.CostCodeDescription){
      errors.CostCodeDescription="Cost Code Description is required."
    }
    if(!formDetails.CostCategoryId){
      errors.CostCategoryId="Please select a cost category ID."
    }
    return errors;
    
  }
  export const ValidateCostCategoryDetails=(formDetails: ICostCategoryProps):ValidationErrors=>{
    const errors:ValidationErrors={};
    if(!formDetails.categoryNumber){
      errors.categoryNumber="Category Number is required."
    }
    else if(/^[a-zA-Z\s]+$/.test(formDetails.categoryNumber)){
      errors.categoryNumber="Enter a valid category Number."
    }
    if(!formDetails.categoryName){
      errors.categoryName="Enter a category Name."
    }
    else if (!/^[A-Za-z]+$/.test(formDetails.categoryName)) {
      errors.categoryName = "Category Name must contain only letters.";
    }
    return errors;
  }
  