import UserAxios from "../UserAxios";
import {ICountryStateData, ICountry,IState} from "../../interfaces";
import { ISubcriptionDetails } from "../../interfaces";


  export const fetchCountryStateData=async(): Promise<ICountryStateData>=>{
        const response = await UserAxios.get<ICountryStateData>('Billing/GetStatesAndCountries');
        return response.data;
    }

  export const getProducts = async():Promise<any>=>{
    const response = await UserAxios.get<any>('Subscription/GetProducts');
    return response.data;
  }  
  export const purchaseSubscription = async (subscriptionDetails: ISubcriptionDetails) => {
    const response = await UserAxios.post(`Subscription/PurchaseSubscription`, subscriptionDetails);
    return response.data;
  };
  const Payment  = async (paymentDetails : any)=>{
    const response = await UserAxios.post(`Billing/createPayment`, paymentDetails)
    return response.data
  }
   const billingService={fetchCountryStateData,getProducts,purchaseSubscription, Payment};
   export default billingService;