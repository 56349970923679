export type DateOnly = {
    year: number;
    month: number;
    day: number;
  };
  
  export const convertToDate = (dateOnly: DateOnly): Date => {
    const { year, month, day } = dateOnly;
    return new Date(year, month - 1, day); // month is 0-indexed
  };
  
  export const dateToDateOnlyString = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  

  export {};
  