import dayjs from "dayjs";
import { IVendor } from "../interfaces/IVendor";

interface ValidationErrors {
  [key: string]: string;
}

export const validateVendor = (vendor: IVendor): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (!vendor.companyName.trim()) {
    errors.companyName = "Company name is required.";
  }

  if (!vendor.vendorTypeIds || vendor.vendorTypeIds.length === 0) {
    errors.vendorTypeIds = "At least one Vendor Type is required.";
  }
  if (!vendor.address1.trim()) {
    errors.address1 = "Address Line 1 is required.";
  }
  if (!vendor.zipCode.trim()) {
    errors.zipCode = "Zip code is required.";
  }
  if (!vendor.stateId) {
    errors.stateId = "State is required.";
  }
  if (!vendor.city.trim()) {
    errors.city = "City is required.";
  }
  if (vendor.coiStartDate && !vendor.coiExpiryDate) {
    errors.coiExpiryDate = "COI Expiry date is required.";
  }
  if (!vendor.coiStartDate && vendor.coiExpiryDate) {
    errors.coiStartDate = "COI Start date is required.";
  }
  if (vendor.coiStartDate && vendor.coiExpiryDate) {
    const startDate = dayjs(vendor.coiStartDate);
    const endDate = dayjs(vendor.coiExpiryDate);
    if (startDate.isAfter(endDate)) {
      errors.coiStartDate = "COI Start date cannot be after COI Expiry date";
      errors.coiExpiryDate = "COI Expiry date cannot be before COI Start date";
    }
  }
  return errors;
};
