import React, { useState, useEffect } from "react";
import styles from "./vendorContactList.module.scss";
import { Datatable, DeleteDialog } from "../../../components";
import Icon from "@mdi/react";
import { mdiMessageProcessing, mdiTrashCanOutline } from "@mdi/js";
import { Alert, Button, Checkbox, FormControlLabel, IconButton, Snackbar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { IContactDetails, IVendorContactListProps } from "../../../interfaces";
import AddVendorContact from "../AddVendorContact/AddVendorContact";
import ContactDetails from "../../ContactDetails/ContactDetail";
import Swal from "sweetalert2";
import userEvent from "@testing-library/user-event";
import vendorServices from "../../../api/services/VendorService";
import { useTranslation } from "react-i18next";

const VendorContactList: React.FC<IVendorContactListProps> = ({
  contactProp,
  vendorId,
  onUpdateContacts,
}) => {
  const [addContactOpen, setAddContactOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  const [deleteRequest, setDeleteRequest] = useState<{
    contactId?: string;
    rowIndex?: number;
  } | null>(null);
  const [contacts, setContactDetails] = useState<IContactDetails[]>([]);
  const [editData, setEditData] = useState<IContactDetails>();
  const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const totalContacts = contactProp.length;
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { t } = useTranslation();
  //Handle save contact
  const handleSaveContact = (contact: IContactDetails, rowIndex?: number) => {
    setContactDetails((prevContacts) => {
      let updatedContacts;
      if (contact.contactId) {
        // Update the contact with the matching contactId
        updatedContacts = prevContacts.map((c) =>
          c.contactId === contact.contactId && !c.isDeleted ? contact : c
        );
      } else if (rowIndex !== undefined) {
        // Update the contact based on rowIndex if the contactId is not present
        updatedContacts = prevContacts.map((c, index) =>
          index === rowIndex ? contact : c
        );
      } else {
        // Add new contact if neither contactId nor rowIndex is provided
        updatedContacts = [...prevContacts, contact];
      }
      onUpdateContacts(updatedContacts);
      return updatedContacts;
    });
  };

  useEffect(() => {
    setContactDetails(contactProp || []);
    setLoading(false);
  }, [contactProp]);

  //handle drawer open
  const handleDrawerOpen = (contactRowId: number) => {
    const selectedContact = contacts?.[contactRowId];
    if (selectedContact) {
      setEditData(selectedContact);
      setCurrentRowIndex(contactRowId);
      setAddContactOpen(true);
    }
  };

  //handle drawer close
  const handleDrawerClose = () => {
    setAddContactOpen(false);
    setEditData(undefined);
    setCurrentRowIndex(null);
  };
  const handleDelete = async (contactId?: string, rowIndex?: number) => {
    var customerId = parseInt(localStorage.getItem("customerId") as string, 10);
    setDeleteRequest({ contactId, rowIndex });
    if (customerId && vendorId) {
      const response = await vendorServices.getUserAssignmentStatus(contactId as string, customerId, vendorId);

      if (response.isUserAssigned) {
        setSnackbarMessage("The contact is associated with the task.");
        setOpenSnackbar(true);
      } else {

        setDeleteDialogOpen(true);
      }
    }
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteConfirm = async () => {
    if (deleteRequest !== null) {
      const { contactId, rowIndex } = deleteRequest;

      try {


        setDeleteDialogOpen(false);

        setContactDetails((prevContacts) => {
          let updatedContacts;

          if (contactId) {
            // Delete by contactId
            updatedContacts = prevContacts.map((contact) =>
              contact.contactId === contactId
                ? { ...contact, isDeleted: true }
                : contact
            );
          } else if (rowIndex !== undefined && rowIndex !== null) {
            // Delete by rowIndex
            updatedContacts = prevContacts.map((contact, index) =>
              index === rowIndex ? { ...contact, isDeleted: true } : contact
            );
          } else {
            updatedContacts = prevContacts;
          }

          // Refresh contacts based on delete type
          const refreshedContacts = updatedContacts.filter((contact) => {
            // If deleting by contactId, include the contactId even if deleted
            if (contactId && contact.contactId === contactId) {
              return true;
            }
            // Otherwise, exclude deleted contacts
            return !contact.isDeleted;
          });

          onUpdateContacts(refreshedContacts);
          return refreshedContacts;
        });

      } catch (error) {
        setError("Something went wrong while deleting the contact.");
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: t("VendorContactList.contact_person"),
      width: 120,
      minWidth: 150,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleContactNameClick = () => {
          handleDrawerOpen(params.row.id);
        };
        const fullName = `${params.row.firstName} ${params.row.lastName}`;
        return (
          <div onClick={handleContactNameClick} style={{ cursor: "pointer" }}>
            <span>{fullName}</span>
          </div>
        );
      },
    },
    {
      field: "contactNo",
      headerName: t("VendorContactList.contact_number"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => {
        const {
          contactNo,
          alternatePhoneNumber,
          sendSmsToPhone,
          canReceiveSms,
        } = params.row;
        // Determine which phone number should have the SMS icon
        const shouldShowSmsIconPrimary =
          canReceiveSms && sendSmsToPhone === contactNo;
        const shouldShowSmsIconAlternate =
          canReceiveSms && sendSmsToPhone === alternatePhoneNumber;

        return (
          <div
            style={{
              padding: "6px 0px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {contactNo && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {contactNo}
                {shouldShowSmsIconPrimary && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
            {alternatePhoneNumber && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {alternatePhoneNumber}
                {shouldShowSmsIconAlternate && (
                  <Icon path={mdiMessageProcessing} size={0.8} />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: t("VendorContactList.contact_email"),
      width: 150,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: "deleteProject",
      headerName: "",
      width: 50,
      minWidth: 50,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          onClick={() => {
            const contactId = params.row.contactId;
            const rowIndex = params.row.id;
            handleDelete(
              contactId ? contactId : undefined,
              contactId ? undefined : rowIndex
            );
          }}
        >
          <Icon path={mdiTrashCanOutline} aria-label="delete" size={1} />
        </IconButton>
      ),
    },
  ];

  // Paginate the data
  const paginatedContacts = contacts
    .filter((contact) => !contact.isDeleted)
    .slice(page * pageSize, page * pageSize + pageSize);

  const rows = paginatedContacts.map((contact, index) => ({
    id: index,
    firstName: contact.firstName,
    sendSmsToPhone: contact.sendSmsToPhone,
    email: contact.email,
    lastName: contact.lastName,
    contactNo: contact.contactNo,
    canReceiveSms: contact.canReceiveSms,
    alternatePhoneNumber: contact.alternatePhoneNumber,
    isDeleted: contact.isDeleted,
    contactId: contact.contactId,
  }));

  //handling the page change
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  //handling the change of the  page size
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to the first page
  };

  return (
    <div className={styles.Gridcontainer}>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={pageSize}
        pagination
        rowCount={totalContacts}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        loading={loading}
        autoHeight={true}
        error={error}
        className={styles.table_wrapper}
        getRowHeight="auto"
      />
      <AddVendorContact
        vendorId={vendorId}
        open={addContactOpen}
        onClose={handleDrawerClose}
        onSaveContact={handleSaveContact}
        editData={editData}
        rowIndex={currentRowIndex}
      />
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          title={deleteDialogTitle}
          cancelbuttonText={dialogCancelButtonText}
          submitButtonText={dialogDeleteButtonText}
          cancelbuttonLink={handleDeleteCancel}
          submitButtonLink={handleDeleteConfirm}
        />
      )}  <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default VendorContactList;
