export enum EntityType {
    project = 1,
    budget = 2,
    vendor = 3,
    task = 4,
    schedule = 5,
    material = 6,
    participate = 7,
    equipment = 8,
    payment = 9,
    attachment = 10,
    COI = 11,
    W9 = 12,
    policyNumber = 13,
    warrantyDeed = 14,
    closingDocuments = 15,
    paymentConfirmation = 16,
    saleDeed = 17,
    lot = 18
}
