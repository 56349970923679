import CryptoJS  from "crypto-js";

// const secretKey = process.env.REACT_APP_SECRET_KEY || "";
const secretKey = "HJ85fsUoJ23as9jsdLfGsY2JD8kQf12H";
const emailkey = process.env.REACT_APP_EMAIL_KEY;
const emailIV = process.env.REACT_APP_EMAIL_IV;

export const encryptData = (data: any) => {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const iv = CryptoJS.lib.WordArray.random(16);

  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv });
  const encryptedData = iv
    .concat(encrypted.ciphertext)
    .toString(CryptoJS.enc.Base64);

  return encryptedData;
};


export const decryptData = (encryptedData: string): any => {
  const key = CryptoJS.enc.Utf8.parse(secretKey);

  const encryptedWordArray = CryptoJS.enc.Base64.parse(encryptedData);

  const iv = CryptoJS.lib.WordArray.create(
    encryptedWordArray.words.slice(0, 4),
    16
  );
  const ciphertext = CryptoJS.lib.WordArray.create(
    encryptedWordArray.words.slice(4),
    encryptedWordArray.sigBytes - 16
  );

  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: ciphertext,
    iv: iv,
    key: key,
  });

  const bytes = CryptoJS.AES.decrypt(cipherParams, key, { iv: iv });

  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

//To Encrypt Email
export const encryptVerifyEmail = (data: any) => {
  if (!emailkey || !emailIV) {
    return null;
  }
  const keyWords = CryptoJS.enc.Utf8.parse(emailkey);
  const ivWords = CryptoJS.enc.Utf8.parse(emailIV);
  const encrypted = CryptoJS.AES.encrypt(data, keyWords, {
    iv: ivWords,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString().replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

//To Encrypt Email
export const decryptVerifyEmail = (encryptedEmail: string): any => {
  if (!emailkey || !emailIV) {
    return null;
  }
  let base64 = encryptedEmail.replace(/-/g, '+').replace(/_/g, '/');
  switch (base64.length % 4) {
    case 2: base64 += '=='; break;
    case 3: base64 += '='; break;
  }
  const keyWords = CryptoJS.enc.Utf8.parse(emailkey);
  const ivWords = CryptoJS.enc.Utf8.parse(emailIV);
  const decrypted = CryptoJS.AES.decrypt(base64, keyWords, {
    iv: ivWords,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}
