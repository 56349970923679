import React, { useEffect, useState } from "react";
import styles from "./materials.module.scss";
import Datatable from "../../components/datatable/datatable";
import Icon from "@mdi/react";
import {
  mdiAlert,
  mdiCalendarMonth,
  mdiCancel,
  mdiCash,
  mdiCheckBold,
  mdiCheckbook,
  mdiCheckCircle,
  mdiClose,
  mdiContentCopy,
  mdiCreditCardOutline,
  mdiFilter,
  mdiMagnify,
  mdiPencil,
  mdiProgressClock,
  mdiTrashCanOutline,
} from "@mdi/js";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import TaskServices from "../../api/services/TaskServices";
import { useLocation, useParams } from "react-router-dom";
import { IProjectParam } from "../../interfaces";
import { PaymentDrawer } from "../../components";
import { AddMaterials } from "../../containers";
import materialService from "../../api/services/MaterialService";
import { IDisplayMaterial } from "../../interfaces/IMaterialInformation";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { sharedService } from "../../api";

const Materials: React.FC = () => {
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [count, setCount] = React.useState(0);
  const [Tasklist, setTasklist] = useState<IDisplayMaterial[]>([]);
  const pageCount = Math.ceil(count / rowsPerPage);
  const [selectedMaterial, setselectedMaterial] = useState<any>();
  const [searchKey, setSearchKey] = useState<string>("");
  const {t}=useTranslation();
  const columns: GridColDef[] = [
    {
      field: "materialCategory",
      headerName: t("Materials.materialCategory"),
      width: 120,
      minWidth: 150,
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="projectLink cursor_pointer">
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "materialPurchased",
      headerName: t("Materials.materialPurchased"),
      width: 120,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "materialVendor",
      headerName: t("Materials.materialVendor"),
      width: 150,
      minWidth: 150,
      editable: false,
      disableColumnMenu: true,
      flex: 1.3,
    },
    {
      field: "materialPurchaseDate",
      headerName: t("Materials.materialPurchaseDate"),
      width: 100,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      flex: 0.7,
    },
    {
      field: "amount",
      headerName: t("Materials.amount"),
      width: 100,
      minWidth: 100,
      editable: false,
      disableColumnMenu: true,
      flex: 0.8,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => <span>$ {params.value} </span>,
    },
    {
      field: "materialComments",
      headerName: t("Materials.materialComments"),
      width: 200,
      minWidth: 200,
      editable: false,
      disableColumnMenu: true,
      flex: 2,
    },
  ];

  const [userRoles, setUserRoles] = useState<any>(null);
  
  const [refreshTask, setRefreshTask] = useState(false);
  useEffect(() => {
    if (refreshTask) {
      fetchMaterials(projectId);
    }
  }, [refreshTask]);
  // Fetch tasks based on projectId
  useEffect(() => {
    fetchMaterials(projectId);
  }, [projectId, page, rowsPerPage,searchKey]);

  const fetchMaterials = async (projectId: any) => {
    try {
      setUserRoles(await sharedService.getUserRolesPermissions());
      if (projectId) {
        const params: IProjectParam = {
          pageNumber: page + 1,
          pageSize: rowsPerPage,
          sortBy: "modifieddate",
          projectTypeId: undefined,
          sortDescending: false,
          searchKey: searchKey || "",
          isGantt: false,
        };
        var userTypeId = localStorage.getItem("userRoleId");
        var userId: any = localStorage.getItem("userId");
        const response = await materialService.getMaterialByProject(
          projectId,
          parseInt(userTypeId as string, 10),
          userId,
          params
        );
        setTasklist(response.materialResult);
        setCount(response.totalCount);
        setLoading(false);
      }
    } catch (error: any) {
      setError(error.response.data.errors.join(", "));
      setLoading(false);
    }
  };

  const handleDataFromChild = (childData: boolean) => {
    setRefreshTask(childData); // Update state with the data from the child
  };
  const rows = Tasklist.map((material) => ({
    id: material.materialId,
    materialCategory: material.costCode?.categoryName || "Unknown",
    materialVendor: material.vendorName,
    materialPurchased: material.materialName,
    materialPurchaseDate: material.purchaseDate
      ? dayjs(material.purchaseDate).format("YYYY-MM-DD")
      : null,
    materialComments: material.description,
    amount: material.amount,
  }));

  const [addMaterialDrawerOpen, setAddMaterialDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleAddMaterialDrawerOpen = () => {
    setAddMaterialDrawerOpen(true);
  };

  // handle for closing drawer
  const handleAddMaterialDrawerClose = () => {
    setselectedMaterial(null);
    setAddMaterialDrawerOpen(false);
  };

  // handle for drawer
  const [materialPaymentDrawerOpen, setMaterialPaymentDrawerOpen] =
    React.useState(false);

  // handle for opening drawer
  const handleMaterialPaymentDrawerOpen = () => {
    setMaterialPaymentDrawerOpen(true);
  };

  // handle for closing drawer
  const handleMaterialPaymentDrawerClose = () => {
    setMaterialPaymentDrawerOpen(false);
  };
  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setSearchKey(value);
  };
  // Handle page change
  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < pageCount) {
      setPage(newPage);
    }
  };
  const handleRowClick = (params: any) => {
    const { row } = params;
    const material = Tasklist.filter((e) => e.materialId == params.id).pop();

    setselectedMaterial(material);
    setAddMaterialDrawerOpen(true);
  };
  return (
    <div className={styles.Gridcontainer}>
      <Grid
        container
        className={styles.search_container}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid
          className={styles.chip_container}
          container
          item
          sm={6}
          alignItems="center"
          justifyContent="flex-start"
        >
          <TextField
            placeholder={t("Materials.search")}
            variant="outlined"
            className="width30"
            value={searchKey}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchKey ? (
                    <IconButton
                      aria-label="clear search"
                      onClick={() => setSearchKey("")} // Clear the search key
                    >
                      <Icon path={mdiClose} size={0.8} />
                    </IconButton>
                  ) : (
                    <Icon path={mdiMagnify} size={1} />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => setAddMaterialDrawerOpen(userRoles?.add_edit_task ? true : false)}
            className="primary-button rounded"
          >
            {t("Materials.add_material_btn")}
          </Button>
        </Grid>
      </Grid>
      <Datatable
        rows={rows}
        columns={columns}
        page={page}
        rowsPerPage={rowsPerPage}
        pagination
        paginationMode="server"
        onPageChange={handlePageChange}
        rowCount={count}
        loading={loading}
        error={error}
        onRowClick={handleRowClick}
      />

      <AddMaterials
        open={addMaterialDrawerOpen}
        onClose={handleAddMaterialDrawerClose}
        refreshTask={handleDataFromChild}
        material={selectedMaterial}
      />
      <PaymentDrawer
        open={materialPaymentDrawerOpen}
        onClose={handleMaterialPaymentDrawerClose}
      />
    </div>
  );
};

export default Materials;
