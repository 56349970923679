import styles from "./recentActivity.module.scss";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { deepOrange, deepPurple } from '@mui/material/colors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={styles.tabStyles}
      
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RecentActivity = (props: any) => {

  const [value, setValue] = React.useState(0);
  const [recentFiles, setRecentFiles] = React.useState<any[]>(props.attachmentDetail);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} variant="scrollable"
          scrollButtons="auto" onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Recent Files" {...a11yProps(0)} />
          {/* <Tab label="Last Activity" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {recentFiles && recentFiles.length > 0 ? recentFiles.map((file: any, index: number) => {
          return (
            <div className={styles.recentFileItem} key={index}>
              <div className={styles.left}>
                <span className={styles.fileName}>{file.fileName}</span>
                {file.days === 0 && <span className={styles.fileInfo}>{file.textType} today</span>}
                {file.days === 1 && <span className={styles.fileInfo}>{file.textType} {file.days} day ago</span>}
                {file.days > 1 && <span className={styles.fileInfo}>{file.textType} {file.days} days ago</span>}
              </div>
              <div className={styles.right}>
                <Avatar sx={{ bgcolor: 'var(--primary)', width: 32, height: 32 }}>{file.firstName ? file.firstName.charAt(0).toUpperCase() : ''}</Avatar>
              </div>
            </div>
          );
        }) : <div className={styles.no_data}>No files available</div>}

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className={styles.lastActivityItem}>
          Last Activity

        </div>
      </CustomTabPanel>
    </Box>
  );
};

export default RecentActivity;