import React, { useState } from "react";
import styles from "./addBudget.module.scss";
import {
  Box,
  Button,
  Drawer,
  Grid,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Icon from "@mdi/react";
import { mdiCloudUpload } from "@mdi/js";
import { TimePicker } from "@mui/x-date-pickers";
import { IAddTaskDrawerProps, IProject } from "../../../interfaces";
import { StyledDrawer } from "../../../components";

const AddEquipments: React.FC<IAddTaskDrawerProps> = ({ open, onClose }) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // handle for drawer
  const [addEquipmentDrawerOpen, setAddEquipmentDrawerOpen] =
    React.useState(false);
  // toggle event for drawer
  const handleDrawerToggle = () => {
    setAddEquipmentDrawerOpen(false);
    if (onClose) {
      onClose();
    }
  };

  // To handle and push the file in filesArray
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = [
      ".pdf",
      ".jpg",
      ".dwf",
      ".dwg",
      ".tif",
      ".jpeg",
      ".png",
    ];
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const invalidFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length > 0) {
        // setFormDetails({
        //   ...formDetails,
        //   files: [...formDetails.files, ...filesArray],
        // });
      }
    }
  };

  return (
    <StyledDrawer className={styles.rightDrawer} open={open} anchor="right">
      <Box
        component="form"
        noValidate
        sx={{ mt: 1 }}
        className={styles.addProjectcontainer}
        alignContent="space-between"
      >
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            <h3>Add Equipment</h3>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Equipment Name:</label>
              <TextField
                placeholder="Task Name"
                variant="outlined"
                className="width70"
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Vendor:</label>
              <Select
                labelId="status_placeholder"
                id="status"
                placeholder="Status"
                className="inlineSelect selectBox width70"
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Contact Person:</label>
              <Select
                labelId="status_placeholder"
                id="status"
                placeholder="Status"
                className="inlineSelect selectBox width70"
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Date of Rent:</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker sx={{ width: "36% !important" }} />
              </LocalizationProvider>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Duration of Rent:</label>
              <TextField
                placeholder="No of Days"
                variant="outlined"
                sx={{ width: "36% !important" }}
              />
            </Grid>
            <Grid item container className="fieldWrapper">
              <Grid
                item
                xs={7}
                className="multiColumns"
                alignItems="center !important"
                alignContent="center !important"
              >
                <label className="FirstLabel w-240" >
                  Amount:
                </label>
                <TextField placeholder="$0.00" variant="outlined" />
              </Grid>
              <Grid item xs={5} className="multiColumns">
                <label id="type" htmlFor="name">
                  Paid By:
                </label>
                <Select
                  labelId="status_placeholder"
                  id="status"
                  placeholder="Status"
                  className="selectBox"
                  sx={{ width: "100% !important", ml: "10px", mr: "8%" }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Credit Card used:</label>
              <Select
                labelId="status_placeholder"
                id="status"
                placeholder="Status"
                className="selectBox"
                sx={{ width: "36% !important" }}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </Grid>
            <Grid item container className="fieldWrapper">
              <label htmlFor="name">Comment:</label>
              <TextField
                id="note"
                placeholder="Notes"
                multiline
                rows={4}
                className="width70"
              />
            </Grid>
            <Grid
              item
              container
              className="fieldWrapper"
              alignItems={"flex-start"}
            >
              <label>Attachments:</label>
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                aria-placeholder="Click here to upload"
                endIcon={
                  <Icon
                    className="uploadButtonIcon"
                    path={mdiCloudUpload}
                    size={1}
                  />
                }
                className="uploadButton"
              >
                Click here to upload
                <input
                  type="file"
                  multiple
                  accept=".pdf,.jpg,.dwf,.dwg,.tif,.jpeg,.png"
                  className="d_none"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid className="fileListContainer">
              {/* {formDetails.files?.map((file, index) => (
                  <div key={index} className="fileItem">
                    <span>{file.name}</span>
                    <svg
                      className="cancelIcon"
                      onClick={() => removeFile(file.name)}
                    >
                      <HighlightOffIcon />
                    </svg>
                  </div>
                ))} */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={styles.submitButtons + " submitButtons"}
        >
          <Button
            fullWidth
            variant="outlined"
            className="outline-button widthauto"
            onClick={handleDrawerToggle}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            className="primary-button widthauto"
          >
            Save
          </Button>
        </Grid>
      </Box>
    </StyledDrawer>
  );
};

export default AddEquipments;
