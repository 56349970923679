import dayjs from "dayjs";
import { IContactDetails } from "../interfaces";

interface ValidationErrors {
  [key: string]: string;
}

// Regular expressions for validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[0-9\s-]{3,20}$/;

export const validateContact = (contact: IContactDetails): ValidationErrors => {
  const errors: ValidationErrors = {};

  // Check for required fields
  if (!contact.firstName.trim()) {
    errors.firstName = "First name is required.";
  }
  if (!contact.lastName.trim()) {
    errors.lastName = "Last name is required.";
  }
  if (!contact.contactNo.trim()) {
    errors.contactNo = "Primary Phone is required.";
  } else if (!phoneRegex.test(contact.contactNo)) {
    errors.contactNo = "Primary Phone is not a valid phone number.";
  }
  if (!contact.email.trim()) {
    errors.email = "Email is required.";
  } else if (!emailRegex.test(contact.email)) {
    errors.email = "Email is not a valid email address.";
  }

  // Optional validations
  if (
    contact.alternatePhoneNumber &&
    !phoneRegex.test(contact.alternatePhoneNumber)
  ) {
    errors.alternatePhoneNumber =
      "Alternate Phone is not a valid phone number.";
  }

  if(contact.canReceiveSms && !contact.sendSmsToPhone.trim()){
    errors.sendSmsToPhone = "Send SMS To Phone Required.";
  }

  return errors;
};
