import styles from "./upcomingPayments.module.scss";
import React from "react";
// import { Card, CardContent, Typography, Grid, Icon, Avatar } from '@mui/material';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { mdiBriefcase, mdiCalendarMonth, mdiProgressClock } from "@mdi/js";
import Icon from "@mdi/react";
import { mdiCalendar, mdiCurrencyUsd } from "@mdi/js";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
// Interface for props
interface PaymentDetails {
  company: string;
  date: string;
  amount: string;
}

// Separate component for each payment item
const PaymentItem: React.FC<PaymentDetails> = ({ company, date, amount }) => {
  return (
    <div className={styles.paymentItemContainer}>
      <div className={styles.title}> {company}</div>
      <div className={styles.subContent}>
        <div className={styles.date}>
          <Icon path={mdiCalendarMonth} size={0.7} />
          <span>{date}</span>
        </div>
        <div className={styles.value}>
          <span className={styles.greenIcon}>
            <Icon path={mdiCurrencyUsd} size={0.7} />
          </span>
          <span>{amount}</span>
        </div>
      </div>
    </div>
  );
};

// Main component for Upcoming Payments card
const UpcomingPayments: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.paymentContainer}>
      <Grid container spacing={4} alignItems="start">
        <Grid item xs={9}>
          {/*<h4 id="upcoming_payments"> {t('dashboard.upcoming_payments')} </h4>
           <div className={styles.upcomingList}>
            <PaymentItem
              company="ABC Landscaping LLC"
              date="10/12/2024"
              amount="4,500"
            />
            <PaymentItem
              company="DEF Construction Co."
              date="10/14/2024"
              amount="3,200"
            />

          </div> */}
          <div className={styles.no_data}>No upcoming payments available</div>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          <div className="v_center">
            <div className={styles.iconBoxIcon + " " + styles.yellowIcon}>
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/assets/dashboard-icon/upcoming-payment.svg"}
                className="empty_img"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UpcomingPayments;
