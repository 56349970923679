import React, { useEffect, useRef, useState } from "react";
import styles from "./addProject.module.scss";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  styled,
  Snackbar,
  Switch,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Collapse,
  useTheme,
  useMediaQuery,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Icon from "@mdi/react";
import {
  mdiChevronDownCircle,
  mdiChevronUpCircle,
  mdiCloudUpload,
} from "@mdi/js";
import {
  ICountry,
  IDeleteProjectParam,
  IProject,
  IState,
  ITemplates,
} from "../../../interfaces";
import { billingService, projectService } from "../../../api";
import dayjs, { Dayjs } from "dayjs";
import { useUserContext } from "../../../utils/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { validateProject } from "../../../utils/ProjectValidation";
import { LookupTypeId } from "../../../enums";
import { validateProjectParam } from "../../../utils/ProjectParamValidation";
import { DeleteDialog, SMSSwitch } from "../../../components";
import { decryptData } from "../../../utils/CryptoJS";
import { useBannerContext } from "../../../utils/BannerContext";
import { set } from "date-fns";
import { useTranslation } from "react-i18next";

const AddProject: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  var customerId = parseInt(localStorage.getItem("customerId") as string, 10);
  var userId = localStorage.getItem("userId") as string;
  const [lookupValues, setLookupValues] = useState<any>({});
  const [lookupTypes, setLookupTypes] = useState<any[]>([]);
  const [statusTypes, setStatusTypes] = useState<any[]>([]);
  const [projectTypes, setProjectTypes] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const { email } = useUserContext();
  const [error, setError] = useState<string | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [states, setStates] = useState<IState[]>([]);
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<ITemplates[]>([]);
  const [selectedProjectTypeId, setSelectedProjectTypeId] = useState<
    number | null
  >(null);
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [validationParamErrors, setValidationParamErrors] = useState<{
    [item: string]: string;
  }>({});
  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [projectParams, setProjectParams] = useState<any[]>([]);
  const [listElements, setListElements] = useState([]);
  const [editProjectTab, setEditProjectTab] = React.useState("false");
  const [isLoaderVisible, setLoaderIsVisible] = useState(false);
  const initialProjectState = {
    projectName: "",
    statusId: 1, //it will remove in next US
    projectTypeId: 0,
    projectStartDate: null,
    projectEndDate: null,
    notes: "",
    templateId: 0,
    userId: "",
    address1: "",
    address2: "",
    countryId: 0,
    zipCode: "",
    stateId: "",
    files: [],
    projectParams: [],
    city: "",
    description: "",
    customerId: 0,
  };

  const [resetParam, setResetParam] = useState(false);
  const [formDetails, setFormDetails] = useState<IProject>({
    projectName: "",
    statusId: 1, //it will remove in next US
    projectTypeId: 0,
    projectStartDate: null,
    projectEndDate: null,
    notes: "",
    templateId: 0,
    userId: "",
    address1: "",
    address2: "",
    city: "",
    zipCode: "",
    stateId: "",
    files: [],
    projectParams: [],
    description: "",
    customerId: 0,
  });
  const [errors, setErrors] = useState({
    projectname: false,
    projecttype: false,
    template: false,
    description: false,
    address1: false,
    stateId: false,
    city: false,
    zipCode: false,
    startDate: false,
  });
  const [touchedFields, setTouchedFields] = useState<Record<any, any>>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    const isValid = validateForm();
    setIsFormValid(isValid);
  }, [formDetails]);

  const handleBlur = (field: any) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    if (!formTouched) {
      setFormTouched(true); // Set formTouched to true on first blur
    }
  };
  // To remove Files from the uploded file list
  const removeFile = (fileName: string) => {
    const updatedFiles = formDetails.files.filter(
      (file) => file.name !== fileName
    );
    setFormDetails({
      ...formDetails,
      files: updatedFiles,
    });
  };

  useEffect(() => {
    if (projectId) {
      setLoaderIsVisible(true);
      setEditProjectTab("true");
      //fetch the project details based on the projectId
      (async () => {
        try {
          const data = await projectService.getProjectByProjectId(projectId);
          data.readProjectDetails.files = [];
          setTimeout(async () => {
            data.readProjectDetails.projectStartDate = data.readProjectDetails
              .projectStartDate
              ? dayjs(data.readProjectDetails.projectStartDate)
              : null;
            data.readProjectDetails.projectEndDate = data.readProjectDetails
              .projectEndDate
              ? dayjs(data.readProjectDetails.projectEndDate)
              : null;
            setFormDetails(data.readProjectDetails);
            setResetParam(true);
            setSelectedProjectTypeId(data.readProjectDetails.projectTypeId);
            await getProjectTemplateParamSections(
              data.readProjectDetails.templateId
            );
            setLoaderIsVisible(false);
          }, 300);
        } catch (error: any) {
          setLoaderIsVisible(false);
          setSnackbarMessage(error.response.data.errors.join(", "));
          setOpenSnackbar(true);
        }
      })();
    }
  }, []);

  useEffect(() => {
    //To get the Countries and States data
    const getCountryStateData = async () => {
      try {
        const data = await billingService.fetchCountryStateData();
        if (data) {
          setCountries(data.country);
          setStates(data.state);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    //to get LookupData for status projectype and Unit
    const getLookupData = async () => {
      try {
        const data = await projectService.getLookupDataAsync();

        if (data.isSuccess) {
          setLookupTypes(data.lookupType);
          setLookupValues(data.lookupValues);

          const statusTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.StatusType
          );
          const projectTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.ProjectType
          );
          const unitTypes = data.lookupValues.filter(
            (value: any) => value.lookupTypeID === LookupTypeId.UnitType
          );
          setStatusTypes(statusTypes);
          setProjectTypes(projectTypes);
          setUnits(unitTypes);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    // Fetch template data for project templates
    const getTemplateData = async () => {
      try {
        const data = await projectService.getProjectTemplatesAsync();
        if (data.isSuccess) {
          setTemplates(data.projectTemplate);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    };
    getTemplateData();
    getCountryStateData();
    getLookupData();
  }, [email]);

  // Handle the state change in the dropdown
  const handleStateChange = (event: SelectChangeEvent<number>) => {
    const selectedStateId = Number(event.target.value);
    if (typeof selectedStateId === "number") {
    }
    setFormDetails({
      ...formDetails,
      stateId: selectedStateId.toString(),
    });
  };
  // Handle the projectId change in the dropdown
  const handleProjectIdChange = (event: SelectChangeEvent<number>) => {
    const selectedProjectTypeId = Number(event.target.value);
    if (typeof selectedProjectTypeId === "number") {
      setSelectedProjectTypeId(selectedProjectTypeId);
    }
    setFormDetails({
      ...formDetails,
      projectTypeId: selectedProjectTypeId,
      templateId: 0,
    });
  };

  //handling the input field changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setValue = (value: any, index: number) => {
    formDetails.projectParams[index].paramValue = value.toString();
    setFormDetails((prevState) => ({
      ...prevState,
      projectParams: formDetails.projectParams,
    }));
  };

  // To handle and push the file in filesArray
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = [
      ".pdf",
      ".jpg",
      ".dwf",
      ".dwg",
      ".tif",
      ".jpeg",
      ".png",
    ];
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);

      const invalidFiles = filesArray.filter((file) => {
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;
        return allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length > 0) {
        setFormDetails({
          ...formDetails,
          files: [...formDetails.files, ...filesArray],
        });
      }
    }
  };

  // Handle changes for select dropdowns
  const handleSelectChange = (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target;

    setFormDetails((prevState) => ({
      ...prevState,
      [name!]: value,
    }));
  };

  // Handle changes for select dropdowns
  const handleTemplateSelectChange = async (e: SelectChangeEvent<number>) => {
    const { name, value } = e.target;

    setFormDetails((prevState) => ({
      ...prevState,
      [name!]: value,
    }));
    await getProjectTemplateParamSections(e.target.value);
  };
  // Fetch the project template param sections based on the templateId
  const getProjectTemplateParamSections = async (templateId: any) => {
    try {
      const response = await projectService.getProjectParamByTemplateIdAsync(
        templateId
      );
      if (response.isSuccess) {
        setProjectParams(response.projectTemplateParamSections);
      }
    } catch (error: any) {
      setSnackbarMessage(error.response.response.errors.join(", "));
      setOpenSnackbar(true);
    }
  };
  // Handle date changes for date pickers
  const handleDateChange = (name: keyof IProject, date: Dayjs | null) => {
    if (date && date.isValid()) {
      const formattedDate = date.format("YYYY-MM-DD");
      setFormDetails((prevState) => ({
        ...prevState,
        [name]: formattedDate,
      }));
    } else {
      setFormDetails((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };
  //handles when cancel button is clicked
  const handleCancel = () => {
    navigate("/projects", { replace: true });
  };
  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };
  // Handle the form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoaderIsVisible(true);
    e.preventDefault();
    const submitter = (e.nativeEvent as SubmitEvent)
      .submitter as HTMLButtonElement;
    const buttonType = submitter.name;

    const formDetailsWithUserId = {
      ...formDetails,
      userId: userId,
      customerId: customerId,
      projectEndDate: formDetails.projectEndDate
        ? formDetails.projectEndDate
        : null,
    };
    const errors = validateProject(formDetailsWithUserId);
    setValidationErrors(errors);
    const projectParamsList: any = [];
    projectParams.forEach((item, index) => {
      item.projectTemplateParams.forEach((param: any) => {
        projectParamsList.push(param);
      });
    });

    const paramErrors = validateProjectParam(
      formDetails.projectParams,
      projectParamsList
    );
    setValidationParamErrors(paramErrors);
    if (Object.keys(errors).length === 0) {
      try {
        if (editProjectTab === "true" && projectId) {
          const response = await projectService.editProject(
            formDetailsWithUserId,
            projectId
          );
          if (response.isSuccess) {
            setSnackbarMessage("Project updated successfully.");
            setLoaderIsVisible(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              setOpenSnackbar(false);
            }, 3000);
          }
        } else {
          const response = await projectService.addProject(formDetailsWithUserId);

          if (response.isSuccess) {
            if (buttonType == "Save") {
              setSnackbarMessage("Project saved successfully.");
              setLoaderIsVisible(false);
              setOpenSnackbar(true);
              sessionStorage.setItem(
                "currentProject",
                JSON.stringify(formDetailsWithUserId)
              );
              sessionStorage.setItem(
                "customerId",
                customerId.toString()
              );
              setTimeout(() => {
                navigate("/project-detail", { state: { projectId: response.projectId } });
              }, 3000);
            } else if (buttonType === "Save_Add") {
              setTouchedFields({}); 
              // Navigate to the add project page after saving
              setLoaderIsVisible(false);
              setSnackbarMessage("Project saved successfully.");
              setOpenSnackbar(true);
              // Reset form to initial state
              setFormDetails(initialProjectState);
              // Use setTimeout to ensure the snackbar is displayed before navigating and refreshing
              setTimeout(() => {
                navigate("/add-project");
                setListElements([]); // Refresh the page after navigation
              }, 500);
            }
          } else {
            setFormDetails(initialProjectState);
            setSnackbarMessage("Project saved successfully.");
            setLoaderIsVisible(false);
            setOpenSnackbar(true);
          }
        }
      } catch (error: any) {
        console.log(error);
        if (error.response?.data.errors[0] == "Project Already Exists") {
          setValidationErrors({
            ...validationErrors,
            projectName: "Project Name already exists",
          });
        } else {
          setSnackbarMessage(error.response.data.errors.Description[0]);
          setOpenSnackbar(true);
        }
        setLoaderIsVisible(false);
      }
    } else {
      setLoaderIsVisible(false);
    }
  };

  //handle when the field becomes inactive
  const handleParamBlur = (value: any, index: number, fieldName: any) => {
    const projectParamsList: any = [];
    projectParams.forEach((item, index) => {
      item.projectTemplateParams.forEach((param: any) => {
        projectParamsList.push(param);
      });
    });

    const newErrors = validateProjectParam(
      formDetails.projectParams,
      projectParamsList
    );

    setValidationParamErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: newErrors[fieldName],
    }));
  };

  //create project params based on the template selected
  useEffect(() => {
    setListElements([]);
    var parentElements: any = [];
    var elements: any = [];
    var subElements: any = [];
    if (projectParams.length != 0) {
      parentElements.push(
        <>
          <Grid
            item
            sm={12}
            className={styles.additional_info_title2}
            sx={{
              "@media screen and (max-width:768px)": {
                display: "none !important",
              },
            }}
          >
            <h4 className="mb_0">
              Additional Information
            </h4>
          </Grid>
        </>
      );
    }

    var subElementsCount: number = 0;
    projectParams.forEach((item, index) => {
      subElements = [];

      item.projectTemplateParams.forEach((param: any) => {
        if (editProjectTab === "false") {
          var data = {
            paramName: param.paramName,
            paramValue: param.defaultValue,
            paramUnitOfMeasure: param.defaultValue,
            projectParamsId: 0,
          };
          formDetails.projectParams.push(data);
        } else {
          var paramObj = formDetails.projectParams.find(
            (x) => x.paramName == param.paramName
          );
          param.defaultValue =
            paramObj?.paramValue == "false" ? false : paramObj?.paramValue;
        }
        var dataType: string = param.dataType === "Boolean" ? "bool" : "text";
        subElements.push(
          <>
            {/* name: any, label: any, type = "text", placeholder: any, className: string */}
            {renderInputField(
              param.displayName,
              param.displayName,
              dataType,
              param.displayName,
              "width70",
              param.defaultValue,
              param.paramName,
              subElementsCount,
              param.valueSuffixLabel,
              param.validationErrorMessage
            )}
          </>
        );

        subElementsCount = subElementsCount + 1;
      });
      elements.push(
        <>
          {subElements}
          {/* <Grid
            container
            key={index}
            item
            sm={4}
            className={styles.additional_info_content}
            spacing={0}
          >
            <Grid item>
              <h5>{item.paramSectionDescription}</h5>
            </Grid>
            <Grid item container spacing={1}>
              {subElements}
            </Grid>
          </Grid> */}
        </>
      );
    });
    parentElements.push(elements);
    setListElements(parentElements);
    setResetParam(false);
  }, [projectParams, resetParam]);

  const renderInputField = (
    name: any,
    label: any,
    type = "text",
    placeholder: any,
    className: string,
    defaultValue1: any,
    paramName: string,
    subParamIndex: number = 0,
    valueSuffixLabel: string,
    validationErrorMessage: string = ""
  ) => {
    if (type === "bool") {
      return (
        <Grid
          item
          className="fieldWrapper"
          sx={{ width: "50%", pt: "24px !important", pb: "16px !important" }}
        >
          <label id="type" htmlFor={name}>
            {label}
          </label>
          <SMSSwitch
            defaultChecked={defaultValue1}
            onChange={(e) => setValue(e.target.checked, subParamIndex)}
            sx={{ ml: "4rem" }}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item className="fieldWrapper" sm={4} xs={6}>
          <TextField
            name={name}
            type={type}
            variant="outlined"
            label={label}
            //className={className}
            placeholder={placeholder}
            defaultValue={defaultValue1}
            onChange={(e) => setValue(e.target.value, subParamIndex)}
            onBlur={(e) => handleParamBlur(e.target, subParamIndex, paramName)}
            error={!!validationParamErrors[name]}
            helperText={validationParamErrors[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {valueSuffixLabel}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      );
    }
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const deleteDialogTitle = "Are you sure you want to delete this record?";
  const dialogCancelButtonText = "Cancel";
  const dialogDeleteButtonText = "Delete";
  // Handles the cancel action for the delete dialog
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };
  const handleDeleteConfirm = async () => {
    if (projectId) {
      try {
        setDeleteDialogOpen(false);
        var deleteRequest: IDeleteProjectParam = {
          userId: userId,
          projectId: projectId,
        };
        const response = await projectService.deleteProject(deleteRequest);
        if (response.isSuccess) {
          setDeleteDialogOpen(false);
          setSnackbarMessage("Project deleted successfully.");
          setLoaderIsVisible(false);
          setOpenSnackbar(true);
          setTimeout(() => {
            navigate("/projects");
          }, 3000);
        }
      } catch (error: any) {
        setSnackbarMessage(error.response.data.errors.join(", "));
        setOpenSnackbar(true);
      }
    }
  };

  const { showBanner } = useBannerContext();
  const [stateValue, setStateValue] = React.useState<string | null>();
  const [inputValue, setInputValue] = React.useState("");

  // const [expanded, setExpanded] = useState(false); // Track collapse state
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen size

  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({
    section1: true,
    section2: true,
  });

  // Function to toggle the collapse of a specific section
  const handleToggle = (section: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section], // Toggle the specific section
    }));
  };
  const validateForm = () => {
    const newErrors = {
      projectname: formDetails.projectName === "",
      address1: formDetails.address1 === "",
      stateId: formDetails.stateId === "",
      city: formDetails.city === "",
      zipCode: formDetails.zipCode === "",
      projecttype: formDetails.projectTypeId === 0,
      template: formDetails.templateId === 0,
      startDate: !formDetails.projectStartDate,
      description: formDetails.description === ""
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean);
  };
  return (
    <div
      className={`${styles.addProjectcontainer} +  content_with_margin + ${editProjectTab === "false" ? " " : styles.editProjectcontainer
        } + ${showBanner ? styles.showBanner : ""}`}
    >
      {isLoaderVisible && (
        <div className={styles.loader_container}>
          <div className={styles.loader}></div>
        </div>
      )}{" "}
      {editProjectTab === "false" && (
        <Grid container className={styles.search_container}>
          <Grid item sm={12}>
            <h3 id="new_project_title" className={styles.bigText}>{t("AddProject.new_project_title")}</h3>
          </Grid>
        </Grid>
      )}
      {editProjectTab === "true" && (
        <Grid container className={styles.search_container}>
          <Grid item sm={12}>
            <h3 id="edit_project_title">{t("AddProject.edit_project_title")}</h3>
          </Grid>
        </Grid>
      )}
      <Box
        component="form"
        className={styles.wrapper_container}
        noValidate
        sx={{ mt: 1 }}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          className={styles.wrapper_container_inner}
          alignItems="flex-start"
        >
          <Grid
            container
            //spacing={3}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Grid container item sm={5.5}>
              <Grid item sm={12} className={styles.additional_info_title}>
                <h4 id="basic_info_title">{t("AddProject.basic_info_title")}</h4>
                {/* Collapse button visible on mobile */}
                {isMobile && (
                  <IconButton onClick={() => handleToggle("section1")}>
                    <Icon
                      path={
                        openSections ? mdiChevronDownCircle : mdiChevronUpCircle
                      }
                      size={1}
                    />
                  </IconButton>
                )}
              </Grid>
              {/* Collapsible content */}
              <Collapse
                sx={{ width: "100%" }}
                in={!isMobile || openSections.section1}
              >
                <Grid item container spacing={2}>
                  <Grid item sm={6} container className="fieldWrapper">
                    <FormControl error={touchedFields.projecttype && errors.projecttype}>
                      <InputLabel id="type_placeholder">
                        {t("AddProject.projectTypeId")}
                      </InputLabel>
                      <Select
                        name="projectTypeId"
                        value={formDetails.projectTypeId}
                        onChange={handleProjectIdChange}
                        labelId="type_placeholder"
                        id="projectTypeId"
                        placeholder="Type"
                        label={t("AddProject.projectTypeId")}
                        className={" selectBox"}
                        onBlur={() => handleBlur('projecttype')}
                        disabled={editProjectTab === "true"}
                        input={<OutlinedInput notched label="Project Type" />}
                      >
                        {projectTypes.map((lookup) => (
                          <MenuItem
                            key={lookup.lookupValueID}
                            value={lookup.lookupValueID}
                          >
                            {lookup.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touchedFields.projecttype && errors.projecttype && (
                        <FormHelperText>Project Type is required</FormHelperText> // Display helper text
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} container className="fieldWrapper">
                    <FormControl error={touchedFields.template && errors.template}>
                      <InputLabel id="template_placeholder">
                        {t("AddProject.templateId")}
                      </InputLabel>
                      <Select
                        label={t("AddProject.templateId")}
                        name="templateId"
                        value={formDetails.templateId}
                        onChange={handleTemplateSelectChange}
                        labelId="template_placeholder"
                        id="templateId"
                        placeholder="Template"
                        className="selectBox"
                        disabled={editProjectTab === "true"}
                        input={<OutlinedInput notched />}
                        onBlur={() => handleBlur('template')}
                      >
                        {templates
                          .filter(
                            (template) =>
                              template.projectTypeId ===
                              Number(selectedProjectTypeId)
                          )
                          .map((template) => (
                            <MenuItem
                              key={template.projectTemplateId}
                              value={template.projectTemplateId}
                            >
                              {template.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {touchedFields.template && errors.template && (
                        <FormHelperText>Template is required</FormHelperText> // Display helper text
                      )}
                    </FormControl>
                  </Grid>
                  {editProjectTab === "false" && (
                    <Grid item sm={12} container className="fieldWrapper">
                      <TextField
                        name="projectName"
                        value={formDetails.projectName}
                        onChange={handleChange}
                        id="project_name"
                        label={t("AddProject.project_name")}
                        placeholder="Project Name"
                        variant="outlined"
                        onBlur={() => handleBlur('projectname')}
                        error={touchedFields.projectname && errors.projectname}
                        helperText={touchedFields.projectname && errors.projectname ? "Project Name is required" : ""}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  <Grid sm={12} item container className="fieldWrapper">
                    <TextField
                      name="description"
                      value={formDetails.description}
                      onChange={handleChange}
                      id="description"
                      label={t("AddProject.description")}
                      placeholder="Description"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      onBlur={() => handleBlur('description')}
                      error={touchedFields.description && errors.description}
                      helperText={touchedFields.description && errors.description ? "Description is required" : ""}
                    />
                  </Grid>
                  <Grid item sm={12} container className="fieldWrapper">
                    <FormControl className="inlineSelect">
                      <TextField
                        name="address1"
                        value={formDetails.address1}
                        onChange={handleChange}
                        placeholder={t("AddProject.address_1")}
                        id="address_1"
                        variant="outlined"
                        label={t("AddProject.address_1")}
                        className="mb20"
                        onBlur={() => handleBlur('address1')}
                        error={touchedFields.address1 && errors.address1}
                        helperText={touchedFields.address1 && errors.address1 ? "Address1 is required" : ""}
                        InputLabelProps={{ shrink: true }}
                      />

                      <TextField
                        name="address2"
                        value={formDetails.address2}
                        onChange={handleChange}
                        placeholder={t("AddProject.address_2")}
                        label={t("AddProject.address_2")}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    container
                    className="fieldWrapper"
                    alignItems="start"
                  >
                    <TextField
                      name="city"
                      value={formDetails.city}
                      onChange={handleChange}
                      placeholder={t("AddProject.city")}
                      variant="outlined"
                      label={t("AddProject.city")}
                      className="inlineSelect oneThird"
                      onBlur={() => handleBlur('city')}
                      error={touchedFields.city && errors.city}
                      helperText={touchedFields.city && errors.city ? "City is required" : ""}
                      InputLabelProps={{ shrink: true }}
                    />
                    <FormControl className="oneThird">
                      <Autocomplete
                        size="small"
                        id="state-autocomplete"
                        options={states.filter(
                          (state) => state.countryId === Number(233)
                        )} // Filter states by countryId
                        value={
                          states.find(
                            (state) => state.stateId === formDetails.stateId
                          ) || null
                        } // Set value based on stateId
                        onChange={(event, newValue) => {
                          setFormDetails((prevDetails) => ({
                            ...prevDetails,
                            stateId: newValue ? newValue.stateId : "", // Update stateId based on selected state
                          }));
                        }}
                        getOptionLabel={(option) => option.stateName || ""} // Display state names in the dropdown
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("AddProject.state")}
                            variant="outlined"
                            placeholder={t("AddProject.state")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onBlur={() => handleBlur('stateId')}
                            error={touchedFields.stateId && errors.stateId}
                            helperText={touchedFields.stateId && errors.stateId ? "State is required" : ""}
                            onKeyDown={(event) => {
                              if (event.key === "Tab") {
                                event.key = "Enter"; // will call `onChange`
                              }
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}

                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      name="zipCode"
                      value={formDetails.zipCode}
                      onChange={handleChange}
                      placeholder={t("AddProject.zip_code")}
                      variant="outlined"
                      className="oneThird"
                      label={t("AddProject.zip_code")}
                      onBlur={() => handleBlur('zipCode')}
                      error={touchedFields.zipCode && errors.zipCode}
                      helperText={touchedFields.zipCode && errors.zipCode ? "zipcode is required" : ""}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item sm={6} container className="fieldWrapper">
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="projectStartDate"
                          label={t("AddProject.start_date")}
                          value={formDetails.projectStartDate}
                          onChange={(date: Dayjs | null) =>
                            setFormDetails((prevState) => ({
                              ...prevState,
                              projectStartDate: date,
                            }))
                          }
                          onAccept={() => handleBlur('startDate')}
                          onClose={() => handleBlur('startDate')}
                          slotProps={{
                            textField: {
                              InputLabelProps: { shrink: true },
                              error: touchedFields.startDate && errors.startDate,
                              helperText: (touchedFields.startDate && errors.startDate ? "Start Date is required" : ""),
                            },
                          }}
                        />
                      </LocalizationProvider>

                    </FormControl>
                  </Grid>
                  <Grid item sm={6} container className="fieldWrapper">
                    <FormControl>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="projectEndDate"
                          label={t("AddProject.estimated_end_date")}
                          value={formDetails.projectEndDate}
                          onChange={(date: Dayjs | null) =>
                            setFormDetails((prevState) => ({
                              ...prevState,
                              projectEndDate: date ? date : null,
                            }))
                          }
                          slotProps={{
                            textField: { InputLabelProps: { shrink: true } },
                          }}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>

                  <Grid sm={12} item container className="fieldWrapper">
                    <TextField
                      name="notes"
                      value={formDetails.notes}
                      onChange={handleChange}
                      id="note"
                      label={t("AddProject.note")}
                      placeholder={t("AddProject.note")}
                      multiline
                      rows={4}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid sm={12} item container>
                    <Grid
                      item
                      md={6}
                      className="fieldWrapper"
                      alignItems={"flex-start"}
                    >
                      <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        aria-placeholder={t("AddProject.upload_text")}
                        endIcon={
                          <Icon
                            className="uploadButtonIcon"
                            path={mdiCloudUpload}
                            size={1}
                          />
                        }
                        className="uploadButton"
                      >
                        {t("AddProject.upload_text")}
                        <input
                          type="file"
                          multiple
                          accept=".pdf,.jpg,.dwf,.dwg,.tif,.png,.jpeg"
                          className="d_none"
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      alignItems={"flex-start"}
                      className="fileListContainer"
                    // spacing={2}
                    >
                      {formDetails.files?.map((file, index) => (
                        <div key={index} className="fileItem">
                          <span>{file.name}</span>
                          <svg
                            className="cancelIcon"
                            onClick={() => removeFile(file.name)}
                          >
                            <HighlightOffIcon />
                          </svg>
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            <Grid container item sm={5.5}>
              {isMobile && (
                <Grid item sm={12} className={styles.additional_info_title}>
                  <h4>Additional Information</h4>
                  {/* Collapse button visible on mobile  */}
                  <IconButton onClick={() => handleToggle("section2")}>
                    <Icon
                      path={
                        openSections ? mdiChevronDownCircle : mdiChevronUpCircle
                      }
                      size={1}
                    />
                  </IconButton>
                </Grid>
              )}
              <Collapse
                sx={{ width: "100%" }}
                in={!isMobile || openSections.section2}
              >
                <Grid item container spacing={2}>
                  {listElements}
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.submmitButton_container}
          spacing={2}
          alignItems="flex-start"
        >
          <Grid
            container
            item
            xs={12}
            sx={{ justifyContent: "space-between", gap: 0 }}
            className="submitButtons"
          >
            {/* <Grid item sm={4}>
              {deleteDialogOpen && (
                <DeleteDialog
                  open={deleteDialogOpen}
                  title={deleteDialogTitle}
                  cancelbuttonText={dialogCancelButtonText}
                  submitButtonText={dialogDeleteButtonText}
                  cancelbuttonLink={handleDeleteCancel}
                  submitButtonLink={handleDeleteConfirm}
                />
              )}
              {editProjectTab === "true" && (
                <Button
                  type="button"
                  fullWidth
                  variant="outlined"
                  className="primary-button widthauto red-button"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </Grid> */}
            <Grid
              item
              sm={12}
              className="submitButtons"
              sx={{ mt: "0 !important" }}
            >
              <Button
                type="button"
                fullWidth
                variant="outlined"
                className="outline-button widthauto"
                onClick={handleCancel}
                id="cancel_btn"
              >
                {t("AddProject.cancel_btn")}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="primary-button widthauto"
                name="Save"
                disabled={!isFormValid}
                id="save_btn"
              >
                {t("AddProject.save_btn")}
              </Button>
              {editProjectTab === "false" && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="primary-button widthauto"
                  name="Save_Add"
                  id="save_add_btn"
                  disabled={!isFormValid}
                >
                  {t("AddProject.save_add_btn")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default AddProject;
