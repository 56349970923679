import React, { useEffect, useState } from "react";
import styles from "./login.module.scss";
import {
  Box,
  Button,
  InputAdornment,
  Link,
  TextField,
  Snackbar,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
  FormControl,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccount, mdiEyeOffOutline, mdiEyeOutline, mdiLock } from "@mdi/js";
import { userService } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import { ISignin } from "../../interfaces";
import { useUserContext } from "../../utils/UserContext";
import { validateSigninForm } from "../../utils/validation";
import {
  encryptData,
  decryptData,
  decryptVerifyEmail,
} from "../../utils/CryptoJS";
import { ValidateToken } from "../../utils/AuthValidation";
import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language";


const Login: React.FC = () => {
  useEffect(() => {
    ValidateToken();
  }, []);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setEmail } = useUserContext();
  const [emailError, setEmailError] = useState<string | null>(null);
  const ProjectURL = process.env.PUBLIC_URL;
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [errors, setErrors] = useState<Partial<ISignin & { general?: string }>>(
    {}
  );
  let [emailFromURL, setValue] = React.useState("");
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const language: Language = localStorage.getItem('language') === 'en' ? Language.en : Language.es;
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const getEmailFromURL = (): string | null => {
    const params = new URLSearchParams(window.location.search);
    const encryptedEmail = params.get("eid") || "";
    if (encryptedEmail) {
      return encryptedEmail;
    }
    return null;
  };
  const SigninObj: ISignin = {
    email: "",
    password: "",
  };
  const [formDetails, setFormDetails] = useState({ ...SigninObj, email: "" });

  const [savingData, setSavingData] = useState(false);

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSavingData(true);
    setGeneralError(null);
    const validationErrors = validateSigninForm(formDetails,t);
    setErrors(validationErrors);
    // If there are no validation errors, proceed with the login
    if (!validationErrors.email || !validationErrors.password) {
      setSavingData(false);
    }
    if (!validationErrors.email && !validationErrors.password) {
      if (!formDetails.email && !formDetails.password) {
        setGeneralError("Email and Password are required.");
        return;
      }
      try {
        const data = await userService.login(formDetails);
        if (data.isSuccess) {
          setTimeout(async () => {
            window.location.reload();
          }, 1000);

          localStorage.setItem("token", data.token);
          const userEmail = emailFromURL || formDetails.email;
          setEmail(userEmail);
          if (rememberMe) {
            localStorage.setItem("email", formDetails.email);
            const encryptedPassword = encryptData(formDetails.password);
            localStorage.setItem("password", encryptedPassword);
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }

          navigate("/dashboard");
          setSavingData(false);
        } else {
          setGeneralError("Incorrect Email or password.");
          setSavingData(false);
          return;
        }
      } catch (error: any) {
        const errorMessage = error.response.data.errors.join(", ");
        if (errorMessage === "USER_NOT_VERIFIED") {
          setGeneralError(
            "Please verify your email to activate your account. If you haven’t received the confirmation email, check your spam or junk folder."
          );
          setSavingData(false);
        } else {
          setGeneralError("Incorrect Email or password.");
          setSavingData(false);
        }
      }
    }
  };

  useEffect(() => {
    const email = getEmailFromURL();
    const storedEmail = localStorage.getItem("email");
    if (email) {
      verifyEmail(email);
    }
    const storedPassword = localStorage.getItem("password");

    if (storedEmail && storedPassword) {
      const decryptedPassword = decryptData(storedPassword);
      setFormDetails({ email: storedEmail, password: decryptedPassword });
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (location.state?.showSnackbar) {
      setSnackbarMessage(location.state.message);
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const forgotPassword = async (email: string) => {
    navigate(`/forgot-password`, { state: { email: email } });
  };

  const verifyEmail = async (email: string) => {
    try {
      const data = await userService.validateEmail(email);
      if (!data.isSuccess) {
        setEmailError(data.errors.join(", "));
      } else {
        setFormDetails({ ...formDetails, email: data.email });
      }
    } catch (error: any) {
      setEmailError(error.response.data.errors.join(", "));
    }
  };
  const onRedirectToSignup = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    navigate(`/signup`); // Pass fromFreeTrial param
  };
  return (
    <div className={styles.loginContainer}>
      <div className={styles.login_left}>
        <div className={styles.login_left_content}>
          <img
            src={ProjectURL + "/assets/new_logo_teal.svg"}
            width="175px"
            alt="Logo"
          />
          <h2 id="welcome_text">{t('login.left.welcome_text')}</h2>
          <p id="sub_text">{t('login.left.sub_text')}</p>
          <Button
            className={styles.white_button}
            id="read_more_btn"
            sx={{
              padding: "8px 30px",
              borderRadius: "4px",
              fontFamily: "Nunito Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          // onClick={onRedirectToSignup}
          >
            {" "}
            {t('login.left.read_more_btn')}{" "}
          </Button>
          <h4 id="register_text">
            {t('login.left.register_text')} {" "} <Link id="click_here_link" href="/signup?fromFreeTrial=true">{t('login.left.click_here_link')}</Link>
          </h4>
        </div>
      </div>
      <div className={styles.login_right}>
        <div className={styles.login_right_content}>
          <h3 id="right_welcome_text"> {t("login.right.right_welcome_text")}</h3>
          <p id="right_sub_text">{t("login.right.right_sub_text")}</p>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            {emailError && <p className={styles.error}>{emailError}</p>}
            <FormControl className="fieldWrapper">
              <TextField
                required
                margin="normal"
                fullWidth
                id="username"
                placeholder={t("login.right.username")}
                label={t("login.right.username")}
                name="username"
                className="inputBox"
                autoFocus
                value={formDetails.email}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, email: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon path={mdiAccount} size={0.7} />
                    </InputAdornment>
                  ),
                }}
                helperText={
                  errors.email && (
                    <span className={styles.error}>{errors.email}</span>
                  )
                }
              />
            </FormControl>
            <FormControl className="fieldWrapper">
              <TextField
                required
                margin="normal"
                fullWidth
                name="password"
                placeholder={t("login.right.password")}
                label={t("login.right.password")}
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={formDetails.password}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, password: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon path={mdiLock} size={0.7} />
                    </InputAdornment>
                  ),
                  endAdornment: formDetails.password && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Icon path={mdiEyeOffOutline} size={0.7} />
                        ) : (
                          <Icon path={mdiEyeOutline} size={0.7} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  errors.password && (
                    <span className={styles.error}>{errors.password}</span>
                  )
                }
              />
            </FormControl>
            {generalError && <p className={styles.error}>{generalError}</p>}

            <FormControlLabel
              id="remember_me_label"
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label={t('login.right.remember_me_label')}
            ></FormControlLabel>
            <Button
              id="login_btn"
              type="submit"
              fullWidth
              variant="contained"
              className="primary-button login-button"
              sx={{ mt: 3, mb: 4 }}
              onClick={handleLogin}
              disabled={savingData}
              startIcon={
                savingData ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              {t('login.right.login_btn')}
            </Button>
            <Link id="forgot_paaword_link"
              className={styles.forgetPassword}
              onClick={async () => await forgotPassword(formDetails.email)}
            >
              {t('login.right.forgot_paaword_link')}
            </Link>
          </Box>
        </div>
        <div className={styles.termsLink}>
          <Link id="term_conditons_link"
            className={styles.textLink}
            href="/assets/CPro360_Terms_and_Conditions.pdf"
            target="_blank"
          >
            {" "}
            {t('login.right.term_conditons_link')}{" "}
          </Link>
          <Link id="privacy_policy_link"
            className={styles.textLink}
            href="/assets/CPro360_Privacy_Policy.pdf"
            target="_blank"
          >
            {" "}
            {t('login.right.privacy_policy_link')}{" "}
          </Link>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Login;
