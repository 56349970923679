import userRoles from '../../json/userRoles.json';
import { UserTypeId } from '../../enums';

const userRoleId = parseInt(localStorage.getItem("userRoleId") as string, 10);


const getUserRolesPermissions = async () => {
    if (UserTypeId[userRoleId]) {
        return userRoles[UserTypeId[userRoleId].toString() as keyof typeof userRoles];
    }
}
const sharedService = { getUserRolesPermissions };
export default sharedService;