import { Dialog, styled } from "@mui/material";

export const RenameDialogStyle = styled(Dialog)({
  "& .MuiPaper-root": {
    maxWidth: "550px",
    width: "550px",
    borderRadius: "14px",
    padding: "15px 20px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    alignContent: "center",
    alignItem: "center",
    textAlign: "center",
  },
  "& .MuiDialogTitle-root": {
    padding: "0px",
  },
  "& .MuiDialogContent-root": {
    padding: "0px",
    paddingBottom: "25px",
    textAlign: "left",
  },
  "& .MuiDialogActions-root": {
    gap: "15px",
    "& button": {
      width: "auto",
    },
  },
});
export default RenameDialogStyle;
